import CIcon from "@coreui/icons-react";

export let entrantProfile = {
  uid: "",
  //Profile
  name: "",
  surname: { value: "", error: false, errorMessage: "Required field" },
  cellphone: "",
  email: { value: "", error: false, errorMessage: "Required field" },
  twitter: { value: "", error: false, errorMessage: "Required field" },
  facebook: { value: "", error: false, errorMessage: "Required field" },
  instagram: { value: "", error: false, errorMessage: "Required field" },
  gender: { value: "", error: false, errorMessage: "Required field" },
  age: { value: 18, error: false, errorMessage: "Required field" },
  city: "",
  province: "",
  fullAddress: "",
  street: "",
  streetNumber: "",
  DOB: {
    value: "",
    error: false,
    errorMessage: "You must enter a date of birth",
  },
};

export let mfsEntry = {
  uid: "",
  signature: "",

  // Media Section
  pic: {
    label: "Picture",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "file",
  },
  pic2: {
    label: "Picture 2",
    value: "No Picture",
    error: false,
    errorMessage: "Required field",
    fieldType: "file",
  },
  pic3: {
    label: "Picture 3",
    value: "No Picture",
    error: false,
    errorMessage: "Required field",
    fieldType: "file",
  },
  video: {
    label: "Video",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "file",
  },
  status: "New",

  // Personal Info Section
  starSign: {
    label: "Star Sign",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  ethnicity: {
    label: "Ethnicity",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  educationLevel: {
    label: "Education Level",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  profession: {
    label: "Profession",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  medicalStatus: {
    label: "Medical Status",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },

  // Appearance Section
  hairColour: {
    label: "Hair Colour",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  eyeColour: {
    label: "Eye Colour",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  weightKG: {
    label: "Weight (KG)",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "number",
  },
  height: {
    label: "Height",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },

  // Lifestyle Choices
  smoker: {
    label: "Smoker",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  feelingsAboutSmokers: {
    label: "Feelings About Smokers",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  dateSmokerOrVaper: {
    label: "Willing to Date a Smoker or Vaper",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  acceptChildrenPetsDependents: {
    label: "Accept Children, Pets, Dependents",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  haveChildren: {
    label: "Have Children",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  drinker: {
    label: "Drinker",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  homeLanguage: {
    label: "Home Language",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  singleBecause: {
    label: "Single Because",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "textarea",
  },
  spiritualReligiousBackground: {
    label: "Spiritual / Religious Background",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },

  // About
  about: {
    label: "About",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "textarea",
  },
  outdoorsIndoorsType: {
    label: "Outdoors or Indoors Type",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  levelOfFitness: {
    label: "Level Of Fitness",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  goToGym: {
    label: "Go To Gym",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  memberOfClubs: {
    label: "Member Of Clubs",
    value: [],
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  extrovertIntrovert: {
    label: "Extrovert or Introvert",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  hobbiesInterests: {
    label: "Hobbies & Interests",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "textarea",
  },
  booksRead: {
    label: "Books Read",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "textarea",
  },
  lastGoodBook: {
    label: "Last Good Book",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  lastMovieWatched: {
    label: "Last Movie Watched",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  typeOfMoviesEnjoy: {
    label: "Type Of Movies Enjoy",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  interestInWorldAffairs: {
    label: "Interest In World Affairs",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  loveAdventure: {
    label: "Love Adventure",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  enjoyMakingNewFriends: {
    label: "Enjoy Making New Friends",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  traveledToGreatDegree: {
    label: "Traveled To Great Degree",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  intendToTravel: {
    label: "Intend To Travel",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  preferredAgeGroup: {
    label: "Preferred Age Group",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  openToAllCulturalGroups: {
    label: "Open To All Cultural Groups",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
};

export let excludeValidation = [
  "pic",
  "video",
  "twitter",
  "height",
  "facebook",
  "instagram",
  "weightKG",
  "height",
  "DOB",
];

export let listColumns = [
  "name",
  "surname",
  "gender",
  "starSign",
  "ethnicity",
  "homeLanguage",
  "extrovertIntrovert",
  "outdoorsIndoorsType",
  "spiritualReligiousBackground",
];

// Dashboard
export let tileDataList = [
  {
    fieldname: { field: "dateSmokerOrVaper", value: "Yes" },
    dashboardLabel: "Are willing to date a smoker",
    color: "warning",
    icon: <CIcon icon="cilChartPie" height={24} />,
  },
  {
    fieldname: { field: "smoker", value: "Yes" },
    dashboardLabel: "Are smokers",
    color: "warning",
    icon: <CIcon icon="cilChartPie" height={24} />,
  },
  {
    fieldname: { field: "haveChildren", value: "Yes" },
    dashboardLabel: "Have Children",
    color: "warning",
    icon: <CIcon icon="cilChartPie" height={24} />,
  },
  {
    fieldname: { field: "goToGym", value: "Yes" },
    dashboardLabel: "Goes to gym",
    color: "warning",
    icon: <CIcon icon="cilChartPie" height={24} />,
  },
  {
    fieldname: { field: "drinker", value: "Yes" },
    dashboardLabel: "Drink",
    color: "warning",
    icon: <CIcon icon="cilChartPie" height={24} />,
  },
  {
    fieldname: { field: "loveAdventure", value: "Yes" },
    dashboardLabel: "Love adventure",
    color: "warning",
    icon: <CIcon icon="cilChartPie" height={24} />,
  },
  {
    fieldname: { field: "enjoyMakingNewFriends", value: "Yes" },
    dashboardLabel: "Enjoy Making New Friends",
    color: "warning",
    icon: <CIcon icon="cilChartPie" height={24} />,
  },

  // Add more objects to the tileDataList for additional fields
];

// pic: { value: "", error: false, errorMessage: "Required field" },
// pic2: { value: "No Picture", error: false, errorMessage: "Required field" },
// pic3: { value: "No Picture", error: false, errorMessage: "Required field" },
// video: { value: "", error: false, errorMessage: "Required field" },
// status: "New",
//
//     starSign: { value: "", error: false, errorMessage: "Required field" },
// ethnicity: { value: "", error: false, errorMessage: "Required field" },
// educationLevel: { value: "", error: false, errorMessage: "Required field" },
// profession: { value: "", error: false, errorMessage: "Required field" },
// medicalStatus: { value: "", error: false, errorMessage: "Required field" },
// hairColour: { value: "", error: false, errorMessage: "Required field" },
// eyeColour: { value: "", error: false, errorMessage: "Required field" },
// weightKG: { value: "", error: false, errorMessage: "Required field" },
// height: { value: "", error: false, errorMessage: "Required field" },
// smoker: { value: "", error: false, errorMessage: "Required field" },
// feelingsAboutSmokers: {
//   value: "",
//       error: false,
//       errorMessage: "Required field",
// },
// dateSmokerOrVaper: {
//   value: "",
//       error: false,
//       errorMessage: "Required field",
// },
// acceptChildrenPetsDependents: {
//   value: "",
//       error: false,
//       errorMessage: "Required field",
// },
// haveChildren: { value: "", error: false, errorMessage: "Required field" },
// drinker: { value: "", error: false, errorMessage: "Required field" },
// homeLanguage: { value: "", error: false, errorMessage: "Required field" },
// singleBecause: { value: "", error: false, errorMessage: "Required field" },
// spiritualReligiousBackground: {
//   value: "",
//       error: false,
//       errorMessage: "Required field",
// },
// about: { value: "", error: false, errorMessage: "Required field" },
// outdoorsIndoorsType: {
//   value: "",
//       error: false,
//       errorMessage: "Required field",
// },
// levelOfFitness: { value: "", error: false, errorMessage: "Required field" },
// goToGym: { value: "", error: false, errorMessage: "Required field" },
// memberOfClubs: { value: "", error: false, errorMessage: "Required field" },
// extrovertIntrovert: {
//   value: "",
//       error: false,
//       errorMessage: "Required field",
// },
// hobbiesInterests: { value: "", error: false, errorMessage: "Required field" },
// booksRead: { value: "", error: false, errorMessage: "Required field" },
// lastGoodBook: { value: "", error: false, errorMessage: "Required field" },
// lastMovieWatched: { value: "", error: false, errorMessage: "Required field" },
// typeOfMoviesEnjoy: {
//   value: "",
//       error: false,
//       errorMessage: "Required field",
// },
// interestInWorldAffairs: {
//   value: "",
//       error: false,
//       errorMessage: "Required field",
// },
// loveAdventure: { value: "", error: false, errorMessage: "Required field" },
// enjoyMakingNewFriends: {
//   value: "",
//       error: false,
//       errorMessage: "Required field",
// },
// traveledToGreatDegree: {
//   value: "",
//       error: false,
//       errorMessage: "Required field",
// },
// intendToTravel: { value: "", error: false, errorMessage: "Required field" },
// preferredAgeGroup: {
//   value: "",
//       error: false,
//       errorMessage: "Required field",
// },
// openToAllCulturalGroups: {
//   value: "",
//       error: false,
//       errorMessage: "Required field",
// },
