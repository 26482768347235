import { usePlacesWidget } from "react-google-autocomplete";
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";

// AIzaSyAe3hFOUMAWvtPsUdKkYeofkg_Gscn5pUA
// AIzaSyBEEUwF_2FTgUM6E7Dku28uJctPimqWCMw

const useAddressAuto = () => {};

const AddressAuto = (props) => {
  const [address, setAddress] = useState("");
  const [addressCity, setCity] = useState("");
  const [addressProvince, setProvince] = useState("");
  const [error, setError] = useState();

  // let addressFormValue = object[props.field].value;
  let formValue = props.value;
  let formError = props.error;

  const { ref: materialRef } = usePlacesWidget({
    apiKey:
      "AIzaSyAe3hFOUMAWvtPsUdKkYeofkg_Gscn5pUA&callback=Function.prototype",
    onPlaceSelected: (place) => {
      console.log(place.formatted_address);
      setAddress(place.formatted_address);
      setCity(place.address_components[3].long_name);
      setProvince(place.address_components[5].long_name);
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "za" },
    },
  });

  useEffect(() => {
    setError(formError);
    setAddress(formValue);
  }, [formValue, formError]);

  const field = () => {
    return (
      <div>
        <TextField
          sx={{ m: 2, width: "83%" }}
          fullWidth
          inputRef={materialRef}
          label={props.label}
          variant="outlined"
          name={props.field}
          value={formValue}
          error={error}
          onChange={props.onChange}
        />
      </div>
    );
  };
};

export { AddressAuto, useAddressAuto };
