export let showColours = {
  // mfs: "linear-gradient(#6b026a, #bd0202)",
  // dmf: "#49a7c2",
  // opw: "#d3a8a1",
  mfs: "rgba(0,0,0,0.3)",
  dmf: "#49a7c2",
  opw: "rgba(0,0,0,0.2)",
};

export let showBackground = {
  mfs: "http://demo.entrywiz.io/images/mfs1.png",
  opw: "http://demo.entrywiz.io/images/opw1.png",
};
