import React from "react";

const showTermsAndConditions = () => {
  return (
    <div>
      <h1>Connect App Privacy Policy</h1>
      <p>
        1. We are Connect Channel (Pty) Ltd, a production company carrying on
        business in South Africa.
      </p>
      <p>
        2. You have elected to provide us with your personal information via the
        Connect App (“the App”), in order to be considered as a participant in
        one of our television shows. Unless you indicate to us otherwise, we
        will use your Personal Information to determine your suitability for any
        television shows produced by us.
      </p>
      <p>
        3. Your privacy is important to us and we will use every effort to
        ensure that any information, including Personal Information and Special
        Personal Information, provided by you, or which is collected from you or
        third parties, is stored in a secure manner. The provision by you of
        certain Personal Information and Special Personal Information is
        necessary to consider your application to be a participant on one of our
        television shows.
      </p>
      <p>
        4. You accordingly agree to give honest, accurate and current
        information about yourself to us and to maintain and update such
        information when necessary.
      </p>
      <p>
        5. The type of Personal Information we collect may vary. You may be
        required to provide the following Personal Information via the App:
      </p>
      <ul>
        <li>Name</li>
        <li>Age</li>
        <li>ID number</li>
        <li>Contact number</li>
        <li>Gender</li>
        <li>Employment status</li>
        <li>Residential address</li>
        <li>
          Any other information which you may be required to provide us in order
          to provide you with any service or benefit.
        </li>
      </ul>
      <p>
        6. We require Special Personal Information in order to undertake
        background checks, which may include credit checks, and criminal record
        checks. By providing us with your Personal Information, you acknowledge
        and agree that we shall be entitled to use your Personal Information
        (including Special Personal Information) for the aforementioned
        purposes.
      </p>
      <p>
        7. We will only process your Personal Information strictly in accordance
        with our obligations in the Protection of Personal Information Act 4 of
        2013 and any other applicable legislation, regulations or rules binding
        on us. We will only process your Personal Information for lawful
        purposes.
      </p>
      <p>
        8. You acknowledge that any information supplied to us by you is
        provided voluntarily to us. By you submitting any information to us in
        any form, you further acknowledge that such conduct constitutes an
        unconditional, specific and voluntary consent to the processing
        (including storage) of such information by us under any applicable law,
        which consent shall, in the absence of any written objection received
        from you, be indefinite and/or for the period otherwise required in
        terms of any applicable law.
      </p>
      <p>
        9. We will be entitled to transfer your Personal Information to third
        parties where you have consented or where we are entitled to do so under
        any applicable law. Save as aforesaid, we will not exchange, transfer,
        or otherwise make available any Personal Information about you (such as
        name, address, email address or telephone number) to other parties.
      </p>
      <p>
        10. You have the right to access any of your Personal Information in our
        possession at any time and you may require us to correct any incorrect
        information we may have. You may access, review, and update much of the
        information you have submitted to us at any time by contacting our
        offices.You may request that we provide you with a copy of your Personal
        Information at any time, and may request us to delete it. We will keep a
        record of your Personal Information for any period of time that we are
        entitled or obliged to by any applicable law, regulation or rule.
      </p>
      <p>
        11. Should you believe that we have utilised Personal Information
        contrary to any applicable law, you undertake to first attempt to
        resolve any concerns with us. If you are not satisfied with such
        process, you have the right to lodge a complaint with the Information
        Regulator.
      </p>

      <p>
        12. Our information officer is <span id="info-officer"></span> and they
        can be contacted at the following email address:{" "}
        <a
          href="mailto:info@connectchannel.co.za"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@connectchannel.co.za
        </a>
        .
      </p>

      <p>
        13. This Privacy Policy shall be governed and interpreted in accordance
        with the procedural and substantive laws of the Republic of South Africa
        and the parties hereby consent to the jurisdiction of the South African
        courts in the event of any dispute or litigation arising out of this
        consent.
      </p>

      <p>
        14. Should you provide us with your Personal Information, you will be
        deemed to have accepted the terms of this Privacy Policy.
      </p>

      <p>
        15. We incorporate commercially reasonable safeguards to help protect
        and secure your Personal Information. However, no data transmission over
        the Internet, mobile networks, wireless transmission or electronic
        storage of information can be guaranteed to be 100% secure. Please note
        that we cannot ensure the security of any information you transmit to
        us, and you use our App and provide us with your information at your own
        risk.
      </p>

      <p>
        16. We reserve the right to change this Privacy Policy at any time. Any
        changes will be effective immediately upon the posting of the revised
        Privacy Policy and your use of our App indicates your consent to the
        privacy policy posted at the time of use. However, we will not use your
        previously collected Personal Information in a manner materially
        different than represented at the time it was collected without your
        consent. To the extent any provision of this Privacy Policy is found by
        a competent court to be invalid or unenforceable, such provision shall
        be severed to the extent necessary for the remainder to be valid and
        enforceable.
      </p>
    </div>
  );
};

export default showTermsAndConditions;
