import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "../../stylesheets/style.css";
import "bootstrap/dist/css/bootstrap.css";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import "react-rating";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { Facebook, Instagram, Twitter } from "react-bootstrap-icons";
import DeleteDialog from "../alerts";
import { useEntryWizFunctions } from "../../hooks/useEntryWizFunctions";
import { dmfEntry, entrantProfile, excludeValidation } from "./models";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardText,
  CCardTitle,
  CCol,
  CContainer,
  CFormCheck,
  CRow,
} from "@coreui/react";
// import renderTermsAndConditions from "../TermsAndConditions";
import showTermsAndConditions from "./showtermsandconditions";
import SignatureCanvas from "react-signature-canvas";
import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";

export function EntryForm() {
  const {
    fetchEntry,
    fetchProfile,
    handleNextClick,
    handlePrevClick,
    handleTermsChange,
    handleImage,
    handleVideo,
    handleSubmit,
    handleChangeEntry,
    handleChangeProfile,
    handleChangeProfileNonValidate,
    step,
    showEntry,
    profile,
    isActive,
    terms,
    show,
    cellNumber,
    setObject,
    showPic,
    showPic2,
    showPic3,
    showVid,
    ref,
    setActive,
    addressSearch,
    setAddressSearch,
    sigCanvasRef,
    updateAddress,
    address,
  } = useEntryWizFunctions(entrantProfile, dmfEntry, excludeValidation);

  const [openClose, setOpenClose] = useState(false);
  const [error] = useState("");
  const form = useRef();

  const handleDelete = () => {
    setOpenClose(true);
  };

  useEffect(() => {
    setActive(true);
    fetchProfile(cellNumber).then(() => {});
    fetchEntry(show, cellNumber).then(() => {});
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    if (showEntry.signature) {
      sigCanvasRef.current.fromDataURL(showEntry.signature);
    }
  }, [showEntry.signature, sigCanvasRef]);

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text="Busy Processing, Please Wait..."
    >
      <Container>
        {error && <Alert varimaant="danger">{error}</Alert>}
        <DeleteDialog show={show} cellNumber={cellNumber} open={openClose} />
        <fieldset disabled={false}>
          <br />
          <Box
            id="enterDMF"
            name="theform"
            onSubmit={handleSubmit}
            disabled
            component="form"
            // className="testbox text-center"
            className="text-center"
            style={{ padding: 10 }}
          >
            <div>
              <div
                style={{ display: step.activeStep === 1 ? "block" : "none" }}
              >
                {/* Names */}
                <Row style={{ margin: "2%" }}>
                  <img
                    style={{ padding: "50px", width: "500px" }}
                    // src={"../images/" + show + ".jpeg"}
                    src={setObject.showLogo}
                    className="img-fluid rounded mx-auto d-block"
                    alt=""
                  />
                  <CContainer>
                    <CCard className="text-center">
                      <CCardHeader>About</CCardHeader>{" "}
                      <CCardBody>
                        <CCardTitle>{setObject.showName}</CCardTitle>{" "}
                        <CCardText>{setObject.showDescription}</CCardText>
                        <CButton onClick={handleNextClick}>Enter Show</CButton>
                      </CCardBody>
                      <CCardFooter className="text-medium-emphasis">
                        Entries open until {setObject.closeDate}
                      </CCardFooter>
                    </CCard>
                  </CContainer>
                </Row>
              </div>

              <div
                style={{ display: step.activeStep === 2 ? "block" : "none" }}
              >
                <h1>Personal Profile</h1>
                <h6 style={{ color: "grey" }}>
                  *Struggling to see? rotate phone
                </h6>
                {/* Names */}
                <div>
                  <TextField
                    // sx={{ m: 2, width: "45%" }}
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                    id="ename"
                    label="Name"
                    name="name"
                    variant="outlined"
                    value={profile.name.value}
                    error={profile.name.error}
                    onChange={handleChangeProfile}
                  />

                  <TextField
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                    id="esurname"
                    label="Surname"
                    name="surname"
                    variant="outlined"
                    value={profile.surname.value}
                    error={profile.surname.error}
                    onChange={handleChangeProfile}
                  />
                </div>
                {/*Contacts*/}
                <div>
                  <TextField
                    // sx={{ m: 2, width: "45%" }}
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                    id="ecellphone"
                    name="cellphone"
                    label="Cellphone"
                    variant="outlined"
                    className={"form-control"}
                    value={cellNumber}
                    onChange={handleChangeProfile}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                    id="email"
                    name="email"
                    label="E-Mail"
                    variant="outlined"
                    className={"form-control"}
                    value={profile.email.value}
                    error={profile.email.error}
                    onChange={handleChangeProfile}
                  />
                </div>
                {/*Age and gender*/}
                <div style={{ margin: "2%" }}>
                  <TextField
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                    type={"date"}
                    id="DOB"
                    name="DOB"
                    label="Date Of Birth"
                    variant="outlined"
                    value={profile.DOB.value}
                    onChange={(event) => {
                      handleChangeProfile(event);
                      // calculateAge(event);
                    }}
                    error={profile.DOB.error}
                    // helperText={profile.DOB.errorMessage}
                  />

                  <FormControl
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                  >
                    <InputLabel id="genderSelect">Gender</InputLabel>
                    <Select
                      variant="outlined"
                      name="gender"
                      label="Gender"
                      value={profile.gender.value}
                      error={profile.gender.error}
                      onChange={handleChangeProfile}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {/*Social Media*/}
                <div>
                  <TextField
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "29%" },
                    }}
                    id="twitter"
                    name="twitter"
                    label="Twitter"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Twitter />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    value={profile.twitter.value}
                    error={profile.twitter.error}
                    onChange={handleChangeProfile}
                  />

                  <TextField
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "29%" },
                    }}
                    id="instagram"
                    name="instagram"
                    label="Instagram"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Instagram />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    value={profile.instagram.value}
                    error={profile.instagram.error}
                    onChange={handleChangeProfile}
                  />

                  <TextField
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "29%" },
                    }}
                    id="facebook"
                    name="facebook"
                    label="Facebook"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Facebook />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    value={profile.facebook.value}
                    error={profile.facebook.error}
                    onChange={handleChangeProfile}
                  />
                </div>
                {/*Address*/}

                <div>
                  <TextField
                    sx={{ m: 2, width: "92%" }}
                    inputRef={ref}
                    label="Search For Address"
                    variant="outlined"
                    name="fullAddress"
                    className={"form-control"}
                    value={addressSearch}
                    onChange={(e) => {
                      setAddressSearch(e.target.value);
                    }}
                  />
                </div>

                <div>
                  <TextField
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                    id="streetNumber"
                    name="streetNumber"
                    label="Street Number"
                    variant="outlined"
                    className={"form-control"}
                    value={profile.streetNumber}
                    onChange={handleChangeProfileNonValidate}
                    onFocus={() => {
                      updateAddress(address);
                    }}
                  />
                  <TextField
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                    id="street"
                    name="street"
                    label="Street"
                    variant="outlined"
                    className={"form-control"}
                    value={profile.street}
                    onChange={handleChangeProfileNonValidate}
                    onFocus={() => {
                      updateAddress(address);
                    }}
                  />
                </div>

                <div>
                  <TextField
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                    id="city"
                    name="city"
                    label="City"
                    variant="outlined"
                    className={"form-control"}
                    value={profile.city}
                    onChange={handleChangeProfileNonValidate}
                    onFocus={() => {
                      updateAddress(address);
                    }}
                  />
                  <TextField
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                    id="province"
                    name="province"
                    label="Province"
                    variant="outlined"
                    className={"form-control"}
                    value={profile.province}
                    onChange={handleChangeProfileNonValidate}
                    onFocus={() => {
                      updateAddress(address);
                    }}
                  />
                </div>
              </div>

              <div
                style={{ display: step.activeStep === 3 ? "block" : "none" }}
              >
                <h1>Entry</h1>
                <h6 style={{ color: "grey" }}>
                  *Struggling to see? rotate phone
                </h6>

                {/*Height and Date*/}
                <div>
                  <TextField
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                    type={"number"}
                    name="height"
                    id="height"
                    min={80}
                    label="Height"
                    placeholder="Enter your height"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">cm</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    className={"form-control"}
                    value={showEntry.height.value}
                    error={showEntry.height.error}
                    onChange={handleChangeEntry}
                  />

                  <FormControl
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Your Date Preference?
                    </InputLabel>
                    <Select
                      // variant="outlined"
                      labelId="demo-simple-select-label"
                      label="Your Date Preference?"
                      name="date"
                      placeholder="Your date preference?"
                      value={showEntry.date.value}
                      error={showEntry.date.error}
                      defaultValue="Non-Binary"
                      onChange={handleChangeEntry}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Non-Binary">Non-Binary</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {/*Highest schooling achievement? */}
                <div>
                  <FormControl
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                  >
                    <InputLabel id="shoolingSelect">
                      Schooling/Education
                    </InputLabel>
                    <Select
                      labelId="shoolingSelect"
                      label="Schooling/Education"
                      name="schooling"
                      value={showEntry.schooling.value}
                      error={showEntry.schooling.error}
                      onChange={handleChangeEntry}
                    >
                      <MenuItem value="None">None</MenuItem>
                      <MenuItem value="High School">High school</MenuItem>
                      <MenuItem value="Technical">
                        Technical Certificate
                      </MenuItem>
                      <MenuItem value="Occupational">
                        Occupational Certificate
                      </MenuItem>
                      <MenuItem value="Professional">Professional</MenuItem>
                      <MenuItem value="Associate">Associate degree</MenuItem>
                      <MenuItem value="Bachelor">Bachelor's degree</MenuItem>
                      <MenuItem value="Master">Master's degree</MenuItem>
                      <MenuItem value="Doctorate">Doctorate</MenuItem>
                    </Select>
                  </FormControl>

                  {/* Date & Criminal Record  */}
                  <FormControl
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                  >
                    <InputLabel id="record">Criminal Record?</InputLabel>
                    <Select
                      labelId="record"
                      name="criminalrecord"
                      label="Criminal Record?"
                      placeholder="Do you have a Criminal Record?"
                      value={showEntry.criminalrecord.value}
                      error={showEntry.criminalrecord.error}
                      onChange={handleChangeEntry}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div>
                  <TextField
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                    multiline
                    rows={4}
                    name="idealphysic"
                    label="Ideal partner's physic?"
                    placeholder="Describe your ideal partner's physic?"
                    className={"form-control"}
                    variant="outlined"
                    value={showEntry.idealphysic.value}
                    error={showEntry.idealphysic.error}
                    onChange={handleChangeEntry}
                  ></TextField>

                  <FormControl
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                  >
                    <InputLabel id="employed">
                      Do they have to be employed?
                    </InputLabel>
                    <Select
                      name="employed"
                      labelId="employed"
                      label="Do they have to be employed"
                      value={showEntry.employed.value}
                      error={showEntry.employed.error}
                      onChange={handleChangeEntry}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {/* Religion  Car?"*/}
                <div>
                  <FormControl
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                  >
                    <InputLabel id="religionSelect">
                      What religion must they follow?
                    </InputLabel>
                    <Select
                      labelId="religionSelect"
                      name="religion"
                      label="What religion must they follow?"
                      value={showEntry.religion.value}
                      error={showEntry.religion.error}
                      onChange={handleChangeEntry}
                    >
                      <MenuItem value="Any">Any</MenuItem>
                      <MenuItem value="African Diaspora Religions">
                        African Diaspora Religions
                      </MenuItem>
                      <MenuItem value="Atheism/Agnosticism">
                        Atheism/Agnosticism
                      </MenuItem>
                      <MenuItem value="American Religions">
                        American Religions
                      </MenuItem>
                      <MenuItem value="Bahá’í">Bahá’í</MenuItem>
                      <MenuItem value="Buddhism">Buddhism</MenuItem>
                      <MenuItem value="Christianity">Christianity</MenuItem>
                      <MenuItem value="Confucianism">Confucianism</MenuItem>
                      <MenuItem value="Druze">Druze</MenuItem>
                      <MenuItem value="Gnosticism">Gnosticism</MenuItem>
                      <MenuItem value="Hinduism">Hinduism</MenuItem>
                      <MenuItem value="Indigenous">Indigenous</MenuItem>
                      <MenuItem value="Islam">Islam</MenuItem>
                      <MenuItem value="Jainism">Jainism</MenuItem>
                      <MenuItem value="Judaism">Judaism</MenuItem>
                      <MenuItem value="Rastafarianism">Rastafarianism</MenuItem>
                      <MenuItem value="Shinto">Shinto</MenuItem>
                      <MenuItem value="Sikhism">Sikhism</MenuItem>
                      <MenuItem value="Traditional African Religions">
                        Traditional African Religions
                      </MenuItem>
                      <MenuItem value="Zoroastrianism">Zoroastrianism</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                  >
                    <InputLabel id="car">Should they have a car?</InputLabel>
                    <Select
                      labelId="car"
                      name="car"
                      label="Should they have a car?"
                      value={showEntry.car.value}
                      error={showEntry.car.error}
                      onChange={handleChangeEntry}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {/* Do you have kids?  Max Kids?"*/}
                <div>
                  <FormControl
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                  >
                    <InputLabel id="pkids">Do you have kids?</InputLabel>
                    <Select
                      labelId="pkids"
                      name="kids"
                      label="Do you have kids??"
                      value={showEntry.kids.value}
                      error={showEntry.kids.error}
                      onChange={handleChangeEntry}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    sx={{
                      m: 2,
                      width: { xs: "92%", sm: "45%" },
                    }}
                    type={"number"}
                    min={0}
                    label="How Many Kids?"
                    name="kidsnumber"
                    placeholder="How Many Kids"
                    variant="outlined"
                    className={"form-control"}
                    value={showEntry.kidsnumber.value}
                    error={showEntry.kidsnumber.error}
                    onChange={handleChangeEntry}
                  />
                </div>

                {/* Describe Yourself*/}
                <div style={{ margin: "2%" }}>
                  <TextField
                    multiline
                    rows={4}
                    name="describeYourself"
                    placeholder="Briefly Describe Yourself?"
                    label="Describe Yourself"
                    className={"form-control"}
                    variant="outlined"
                    value={showEntry.describeYourself.value}
                    onChange={handleChangeEntry}
                    error={showEntry.describeYourself.error}
                  ></TextField>
                </div>

                {/* What is your deal-breaker?*/}
                <div style={{ margin: "2%" }}>
                  <TextField
                    multiline
                    rows={4}
                    name="dealbreakers"
                    label="What is your deal breaker?"
                    className={"form-control"}
                    variant="outlined"
                    value={showEntry.dealbreakers.value}
                    error={showEntry.dealbreakers.error}
                    onChange={handleChangeEntry}
                  ></TextField>
                </div>
              </div>

              <div
                style={{ display: step.activeStep === 4 ? "block" : "none" }}
              >
                <h1>Media</h1>
                {/*Media Uploads*/}
                <CRow>
                  <CCol>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Upload a pic of yourself</Form.Label>
                      <Form.Control
                        type="file"
                        id="pic"
                        onChange={handleImage}
                        accept="image/*"
                      />
                    </Form.Group>
                  </CCol>
                  <CCol>
                    <img
                      style={{ padding: "20px" }}
                      id={"showPic"}
                      src={showPic}
                      className="img-fluid rounded mx-auto d-block"
                      alt=""
                    />
                  </CCol>
                </CRow>

                <CRow>
                  <CCol>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Upload a 2nd pic of yourself</Form.Label>
                      <Form.Control
                        type="file"
                        id="pic2"
                        onChange={handleImage}
                        accept="image/*"
                      />
                    </Form.Group>
                  </CCol>
                  <CCol>
                    <img
                      style={{ padding: "20px" }}
                      id={"showPic2"}
                      src={showPic2}
                      className="img-fluid rounded mx-auto d-block"
                      alt=""
                    />
                  </CCol>
                </CRow>

                <CRow>
                  <CCol>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Upload a 3rd pic of yourself</Form.Label>
                      <Form.Control
                        type="file"
                        id="pic3"
                        onChange={handleImage}
                        accept="image/*"
                      />
                    </Form.Group>
                  </CCol>
                  <CCol>
                    <img
                      style={{ padding: "20px" }}
                      id={"showPic3"}
                      src={showPic3}
                      className="img-fluid rounded mx-auto d-block"
                      alt=""
                    />
                  </CCol>
                </CRow>

                <CRow>
                  <Col>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>
                        Upload a 30 sec video introducing yourself
                      </Form.Label>
                      <Form.Control
                        type="file"
                        onChange={handleVideo}
                        accept="video/*"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <video
                      style={{ padding: "20px" }}
                      src={showVid}
                      id={"vidShow"}
                      className="img-fluid rounded mx-auto d-block"
                      controls
                    />
                  </Col>
                </CRow>
              </div>

              <div
                style={{ display: step.activeStep === 5 ? "block" : "none" }}
              >
                <CContainer className="text-center">
                  <FormGroup>
                    <Form.Label>
                      Accept terms and conditions to submit
                    </Form.Label>
                    {showTermsAndConditions()}
                    <div
                      className="sigPadContainer"
                      style={{ border: "double", margin: 1 }}
                    >
                      <label>Please sign</label>
                      <CRow>
                        <CCol>
                          <SignatureCanvas
                            penColor="black"
                            canvasProps={{
                              className: "sigCanvas",
                            }}
                            ref={sigCanvasRef}
                          />
                        </CCol>
                      </CRow>
                      <hr />
                      <CButton onClick={() => sigCanvasRef.current.clear()}>
                        Clear
                      </CButton>
                    </div>

                    <FormControlLabel
                      value="start"
                      control={
                        <Checkbox
                          checked={terms.checked}
                          onChange={handleTermsChange}
                          name="accept"
                          size="large"
                        />
                      }
                      label="I consent to Connect Channel (Pty) Ltd processing my personal information (and special personal information) in accordance with the above Privacy Policy"
                      labelPlacement="end"
                    />

                    {/*<CFormCheck*/}
                    {/*  // style={{ width: "15%", margin: 3 }}*/}
                    {/*  id="btn-check-outlined"*/}
                    {/*  // label="Accept Terms & Conditions"*/}
                    {/*  label="I consent to Connect Channel (Pty) Ltd processing my personal information (and special personal information) in accordance with the above Privacy Policy"*/}
                    {/*  autoComplete="off"*/}
                    {/*  onChange={handleTermsChange}*/}
                    {/*  value={terms.checked.toString()}*/}
                    {/*  style={{*/}
                    {/*    width: 40,*/}
                    {/*    height: 40,*/}
                    {/*    display: "flex",*/}
                    {/*    alignItems: "center",*/}
                    {/*    justifyContent: "center",*/}
                    {/*  }}*/}
                    {/*/>*/}
                  </FormGroup>
                </CContainer>
              </div>
            </div>
            {terms.checked === true ? (
              <div>
                <CButton
                  style={{ width: "15%", margin: 3 }}
                  color="danger"
                  variant="outline"
                  onClick={handleDelete}
                >
                  <CIcon icon={icon.cilTrash} size="xxl" /> Delete
                </CButton>
                <CButton
                  style={{ width: "15%", margin: 3 }}
                  color="success"
                  variant="outline"
                  type="submit"
                >
                  <CIcon icon={icon.cilSend} size="xxl" /> Submit
                </CButton>
              </div>
            ) : null}
            {terms.checked === false && step.activeStep !== 1 ? (
              <div>
                <CButton
                  variant="outline"
                  color="danger"
                  style={{ width: "15%", margin: 3 }}
                  onClick={handlePrevClick}
                  disabled={step.activeStep === 1}
                >
                  <CIcon icon={icon.cilArrowThickFromRight} size="xxl" /> Back
                </CButton>
                <CButton
                  color="primary"
                  variant="outline"
                  style={{ width: "15%", margin: 3 }}
                  onClick={handleNextClick}
                  disabled={step.activeStep === 5}
                >
                  Next <CIcon icon={icon.cilArrowThickFromLeft} size="xxl" />
                </CButton>
              </div>
            ) : null}
          </Box>
        </fieldset>
      </Container>
    </LoadingOverlay>
  );
}
