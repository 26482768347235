import { useEffect, useReducer, useState } from "react";
import {
  projectFirestore,
  projectStorage,
  timestamp,
} from "../firebase/config";
import emailjs from "@emailjs/browser";
import { dashObjectModel } from "../components/models";
//import { dashObjectModel } from "../components/mfs/models";
import { useMailServer } from "./useMailServer";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "./useAppContext";

let initialState = {
  document: null,
  isPending: false,
  error: null,
  success: null,
};

const firestoreReducer = (state, action) => {
  switch (action.type) {
    case "IS_PENDING":
      return { isPending: true, document: null, success: false, error: null };
    case "ADDED_DOCUMENT":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        document: null,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const useFirestore = (collection) => {
  const navigate = useNavigate();
  const [response, dispatch] = useReducer(firestoreReducer, initialState);
  const [isCancelled, setIsCancelled] = useState(false);
  const { setActive, setObject } = useAppContext();
  const { processEmail, processProfileEmail } = useMailServer();
  //ToDo HandleDashObjects for different shots
  const [dashObject, setDashObject] = useState(dashObjectModel);
  const [dashObjectEntry, setDashObjectEntry] = useState({});
  const [dashObjectPro, setDashObjectPro] = useState({});
  const updateEntryStatus = (profile, showEntry, settings, user, show) => {
    console.log("Submitting Entry");
    const entry = showEntry;
    const record = profile.cellphone;
    console.log(entry);
    console.log("Entry being created and created");
    const CreatedAt = timestamp.fromDate(new Date());
    //addDocument(entry);

    try {
      setActive(true);
      console.log("Updating Status");

      console.log(entry);
      projectFirestore
        .collection("Shows")
        .doc(show)
        .collection("Entries")
        .doc(record)
        .update(entry)
        .then((result) => {
          setActive(false);
          alert("Status Updated");
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  const createProfileFirestore = (
    //e,
    exists,
    profile,
    showEntry,
    settings,
    user,
    show
  ) => {
    profile.cellphone = user.phoneNumber;
    profile.uid = user.uid;
    showEntry.uid = user.uid;
    // showEntry.status = "Submitted Application";
    // setCellphone(user.phoneNumber);
    console.log("Submitting Profile");
    console.log(exists);
    //e.preventDefault();

    const prof = profile;

    const entry = showEntry;

    console.log(entry);
    console.log("Entry being created and created");
    const CreatedAt = timestamp.fromDate(new Date());
    //addDocument(entry);

    try {
      if (exists === "false") {
        setActive(true);
        console.log("Creating New");

        console.log(prof);
        console.log("ProfileOnly");

        projectFirestore
          .collection("Profiles")
          .doc(user.phoneNumber)
          .set({ ...prof, CreatedAt })
          .then(() => {
            setActive(false);
            processProfileEmail(profile, showEntry, exists);
            // sendEntryEmail(profile, entry, exists, settings);
            //navigate("/thankyou/" + show);
            //window.scrollTo(0, 0);
          });
        // alert("Thank you for your entry");
      } else {
        setActive(true);
        console.log("Updating");

        console.log(prof);
        console.log(entry);

        projectFirestore
          .collection("Profiles")
          .doc(user.phoneNumber)
          .update(prof)
          .then((result) => {
            setActive(false);
            processProfileEmail(profile, showEntry, exists);
            // sendEntryEmail(profile, entry, exists, settings);
            // navigate("/thankyou/" + show);
            //window.scrollTo(0, 0);
          });

        // alert("Thank you for your entry");
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const createDocumentFirestore = (
    e,
    exists,
    profile,
    showEntry,
    settings,
    user,
    show
  ) => {
    profile.cellphone = user.phoneNumber;
    profile.uid = user.uid;
    showEntry.uid = user.uid;
    // setCellphone(user.phoneNumber);
    console.log("Submitting Entry");
    console.log(exists);
    e.preventDefault();

    const prof = profile;

    const entry = showEntry;

    console.log(entry);
    console.log("Entry being created and created");
    const CreatedAt = timestamp.fromDate(new Date());
    //addDocument(entry);

    try {
      if (exists === "false") {
        // setActive(true);
        console.log("Creating New");

        console.log(prof);
        console.log(entry);

        projectFirestore
          .collection("Profiles")
          .doc(user.phoneNumber)
          .set({ ...prof, CreatedAt });

        projectFirestore
          .collection("Shows")
          .doc(show)
          .collection("Entries")
          .doc(user.phoneNumber)
          .set({ ...entry, CreatedAt })
          .then(() => {
            // setActive(false);
            processEmail(profile, entry, exists);
            //navigate("/thankyou/" + show);
            // window.scrollTo(0, 0);
          });

        // alert("Thank you for your entry");
      } else {
        // setActive(true);
        console.log("Updating");

        console.log(prof);
        console.log(entry);

        projectFirestore
          .collection("Profiles")
          .doc(user.phoneNumber)
          .update(prof);

        projectFirestore
          .collection("Shows")
          .doc(show)
          .collection("Entries")
          .doc(user.phoneNumber)
          .update(entry)
          .then((result) => {
            //setActive(false);
            //sendEntryEmail(profile, entry, exists, settings);
            processEmail(profile, showEntry, exists);
            //navigate("/thankyou/" + show);
            // navigate("/thankyou/" + show);
            // window.scrollTo(0, 0);
            // window.scrollTo(0, 0);
          });

        // alert("Thank you for your entry");
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const createDocumentFirestoreWithOutEmail = (
    e,
    exists,
    profile,
    showEntry,
    settings,
    user,
    show
  ) => {
    profile.cellphone = user.phoneNumber;
    profile.uid = user.uid;
    showEntry.uid = user.uid;
    // showEntry.status = "Submitted Application";
    // setCellphone(user.phoneNumber);
    console.log("Submitting Entry");
    console.log(exists);
    e.preventDefault();

    const prof = profile;

    const entry = showEntry;

    console.log(entry);
    console.log("Entry being created and created");
    const CreatedAt = timestamp.fromDate(new Date());
    //addDocument(entry);

    try {
      if (exists === "false") {
        setActive(true);
        console.log("Creating New");

        console.log(prof);
        console.log(entry);

        projectFirestore
          .collection("Profiles")
          .doc(user.phoneNumber)
          .set({ ...prof, CreatedAt });

        projectFirestore
          .collection("Shows")
          .doc(show)
          .collection("Entries")
          .doc(user.phoneNumber)
          .set({ ...entry, CreatedAt })
          .then(() => {
            setActive(false);
            // sendEntryEmail(profile, entry, exists, settings);
            navigate(show + "/list/" + show);
            window.scrollTo(0, 0);
          });

        // alert("Thank you for your entry");
      } else {
        setActive(true);
        console.log("Updating");

        console.log(prof);
        console.log(entry);

        projectFirestore
          .collection("Profiles")
          .doc(user.phoneNumber)
          .update(prof);

        projectFirestore
          .collection("Shows")
          .doc(show)
          .collection("Entries")
          .doc(user.phoneNumber)
          .update(entry)
          .then((result) => {
            setActive(false);
            processEmail(profile, showEntry, exists);
            // sendEntryEmail(profile, entry, exists, settings);
            navigate("/thankyou/" + show);
            window.scrollTo(0, 0);
          });

        // alert("Thank you for your entry");
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getDashCounts = async (show) => {
    const query = projectFirestore
      .collection("Profiles")
      .where("gender.value", "==", "Male");

    const observer = await query.onSnapshot(
      (querySnapshot) => {
        dashObject.male = querySnapshot.size;
        setDashObject({ ...dashObject, male: querySnapshot.size });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    const query2 = projectFirestore
      .collection("Profiles")
      .where("gender.value", "==", "Female");

    const observer2 = await query2.onSnapshot(
      (querySnapshot) => {
        dashObject.female = querySnapshot.size;
        setDashObject({ ...dashObject, female: querySnapshot.size });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    const query3 = projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries")
      .onSnapshot((snap) => {
        dashObject.total = snap.size;
      });

    var DateRightNow = Date.now();
    var Begin7Days = Date.now() - 604800000;
    var DateObject = new Date(DateRightNow);
    var BeginObject = new Date(Begin7Days);
    var Begin24 = Date.now() - 86400000;
    var Begin24Object = new Date(Begin24);

    var OnlyToday = DateObject.setHours("00", "00", "00", "00");
    var OnlyTodayObj = new Date(OnlyToday);

    var OnlyYesterday = Begin24Object.setHours("00", "00", "00", "00");
    var OnlyYesterObj = new Date(OnlyYesterday);

    console.log("This is 7 Days Ago" + BeginObject);
    console.log("This is today midnight" + DateObject);
    console.log("This is yesterday midnight" + Begin24Object);
    // console.log(OnlyTodayObj);

    var RightNow = Date.now();
    var NowObject = new Date(RightNow);
    console.log("This is right now " + NowObject);
    console.log("This is today Midnight " + DateObject);

    const query4 = projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries")
      .where("CreatedAt", "<=", NowObject)
      .where("CreatedAt", ">=", BeginObject);

    const observer4 = await query4.onSnapshot(
      (querySnapshot) => {
        dashObject.sevendays = querySnapshot.size;
        setDashObject({ ...dashObject, sevendays: querySnapshot.size });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    const query5 = projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries")
      .where("CreatedAt", "<=", DateObject)
      .where("CreatedAt", ">=", Begin24Object);

    const observer5 = await query5.onSnapshot(
      (querySnapshot) => {
        dashObject.yesterday = querySnapshot.size;
        setDashObject({ ...dashObject, yesterday: querySnapshot.size });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    const query6 = projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries")
      .where("CreatedAt", ">=", DateObject)
      .where("CreatedAt", "<=", NowObject);

    const observer6 = await query6.onSnapshot(
      (querySnapshot) => {
        dashObject.today = querySnapshot.size;
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    const query7 = projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries")
      .where("coupleEntry", "==", "true");

    const observer7 = await query7.onSnapshot(
      (querySnapshot) => {
        dashObject.couples = querySnapshot.size;
        setDashObject({ ...dashObject, couples: querySnapshot.size });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    const query8 = projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries")
      .where("coupleEntry", "==", "false");

    const observer8 = await query8.onSnapshot(
      (querySnapshot) => {
        dashObject.single = querySnapshot.size;
        setDashObject({ ...dashObject, single: querySnapshot.size });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    const query10 = projectFirestore
      // .collection("Shows")
      // .doc(show)
      .collection("Profiles")
      .where("gender.value", "==", "Male")
      .where("CreatedAt", ">=", DateObject)
      .where("CreatedAt", "<=", NowObject);

    const observer10 = await query10.onSnapshot(
      (querySnapshot) => {
        dashObject.maletoday = querySnapshot.size;
        setDashObject({ ...dashObject, maletoday: querySnapshot.size });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    const query11 = projectFirestore
      // .collection("Shows")
      // .doc(show)
      .collection("Profiles")
      .where("gender.value", "==", "Female")
      .where("CreatedAt", ">=", DateObject)
      .where("CreatedAt", "<=", NowObject);

    const observer11 = await query11.onSnapshot(
      (querySnapshot) => {
        dashObject.femaletoday = querySnapshot.size;
        setDashObject({ ...dashObject, femaletoday: querySnapshot.size });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };

  // const getDashCounts = async (show) => {
  //   const countField = async (fieldName, fieldValue) => {
  //     const observer = await projectFirestore
  //       .collection("Profiles")
  //       .where(fieldName, "==", fieldValue)
  //       .onSnapshot(
  //         (querySnapshot) => {
  //           dashObjectPro[fieldValue] = querySnapshot.size;
  //           setDashObjectPro({
  //             ...dashObjectPro,
  //             [fieldValue]: querySnapshot.size,
  //           });
  //         },
  //         (err) => {
  //           console.log(`Encountered error: ${err}`);
  //         }
  //       );
  //   };
  //
  //   try {
  //     await countField("gender.value", "Male");
  //     await countField("gender.value", "Female");
  //   } catch (err) {
  //     console.log(`Encountered error: ${err}`);
  //   }
  //
  //   const getEntriesCountInDateRange = async (
  //     dateStart,
  //     dateEnd,
  //     fieldName
  //   ) => {
  //     const observer = await projectFirestore
  //       .collection("Profiles")
  //       .where("CreatedAt", ">=", dateStart)
  //       .where("CreatedAt", "<=", dateEnd)
  //       .onSnapshot(
  //         (querySnapshot) => {
  //           dashObjectPro[fieldName] = querySnapshot.size;
  //           setDashObjectPro({
  //             ...dashObjectPro,
  //             [fieldName]: querySnapshot.size,
  //           });
  //         },
  //         (err) => {
  //           console.log(`Encountered error: ${err}`);
  //         }
  //       );
  //   };
  //
  //   var DateRightNow = Date.now();
  //   var Begin7Days = Date.now() - 604800000;
  //   var BeginObject = new Date(Begin7Days);
  //   var Begin24 = Date.now() - 86400000;
  //   var Begin24Object = new Date(Begin24);
  //
  //   var OnlyToday = Begin24Object.setHours("00", "00", "00", "00");
  //   var OnlyTodayObj = new Date(OnlyToday);
  //
  //   var OnlyYesterday = Begin24Object.setHours("00", "00", "00", "00");
  //   var OnlyYesterObj = new Date(OnlyYesterday);
  //
  //   try {
  //     await getEntriesCountInDateRange(BeginObject, DateRightNow, "sevendays");
  //     await getEntriesCountInDateRange(
  //       OnlyYesterObj,
  //       OnlyTodayObj,
  //       "yesterday"
  //     );
  //     await getEntriesCountInDateRange(OnlyTodayObj, DateRightNow, "today");
  //   } catch (err) {
  //     console.log(`Encountered error: ${err}`);
  //   }
  // };

  const getDashTotals = async (show, tileDataList) => {
    const countField = async (fieldName, fieldValue) => {
      try {
        const querySnapshot = await projectFirestore
          .collection("Shows")
          .doc(show)
          .collection("Entries")
          .where(fieldName, "==", fieldValue)
          .get();

        return querySnapshot.size;
        console.log(querySnapshot.size);
      } catch (err) {
        console.log(`Encountered error: ${err}`);
        return 0;
      }
    };

    const fetchDashData = async () => {
      const promises = tileDataList.map(async (tileData) => {
        const { fieldname } = tileData;
        // const count = await countField(fieldname.field, fieldname.value);
        const count = await countField(
          fieldname.field + ".value",
          fieldname.value
        );

        return { fieldname: fieldname.field, count };
      });

      const resolvedData = await Promise.all(promises);

      // Update the dashObject with the resolved data
      const updatedDashObject = {};
      resolvedData.forEach(({ fieldname, count }) => {
        updatedDashObject[fieldname] = count;
      });

      return updatedDashObject;
    };

    try {
      return await fetchDashData();
    } catch (err) {
      console.log(`Encountered error: ${err}`);
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return {
    deleteDocument,
    response,
    createDocumentFirestore,
    createProfileFirestore,
    createDocumentFirestoreWithOutEmail,
    getDashCounts,
    dashObject,
    getDashTotals,
    updateEntryStatus,
  };
};

export const getSettings = async (show) => {
  let settings = [];

  const results = await projectFirestore.collection("Settings").doc(show).get();
  settings = results.data();
  console.log({ settings });
  return settings;
};

export const getAllShows = async () => {
  let shows = [];
  const results = await projectFirestore.collection("Settings").get();
  shows = results.docs.map((doc) => doc.data());
  console.log({ shows });
  return shows;
};

export const getProfile = async (mobileNumber) => {
  let profile = {};
  const results = await projectFirestore
    .collection("Profiles")
    .doc(mobileNumber)
    .get();
  profile = results.data();
  console.log({ profile });
  return profile;
};

export const getEntry = async (show, mobileNumber) => {
  let entry = {};
  const results = await projectFirestore
    .collection("Shows")
    .doc(show)
    .collection("Entries")
    .doc(mobileNumber)
    .get();
  entry = results.data();
  console.log({ entry });
  return entry;
};

export const picUpload = async (event, show, name, surname, mobileNumber) => {
  const uploadedFile = event.target.files[0];
  if (!uploadedFile) return;

  const storage = projectStorage;
  const storageRef = storage.ref();
  const downloadURL = "";
  try {
    // await storageRef.child(uploadedFile.name).put(uploadedFile);

    var refObject = await storageRef
      .child("EntriesMedia/" + show + "/" + name + surname + mobileNumber)
      .put(uploadedFile)
      .then(async (response) => {
        response.ref.getDownloadURL().then((link) => {
          const downloadURL = link;
          console.log(downloadURL);
        });
      });
    // alert("Successfully uploaded video!");
  } catch (error) {
    console.log("error", error);
    alert(error.message);
  }

  return downloadURL;
};

export const videoUpload = async (event, show, name, surname, mobileNumber) => {
  const uploadedFile = event.target.files[0];
  if (!uploadedFile) return;

  const storage = projectStorage;
  const storageRef = storage.ref();

  try {
    // await storageRef.child(uploadedFile.name).put(uploadedFile);

    var refObject = await storageRef
      .child("EntriesMedia/" + show + "/" + name + surname + mobileNumber)
      .put(uploadedFile)
      .then(async (response) => {
        let downloadURL = await response.ref.getDownloadURL();
        console.log(downloadURL);
        return downloadURL;
      });
    // alert("Successfully uploaded video!");
  } catch (error) {
    console.log("error", error);
    alert(error.message);
  }
};

export const sendEntryEmail = async (profile, entry, exists, settings) => {
  var rentry = exists === "true" ? "(Update)" : "";
  var showDetails = { ...entry };
  var profileDetails = { ...profile };
  var templateParams = {
    from_name:
      rentry +
      profile.name.value +
      " " +
      profile.surname.value +
      " " +
      profile.gender.value +
      profile.age.value,
    to_name: profile.name.value,
    reply_to: profile.email.value,
    message: "",
    cc: settings.showEmail,
    update: rentry,
  };

  templateParams = { ...templateParams, ...showDetails, ...profileDetails };

  emailjs
    .send(
      settings.serviceID,
      settings.templateID,
      templateParams,
      settings.publicKey
    )
    .then(
      (result) => {
        console.log(result.text);
        return result;
      },
      (error) => {
        console.log(error.text);
        return error;
      }
    );
};

export const deleteDocument = (show, cellnumber) => {
  projectFirestore
    .collection("Shows")
    .doc(show)
    .collection("Entries")
    .doc(cellnumber)
    .delete()
    .then(() => {
      console.log("Document successfully deleted!");
    })
    .catch((error) => {
      console.error("Error removing document: ", error);
    });
};

// //Collection ref
// const ref = projectFirestore.collection(collection);
//
// //Only dispatch is not cancelled
// const dispatchIfNotCancelled = (action) => {
//   if (!isCancelled) {
//     dispatch(action);
//   }
// };
//
// //dd
// const addDocument = async (doc) => {
//   dispatch({ type: "IS_PENDING" });
//   try {
//     const createdAt = timestamp.fromDate(new Date());
//     const addedDocument = await ref.add({ ...doc, createdAt });
//     dispatchIfNotCancelled({
//       type: "ADDED_DOCUMENT",
//       payload: addedDocument,
//     });
//   } catch (err) {
//     dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
//   }
// };
//
// const deleteDocument = (show, cellnumber) => {
//   projectFirestore
//     .collection("Shows")
//     .doc(show)
//     .collection("Entries")
//     .doc(cellnumber)
//     .delete()
//     .then(() => {
//       console.log("Document successfully deleted!");
//     })
//     .catch((error) => {
//       console.error("Error removing document: ", error);
//     });
// };

// export const createDocumentFirestore = (
//   e,
//   exists,
//   profile,
//   showEntry,
//   settings,
//   user,
//   show
// ) => {
//   profile.cellphone = user.phoneNumber;
//   profile.uid = user.uid;
//   showEntry.uid = user.uid;
//   // setCellphone(user.phoneNumber);
//   console.log("Submitting Entry");
//   console.log(exists);
//   e.preventDefault();
//
//   const prof = profile;
//
//   const entry = showEntry;
//
//   console.log(entry);
//   console.log("Entry being created and created");
//   const CreatedAt = timestamp.fromDate(new Date());
//   //addDocument(entry);
//
//   try {
//     if (exists === "false") {
//       // setActive(true);
//       console.log("Creating New");
//
//       console.log(prof);
//       console.log(entry);
//
//       projectFirestore
//         .collection("Profiles")
//         .doc(user.phoneNumber)
//         .set({ ...prof, CreatedAt });
//
//       projectFirestore
//         .collection("Shows")
//         .doc(show)
//         .collection("Entries")
//         .doc(user.phoneNumber)
//         .set({ ...entry, CreatedAt })
//         .then(() => {
//           // setActive(false);
//           sendEntryEmail(profile, entry, exists, settings);
//           //navigate("/thankyou/" + show);
//           window.scrollTo(0, 0);
//         });
//
//       // alert("Thank you for your entry");
//     } else {
//       // setActive(true);
//       console.log("Updating");
//
//       console.log(prof);
//       console.log(entry);
//
//       projectFirestore
//         .collection("Profiles")
//         .doc(user.phoneNumber)
//         .update(prof);
//
//       projectFirestore
//         .collection("Shows")
//         .doc(show)
//         .collection("Entries")
//         .doc(user.phoneNumber)
//         .update(entry)
//         .then((result) => {
//           //setActive(false);
//           sendEntryEmail(profile, entry, exists, settings);
//           //navigate("/thankyou/" + show);
//           window.scrollTo(0, 0);
//         });
//
//       // alert("Thank you for your entry");
//     }
//   } catch (err) {
//     console.log(err.message);
//   }
// };
