import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "react-phone-input-2/lib/bootstrap.css";

import {
  Form,
  Alert,
  FormGroup,
  Button,
  Container,
  Image,
  Row,
  Col,
} from "react-bootstrap";
import { projectAuth } from "../firebase/config";
import { useAuthContext } from "../hooks/useAuthContext";
import "../stylesheets/style.css";
import { TextField } from "@mui/material";
import logo from "../images/ewizlogo.png";

export function LogIn() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState("");
  const [flag] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { dispatch } = useAuthContext();

  projectAuth.useDeviceLanguage();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (email === "" || email === undefined) {
      return setError("Please enter a valid email");
    }

    try {
      projectAuth
        .signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // Signed in
          // let user = userCredential.user;
          // .
          dispatch({ type: "LOGIN", payload: userCredential.user });
          console.log(userCredential);
          if (userCredential.user.email) {
            navigate("/");
          }
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorMessage + " " + errorCode);
          setError(error.message);
        });
    } catch (err) {
      setError(err.message);
    }

    console.log();
  };

  return (
    <Container>
      <div className="p-4 .container-fluid">
        <Row className="justify-content-center">
          <Col md="auto">
            <Image
              src={"../ewizlogo.png"}
              className="Wiz-App-logo img-fluid rounded"
              alt="logo"
              style={{ padding: "20px", width: "800px" }}
            />
          </Col>
        </Row>
        <br />
        {error && <Alert variant="danger">{error}</Alert>}
        <Form
          onSubmit={handleSubmit}
          style={{ display: !flag ? "block" : "none" }}
        >
          <FormGroup className="mb-3 col text-center">
            <TextField
              value={email}
              id={"email"}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              label={"Email"}
            ></TextField>
          </FormGroup>
          <FormGroup className="mb-3 col text-center">
            <TextField
              value={password}
              type={"password"}
              id={"password"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              label={"Password"}
            ></TextField>
          </FormGroup>

          <FormGroup className="mb-3">
            <div className="col text-center">
              <Button
                className="text-center"
                variant="primary"
                value="Submit"
                type="submit"
              >
                Login
              </Button>
            </div>
          </FormGroup>
        </Form>
      </div>
    </Container>
  );
}
