import React, { useCallback, useEffect, useState } from "react";
import TransactionList from "./transactionList";
import { useCollection } from "../../hooks/useCollection";
import { DataGrid } from "devextreme-react";
import { projectFirestore } from "../../firebase/config";
import { useParams } from "react-router-dom";
import { Column, HeaderFilter } from "devextreme-react/gantt";
import { Export } from "devextreme-react/chart";
import { listColumns } from "./models";
import {
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import { useAppContext } from "../../hooks/useAppContext";
import { CRow } from "@coreui/react";

export function EntryList() {
  const { show } = useParams();
  const [documents, setDocuments] = useState(null);
  const [profiles, setProfiles] = useState(null);
  const [mergedSets, setMergedSets] = useState([]);
  const { setObject } = useAppContext();
  // const arr1 = [
  //   { id: "abdc4051", date: "2017-01-24" },
  //   { id: "abdc4052", date: "2017-01-22" },
  //   { id: "abdc4053", date: "2017-01-22" },
  // ];
  // const arr2 = [
  //   { nameId: "abdc4051", name: "ab" },
  //   { nameId: "abdc4052", name: "abc" },
  // ];

  // const map = new Map();
  // arr1.forEach((item) => map.set(item.id, item));
  // arr2.forEach((item) =>
  //   map.set(item.nameId, { ...map.get(item.nameId), ...item })
  // );
  // const mergedArr = Array.from(map.values());

  const combineProfileEntries = useCallback(async () => {
    if (profiles !== null && documents !== null) {
      // const mapSets = new Map();
      // profiles.forEach((item) => mapSets.set(item.uid, item));
      // documents.forEach((item) =>
      //   mapSets.set(item.uid, { ...mapSets.get(item.uid), ...item })
      // );
      // setMergedSets(Array.from(mapSets.values()));

      const mapSets = new Map();
      documents.forEach((item) => {
        const profile = profiles.find((p) => p.uid === item.uid);
        if (profile) {
          mapSets.set(item.uid, { ...item, ...profile });
        }
      });
      setMergedSets(Array.from(mapSets.values()));
    }
  }, [documents, profiles]);

  const getAllProfiles = async () => {
    let entry = {};
    const ref = await projectFirestore.collection("Profiles");

    const unsubscibe = ref.onSnapshot(
      (snapshot) => {
        let results = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });

        ///update state
        setProfiles(results);

        // setError(null);
      },
      (error) => {
        console.log(error);
        // setError("could not fetch the data");
      }
    );
    //unsubscribe on amount
    return () => unsubscibe();
  };

  const getAllEntries = async () => {
    let entry = {};
    const ref = await projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries");
    const unsubscibe = ref.onSnapshot(
      (snapshot) => {
        let results = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });

        ///update state
        setDocuments(results);
        // setError(null);
      },
      (error) => {
        console.log(error);
        // setError("could not fetch the data");
      }
    );

    //unsubscribe on amount
    return () => unsubscibe();
  };

  const renderGridCell = (data) => {
    // return (
    //   <a
    //     href={"/dmf/entry/" + show + "/" + data.text}
    //     target="_blank"
    //     rel="noopener noreferrer"
    //   >
    //     check
    //   </a>
    // );
    return (
      <a
        href={"/" + show + "/list/entry/" + show + "/" + data.text}
        target="_blank"
        rel="noopener noreferrer"
      >
        check
      </a>
    );
  };

  useEffect(() => {
    //console.log(user);

    if (profiles == null) {
      getAllProfiles();
      getAllEntries();
    }
    combineProfileEntries();
  }, [combineProfileEntries]);

  const renderDate = (data) => {
    return new Date(data.value.seconds * 1000).toDateString();
    // return data.toString();
  };

  const renderTime = (data) => {
    return new Date(data.value.seconds * 1000).toTimeString();
  };

  const allowedPageSizes = [10, 20, 50];

  return (
    <div className="testbox container">
      {/*<h1>2021 Entries Loading</h1>*/}
      {/*/!* <div>entryList</div> *!/*/}
      {/*{error && <p>{error}</p>}*/}
      {/*{documents && <TransactionList transactions={documents} />}*/}

      {/*<img*/}
      {/*  style={{ padding: "20px" }}*/}
      {/*  // src={"/../images/" + show + ".jpeg"}*/}
      {/*  src={setObject.showLogo}*/}
      {/*  className="img-fluid rounded mx-auto d-block"*/}
      {/*  alt=""*/}
      {/*/>*/}
      <CRow>
        <img
          style={{ padding: "50px", width: "500px" }}
          // src={"../images/" + show + ".jpeg"}
          src={setObject.showLogo}
          className="img-fluid rounded mx-auto d-block"
          alt=""
        />
      </CRow>
      {/*<img*/}
      {/*  style={{ padding: "20px" }}*/}
      {/*  src={"/../images/" + show + ".jpeg"}*/}
      {/*  className="img-fluid rounded mx-auto d-block"*/}
      {/*  alt="Our Perfect Wedding"*/}
      {/*/>*/}
      <CRow>
        <DataGrid dataSource={mergedSets} showBorders={true}>
          <HeaderFilter visible={true} />
          <GroupPanel visible={true} />
          <Grouping autoExpandAll={false} />
          <SearchPanel visible={true} highlightCaseSensitive={true} />
          <Export enabled={true} allowExportSelectedData={true} />
          <Selection mode="multiple" />
          <Column
            dataField="CreatedAt"
            caption="Date"
            sortOrder={"desc"}
            cellRender={renderDate}
          />
          <Column dataField="name.value" />
          <Column dataField="surname.value" />
          <Column dataField="gender.value" />
          <Column dataField="date.value" />
          <Column dataField="kids.value" />
          <Column dataField="religion.value" />
          <Column dataField="car.value" />
          <Column dataField="province" />
          <Column dataField="city" />
          <Column
            dataField="cellphone"
            caption="entry"
            cellRender={renderGridCell}
          />
          <Column dataField="status" />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={allowedPageSizes}
          />
        </DataGrid>
      </CRow>
    </div>
  );
}
