import { Button, Col, Container, Image, Row } from "react-bootstrap";
import logo from "../images/ewizlogo.png";
import { useNavigate } from "react-router-dom";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardImage,
  CCol,
  CRow,
} from "@coreui/react";
import { getAllShows } from "../hooks/useFirestore";
import { useEffect, useState, React } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

export function IndexMainPage() {
  const [list, setList] = useState();
  const [itemData, setItemData] = useState([]);
  const [isActive, setActive] = useState(true);
  let navigate = useNavigate();

  // useEffect(() => {
  //   let start = async () => {
  //     let data = [];
  //     const shows = await getAllShows();
  //
  //     // ToDo list is null
  //     list?.map((d) =>
  //       data.push({
  //         id: d.showCode,
  //         imgUrl: d.imgUrl,
  //         title: d.showName,
  //         url: "/settings/" + d.showCode,
  //         dash: "/dashboard/" + d.showCode,
  //         entries: "/list/" + d.showCode,
  //         param: d.showCode,
  //       })
  //     );
  //     setItemData(data);
  //     setActive(false);
  //     setList(shows);
  //   };
  //   start();
  // }, []);
  useEffect(() => {
    let start = async () => {
      let data = [];
      const shows = await getAllShows();

      shows.map((d) =>
        data.push({
          id: d.showCode,
          imgUrl: d.showLogo,
          title: d.showName,
          url: "/settings/" + d.showCode,
          dash: "/dashboard/" + d.showCode,
          entries: "/" + d.showCode + "/list/" + d.showCode,
          param: d.showCode,
        })
      );
      setItemData(data);
      setActive(false);
      setList(shows);
    };
    start();
  }, []);

  function logOut() {}

  return (
    <div>
      {isActive && (
        <LoadingOverlay active={isActive} spinner text="">
          <div>
            <h1 id={"loading"}>
              <span className="let1">l</span>
              <span className="let2">o</span>
              <span className="let3">a</span>
              <span className="let4">d</span>
              <span className="let5">i</span>
              <span className="let6">n</span>
              <span className="let7">g</span>
            </h1>
          </div>
        </LoadingOverlay>
      )}

      {!isActive && (
        <Container>
          <Row className="justify-content-center">
            <Col md="auto">
              <Image
                src={logo}
                className="Wiz-App-logo img-fluid rounded"
                alt="logo"
                style={{ padding: "20px", width: "800px" }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="auto">
              <CRow md={{ cols: 3 }}>
                {itemData.map((item) => (
                  <CCol md key={item.id}>
                    <CCard className="text-center" style={{ margin: 5 }}>
                      <CCardImage
                        className="card-img-top"
                        style={{
                          padding: "50px",
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                        }}
                        orientation="top"
                        src={item.imgUrl}
                      />
                      <CCardBody>
                        <CButtonGroup>
                          <CButton
                            onClick={() => {
                              navigate(`${item.url}`, { id: item.param });
                            }}
                            color="primary"
                            variant="outline"
                            style={{ width: 110, height: 40 }}
                          >
                            Settings
                          </CButton>
                          <CButton
                            onClick={() => {
                              navigate(`${item.dash}`, { id: item.param });
                              window.scrollTo(0, 0);
                            }}
                            color="primary"
                            variant="outline"
                            style={{ width: 110, height: 40 }}
                          >
                            Dashboard
                          </CButton>
                          <CButton
                            onClick={() => {
                              navigate(`${item.entries}`, { id: item.param });
                              window.scrollTo(0, 0);
                            }}
                            color="primary"
                            variant="outline"
                            style={{ width: 110, height: 40 }}
                          >
                            Entries
                          </CButton>
                        </CButtonGroup>
                      </CCardBody>
                    </CCard>
                  </CCol>
                ))}
              </CRow>
            </Col>
          </Row>
        </Container>

        // <Container>
        //   <Row className="justify-content-center">
        //     <Col md="auto">
        //       <Image
        //         src={logo}
        //         className="Wiz-App-logo img-fluid rounded"
        //         alt="logo"
        //         style={{ padding: "20px", width: "800px" }}
        //       />
        //     </Col>
        //   </Row>
        //   <Row className="justify-content-center">
        //     <Col md="auto">
        //       {/*<CCardGroup>*/}
        //       {/*xs={{ cols: 1, gutter: 4 }}*/}
        //       <CRow md={{ cols: 3 }}>
        //         {itemData.map((item) => (
        //           <CCol md key={item.id}>
        //             <CCard className="text-center" style={{ margin: 5 }}>
        //               <CCardImage
        //                 className="card-img-top"
        //                 // style={{ width: 600, height: 300 }}
        //                 style={{ padding: "50px" }}
        //                 orientation="top"
        //                 src={item.imgUrl}
        //               />
        //               <CCardBody>
        //                 <CButtonGroup>
        //                   <CButton
        //                     onClick={() => {
        //                       navigate(`${item.url}`, { id: item.param });
        //                     }}
        //                     color="primary"
        //                     variant="outline"
        //                     style={{ width: 110, height: 40 }}
        //                   >
        //                     Settings
        //                   </CButton>
        //                   <CButton
        //                     onClick={() => {
        //                       navigate(`${item.dash}`, { id: item.param });
        //                       window.scrollTo(0, 0);
        //                     }}
        //                     color="primary"
        //                     variant="outline"
        //                     style={{ width: 110, height: 40 }}
        //                   >
        //                     Dashboard
        //                   </CButton>
        //                   <CButton
        //                     onClick={() => {
        //                       navigate(`${item.entries}`, { id: item.param });
        //                       window.scrollTo(0, 0);
        //                     }}
        //                     color="primary"
        //                     variant="outline"
        //                     style={{ width: 110, height: 40 }}
        //                   >
        //                     Entries
        //                   </CButton>
        //                 </CButtonGroup>
        //               </CCardBody>
        //             </CCard>
        //           </CCol>
        //         ))}
        //       </CRow>
        //     </Col>
        //   </Row>
        // </Container>
      )}
    </div>
  );
}
