import React, { useCallback, useEffect, useState } from "react";
import { DataGrid } from "devextreme-react";
import { projectFirestore } from "../../firebase/config";
import { useParams } from "react-router-dom";
import { Column, HeaderFilter } from "devextreme-react/gantt";
import { Export } from "devextreme-react/chart";
import "../mfs/showStyle.css";
import { useNavigate } from "react-router-dom";

import {
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import { useAppContext } from "../../hooks/useAppContext";
import { CRow } from "@coreui/react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
} from "@mui/material";

export function EntryList({ show, columns }) {
  const [documents, setDocuments] = useState(null);
  const [profiles, setProfiles] = useState(null);
  const [mergedSets, setMergedSets] = useState([]);
  const { setObject } = useAppContext();
  const [selectedRowData, setSelectedRowData] = useState(null);

  const navigate = useNavigate();

  const handleSelectionChanged = (e) => {
    const selectedRowsData = e.selectedRowsData;
    if (selectedRowsData.length > 0) {
      setSelectedRowData(selectedRowsData[0]); // Select the first selected row
    }
  };

  const combineProfileEntries = useCallback(async () => {
    if (profiles !== null && documents !== null) {
      const mapSets = new Map();
      documents.forEach((item) => {
        const profile = profiles.find((p) => p.uid === item.uid);
        if (profile) {
          mapSets.set(item.uid, { ...item, ...profile });
        }
      });
      setMergedSets(Array.from(mapSets.values()));
      console.log("mergedSets", Array.from(mapSets.values()));

      console.log("columns", columns);
    }
  }, [documents, profiles]);

  const getAllProfiles = async () => {
    let entry = {};
    const ref = await projectFirestore.collection("Profiles");

    const unsubscibe = ref.onSnapshot(
      (snapshot) => {
        let results = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setProfiles(results);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => unsubscibe();
  };

  const getAllEntries = async () => {
    let entry = {};
    const ref = await projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries");
    const unsubscibe = ref.onSnapshot(
      (snapshot) => {
        let results = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setDocuments(results);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => unsubscibe();
  };

  useEffect(() => {
    if (profiles == null) {
      getAllProfiles();
      getAllEntries();
    }
    combineProfileEntries();
  }, [combineProfileEntries]);

  const renderGridCell = (data) => {
    return (
      <a
        href={"/" + show + "/list/entry/" + show + "/" + data.text}
        target="_blank"
        rel="noopener noreferrer"
      >
        check
      </a>
    );
  };

  // const renderDate = (data) => {
  //   return new Date(data.value.seconds * 1000).toDateString();
  // };

  // const renderDate = (data) => {
  //   const date = new Date(data.value.seconds * 1000);
  //   const day = date.getDate(); // Get the day of the month (1-31)
  //   const month = date.toLocaleString("default", { month: "long" }); // Get the month name
  //   return `${day} ${month}`;
  // };

  const renderDate = (data) => {
    const date = new Date(data.value.seconds * 1000);
    const day = date.getDate(); // Get the day of the month (1-31)
    const month = date.toLocaleString("default", { month: "short" });
    const dayOfWeek = date.toLocaleString("default", { weekday: "short" }); // Get the abbreviated day of the week
    return `${dayOfWeek} ${day < 10 ? "0" : ""}${day} ${
      month < 10 ? "0" : ""
    }${month}`;
  };

  const allowedPageSizes = [10, 20, 50];

  // const generateColumns = () => {
  //   return columns.map((field) => {
  //     if (typeof field === "object" && field.value) {
  //       // Handle fields with structure name: value
  //       return <Column key={field.name} dataField={field.value} />;
  //     } else {
  //       // Handle fields with regular structure
  //       return <Column key={field} dataField={field} />;
  //     }
  //   });
  // };

  const generateColumns = () => {
    return columns.map((field) => {
      return (
        <Column
          key={field}
          dataField={`${field}.value`}
          caption={field.charAt(0).toUpperCase() + field.slice(1)}
        />
      );
    });
  };

  return (
    <Container>
      <div>
        {/* ... */}
        <CRow>
          <img
            style={{ padding: "50px", width: "500px" }}
            // src={"../images/" + show + ".jpeg"}
            src={setObject.showLogo}
            className="img-fluid rounded mx-auto d-block logo"
            alt=""
          />
        </CRow>
        <Card style={{ margin: 10, padding: 10 }}>
          <DataGrid
            dataSource={mergedSets}
            showBorders={true}
            onSelectionChanged={handleSelectionChanged}
            allowColumnResizing={true}
          >
            <SearchPanel visible={true} placeholder="Search..." />
            <Export enabled={true} allowExportSelectedData={true} />
            <HeaderFilter visible={true} />
            <GroupPanel visible={true} />
            <Grouping autoExpandAll={false} />
            <Selection mode="single" />
            <Column
              dataField="CreatedAt"
              caption="Date"
              sortOrder={"desc"}
              cellRender={renderDate}
            />
            {generateColumns()}
            <Column
              dataField="cellphone"
              caption="entry"
              cellRender={renderGridCell}
            />
            <Paging defaultPageSize={10} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={allowedPageSizes}
            />
          </DataGrid>
        </Card>

        {selectedRowData && (
          <Card style={{ margin: 10, padding: 10 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <CardMedia
                      sx={{ height: 400 }}
                      image={selectedRowData.pic.value}
                      title="green iguana"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CardMedia
                      sx={{ height: 400 }}
                      image={selectedRowData.pic2.value}
                      title="green iguana"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CardMedia
                      sx={{ height: 400 }}
                      image={selectedRowData.pic3.value}
                      title="green iguana"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <CardContent>
                  <p style={{ fontWeight: "bold" }}>Selected Record:</p>
                  <p>Date of birth: {selectedRowData.DOB.value}</p>
                  <p>Education: {selectedRowData.educationLevel.value}</p>
                  <p>
                    Hobbies & Interests:{" "}
                    {selectedRowData.hobbiesInterests.value}
                  </p>
                  <p>Weight: {selectedRowData.weightKG.value}</p>
                  <p>Height: {selectedRowData.height.value}</p>
                  <p>
                    What is your profession: {selectedRowData.profession.value}
                  </p>
                  <p>
                    Type Of Movies Enjoy:{" "}
                    {selectedRowData.typeOfMoviesEnjoy.value}
                  </p>
                  <p>What you into: {selectedRowData.memberOfClubs.value}</p>
                  <p>About: {selectedRowData.about.value}</p>
                  {/* Display additional fields as needed */}
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() =>
                      navigate(
                        "/" +
                          show +
                          "/list/entry/" +
                          show +
                          "/" +
                          selectedRowData.cellphone
                      )
                    }
                  >
                    View Record
                  </Button>
                </CardActions>
              </Grid>

              <Grid item xs={6}>
                <video
                  src={selectedRowData.video.value} // assuming this is a suitable video
                  title="selected video"
                  height={400}
                  controls
                />
              </Grid>
            </Grid>
          </Card>
        )}
      </div>
    </Container>
  );
}
