import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "../../stylesheets/style.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-rating";
import { MyForm, StepComponent } from "../../hooks/useEWizFormComponents";

import { useEntryWizFunctions } from "../../hooks/useEntryWizFunctions";
import { mfsEntry, entrantProfile, excludeValidation } from "./models";
import { CButton } from "@coreui/react";
import showTermsAndConditions from "./showtermsandconditions";
import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";
import ProfileForm from "../shared/ProfileForm";
import MediaUploadForm from "../shared/MediaUploadForm";
import ShowForm from "../shared/ShowForm";
import CustomEntryForm from "./customEntryFrom";
import TermsAndConditionsForm from "../shared/TermsAndConditionsForm";
import { TextField } from "@mui/material";
import Hotjar from "@hotjar/browser";

export function EntryForm() {
  const {
    fetchEntry,
    fetchProfile,
    handleNextClick,
    handlePrevClick,
    handleTermsChange,
    handleImage,
    handleVideo,
    handleSubmit,
    handleChangeEntry,
    handleChangeProfile,
    handleChangeProfileNonValidate,
    step,
    showEntry,
    profile,
    isActive,
    terms,
    show,
    cellNumber,
    setObject,
    showPic,
    showPic2,
    showPic3,
    showVid,
    ref,
    setActive,
    addressSearch,
    setAddressSearch,
    sigCanvasRef,
    updateAddress,
    address,
    setAddress,
    handleChangeEntryM,
    handleChangeProfileDate,
  } = useEntryWizFunctions(entrantProfile, mfsEntry, excludeValidation);

  const [openClose, setOpenClose] = useState(false);
  const [error] = useState("");

  const siteId = 3583971;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);

  const handleDelete = () => {
    setOpenClose(true);
  };

  // Define the props for ShowForm
  const showFormProps = {
    showLogo: setObject.showLogo,
    showName: setObject.showName,
    showDescription: setObject.showDescription,
    closeDate: setObject.closeDate,
    handleNextClick: handleNextClick,
    setAddress: setAddress,
    handleChangeEntryM: handleChangeEntryM,
    // Add more props as needed
  };

  // Define the props for ProfileForm
  const profileFormProps = {
    profile: profile,
    handleChangeProfile: handleChangeProfile,
    handleChangeProfileNonValidate: handleChangeProfileNonValidate,
    updateAddress: updateAddress,
    // address: address,
    handleChangeProfileDate: handleChangeProfileDate,
    addressSearch: addressSearch,
    setAddressSearch: setAddressSearch,
    setAddress: setAddress,
    cellphone: cellNumber,
  };

  const entryFormProps = {
    showEntry: showEntry,
    handleChangeEntry: handleChangeEntry,
    // Add more props as needed
  };

  // Define the props for ShowForm
  const mediaUploadFormProps = {
    handleImage: handleImage,
    handleVideo: handleVideo,
    showPic: showPic,
    showPic2: showPic2,
    showPic3: showPic3,
    showVid: showVid,
    // Add more props as needed
  };

  // Define the props for ProfileForm
  const TermsAndConditionsProps = {
    terms: terms,
    handleTermsChange: handleTermsChange,
    showTermsAndConditions: showTermsAndConditions,
    // Add more props as needed
  };

  useEffect(() => {
    setActive(true);
    fetchProfile(cellNumber).then(() => {});
    fetchEntry(show, cellNumber).then(() => {});
    window.scrollTo(0, 0);
    document.body.style.background =
      "linear-gradient(to bottom, #6b026a, #bd0202)";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  useLayoutEffect(() => {
    if (showEntry.signature) {
      sigCanvasRef.current.fromDataURL(showEntry.signature);
    }
  }, [showEntry.signature, sigCanvasRef]);

  const addressRef = useRef(null);

  const handleProfileFormRef = (ref) => {
    addressRef.current = ref;
  };

  // // Extract the necessary information from the ref
  // const { current: storedRefValue } = ref;
  //
  // // Store the ref value in localStorage
  // localStorage.setItem("addressRef", JSON.stringify(storedRefValue));
  //
  // // Retrieve the ref value from localStorage
  // const storedRef = JSON.parse(localStorage.getItem("addressRef"));

  return (
    <MyForm
      isActive={isActive} // Set to true or false based on your condition
      error={error} // Set to an error message or null
      show={show} // Set to true or false based on your condition
      cellNumber={cellNumber} // Pass the cell number
      openClose={openClose} // Set to true or false based on your condition
      handleSubmit={handleSubmit} // Pass the submit handler function
      background={"linear-gradient(#6b026a, #bd0202)"}
      transparent={"transparent"}
      transparencyValue={"0,5"}
    >
      <div>
        <StepComponent
          activeStep={step.activeStep}
          steps={["Show", "Profile", "Entry", "Media", "Terms"]}
        />

        {/* Render content for each step */}
        {step.activeStep === 1 && <ShowForm {...showFormProps} />}
        {step.activeStep === 2 && <ProfileForm {...profileFormProps} />}
        {step.activeStep === 3 && <CustomEntryForm {...entryFormProps} />}
        {step.activeStep === 4 && <MediaUploadForm {...mediaUploadFormProps} />}
        {step.activeStep === 5 && (
          <TermsAndConditionsForm {...TermsAndConditionsProps} />
        )}
      </div>
      {terms.checked === true ? (
        <div>
          <CButton
            style={{ width: "15%", margin: 3 }}
            color="danger"
            variant="outline"
            onClick={handleDelete}
          >
            <CIcon icon={icon.cilTrash} size="xxl" /> Delete
          </CButton>
          <CButton
            style={{ width: "15%", margin: 3 }}
            color="success"
            variant="outline"
            type="submit"
          >
            <CIcon icon={icon.cilSend} size="xxl" /> Submit
          </CButton>
        </div>
      ) : null}
      {terms.checked === false && step.activeStep !== 1 ? (
        <div>
          <CButton
            variant="outline"
            color="danger"
            style={{ width: "15%", margin: 3 }}
            onClick={handlePrevClick}
            disabled={step.activeStep === 1}
          >
            <CIcon icon={icon.cilArrowThickFromRight} size="xxl" /> Back
          </CButton>
          <CButton
            color="primary"
            variant="outline"
            style={{ width: "15%", margin: 3 }}
            onClick={handleNextClick}
            disabled={step.activeStep === 5}
          >
            Next <CIcon icon={icon.cilArrowThickFromLeft} size="xxl" />
          </CButton>
        </div>
      ) : null}
    </MyForm>
  );
}
