import React from "react";
import {
  SelectComponent,
  MultilineTextBox,
  InputComponent,
  MultipleSelectComponent,
} from "../../hooks/useEWizFormComponents";
import { NumberInputComponent } from "../../hooks/useEWizFormComponents";
import { Container, Grid } from "@mui/material";

const CustomEntryForm = ({
  showEntry,
  handleChangeEntry,
  handleChangeEntryM,
}) => {
  return (
    <Container>
      <h1>Entry</h1>
      <h6 style={{ color: "grey" }}>
        *Please put a dash(-) for questions that dont apply to you.
      </h6>
      {/* Personal Information */}
      <div>
        <h2>Personal Information</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {/* Star Sign */}
            <SelectComponent
              label="Star Sign"
              value={showEntry.starSign.value}
              onChange={handleChangeEntry}
              name="starSign"
              error={showEntry.starSign.error}
              options={[
                { label: "Aries", value: "Aries" },
                { label: "Taurus", value: "Taurus" },
                { label: "Gemini", value: "Gemini" },
                { label: "Cancer", value: "Cancer" },
                { label: "Leo", value: "Leo" },
                { label: "Virgo", value: "Virgo" },
                { label: "Libra", value: "Libra" },
                { label: "Scorpio", value: "Scorpio" },
                { label: "Sagittarius", value: "Sagittarius" },
                { label: "Capricorn", value: "Capricorn" },
                { label: "Aquarius", value: "Aquarius" },
                { label: "Pisces", value: "Pisces" },
                // Add more star signs options
              ]}
              placeholder="What is your star sign?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Ethnicity */}
            <SelectComponent
              label="Ethnicity"
              value={showEntry.ethnicity.value}
              onChange={handleChangeEntry}
              name="ethnicity"
              error={showEntry.ethnicity.error}
              placeholder="What is your ethnicity?"
              options={[
                // Add more ethnicity options
                { label: "Zulu", value: "Zulu" },
                { label: "Xhosa", value: "Xhosa" },
                { label: "Afrikaner", value: "Afrikaner" },
                { label: "English", value: "English" },
                { label: "Sotho", value: "Sotho" },
                { label: "Tswana", value: "Tswana" },
                { label: "Venda", value: "Venda" },
                { label: "Ndebele", value: "Ndebele" },
                { label: "Pedi", value: "Pedi" },
                { label: "Swazi", value: "Swazi" },
                { label: "Tsonga", value: "Tsonga" },
                { label: "Indian", value: "Indian" },
                { label: "Coloured", value: "Coloured" },
                { label: "Nguni", value: "Nguni" },
                { label: "Sotho-Tswana", value: "Sotho-Tswana" },
                { label: "Tshivenda", value: "Tshivenda" },
                { label: "Tsonga-Shangaan", value: "Tsonga-Shangaan" },
                { label: "San", value: "San" },
                { label: "Khoi", value: "Khoi" },
                { label: "Nama", value: "Nama" },
                { label: "Griqua", value: "Griqua" },
                { label: "Chinese", value: "Chinese" },
                { label: "Lebanese", value: "Lebanese" },
                { label: "Greek", value: "Greek" },
                { label: "Portuguese", value: "Portuguese" },
                { label: "Italian", value: "Italian" },
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Education Level */}
            <SelectComponent
              label="Education Level"
              value={showEntry.educationLevel.value}
              onChange={handleChangeEntry}
              name="educationLevel"
              error={showEntry.educationLevel.error}
              options={[
                { label: "None", value: "None" },
                { label: "High School", value: "High School" },
                {
                  label: "Technical Certificate",
                  value: "Technical Certificate",
                },
                {
                  label: "Occupational Certificate",
                  value: "Occupational Certificate",
                },
                { label: "Professional", value: "Professional" },
                {
                  label: "Associate Degree",
                  value: "Associate Degree",
                },
                {
                  label: "Bachelor's Degree",
                  value: "Bachelor's Degree",
                },
                { label: "Master's Degree", value: "Master's Degree" },
                { label: "Doctorate", value: "Doctorate" },
              ]}
              placeholder="What is your education level?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Profession */}
            <InputComponent
              label="Profession"
              value={showEntry.profession.value}
              onChange={handleChangeEntry}
              name="profession"
              type="text"
              error={showEntry.profession.error}
              placeholder="What is your profession?"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {/* Medical Status */}
            <MultilineTextBox
              label="Medical Status"
              value={showEntry.medicalStatus.value}
              name="medicalStatus"
              onChange={handleChangeEntry}
              error={showEntry.medicalStatus.error}
              rows={4}
              placeholder="What is your medical status?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Home Language */}
            <SelectComponent
              label="Home Language"
              value={showEntry.homeLanguage.value}
              onChange={handleChangeEntry}
              name="homeLanguage"
              error={showEntry.homeLanguage.error}
              options={[
                { label: "Afrikaans", value: "Afrikaans" },
                { label: "English", value: "English" },
                { label: "isiNdebele", value: "isiNdebele" },
                { label: "isiXhosa", value: "isiXhosa" },
                { label: "isiZulu", value: "isiZulu" },
                {
                  label: "Sesotho sa Leboa",
                  value: "Sesotho sa Leboa",
                },
                { label: "Setswana", value: "Setswana" },
                { label: "Sesotho", value: "Sesotho" },
                { label: "siSwati", value: "siSwati" },
                { label: "Tshivenda", value: "Tshivenda" },
                { label: "Xitsonga", value: "Xitsonga" },
                { label: "Nyanja", value: "Nyanja" },
                { label: "Pedi", value: "Pedi" },
                { label: "Siswati", value: "Siswati" },
                { label: "Tshwana", value: "Tshwana" },
                { label: "Sign Language", value: "Sign Language" },
                { label: "French", value: "French" },
                { label: "Portuguese", value: "Portuguese" },
                { label: "German", value: "German" },
                { label: "Dutch", value: "Dutch" },
                { label: "Chinese", value: "Chinese" },
                { label: "Japanese", value: "Japanese" },
                { label: "Korean", value: "Korean" },
                { label: "Italian", value: "Italian" },
                { label: "Greek", value: "Greek" },
                { label: "Spanish", value: "Spanish" },
                { label: "Arabic", value: "Arabic" },
                { label: "Hindi", value: "Hindi" },
                { label: "Urdu", value: "Urdu" },
                { label: "Bengali", value: "Bengali" },
                { label: "Gujarati", value: "Gujarati" },
                { label: "Tamil", value: "Tamil" },
                { label: "Telugu", value: "Telugu" },
                { label: "Marathi", value: "Marathi" },
                { label: "Punjabi", value: "Punjabi" },
                { label: "Russian", value: "Russian" },
                { label: "Swahili", value: "Swahili" },
                { label: "Tagalog", value: "Tagalog" },
                { label: "Vietnamese", value: "Vietnamese" },
                { label: "Other", value: "Other" },
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Single Because */}
            <MultilineTextBox
              label="Single Because"
              value={showEntry.singleBecause.value}
              onChange={handleChangeEntry}
              name={"singleBecause"}
              error={showEntry.singleBecause.error}
              rows={4}
              placeholder="Why are you single?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Spiritual/Religious Background */}
            <SelectComponent
              label="Spiritual/Religious Background"
              value={showEntry.spiritualReligiousBackground.value}
              onChange={handleChangeEntry}
              name="spiritualReligiousBackground"
              error={showEntry.spiritualReligiousBackground.error}
              options={[
                { label: "Christianity", value: "Christianity" },
                { label: "Islam", value: "Islam" },
                { label: "Hinduism", value: "Hinduism" },
                { label: "Buddhism", value: "Buddhism" },
                { label: "Judaism", value: "Judaism" },
                {
                  label: "African Traditional Religion",
                  value: "African Traditional Religion",
                },
                { label: "Bahá'í Faith", value: "Bahá'í Faith" },
                { label: "Sikhism", value: "Sikhism" },
                { label: "Rastafari", value: "Rastafari" },
                { label: "Jainism", value: "Jainism" },
                { label: "Zoroastrianism", value: "Zoroastrianism" },
                { label: "Atheism", value: "Atheism" },
                { label: "Agnosticism", value: "Agnosticism" },
                { label: "Other", value: "Other" },
              ]}
              placeholder="What is your religious background?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* About */}
            <MultilineTextBox
              label="About"
              value={showEntry.about.value}
              onChange={handleChangeEntry}
              name={"about"}
              error={showEntry.about.error}
              rows={4}
              placeholder="Tell us about yourself?"
            />
          </Grid>
        </Grid>
      </div>

      {/* Physical Appearance */}
      <div>
        <h2>Physical Appearance</h2>
        <Grid container spacing={2}>
          {/* Hair Colour */}

          <Grid item xs={12} sm={6}>
            <SelectComponent
              label="Hair Colour"
              value={showEntry.hairColour.value}
              onChange={handleChangeEntry}
              name="hairColour"
              error={showEntry.hairColour.error}
              options={[
                { label: "Black", value: "Black" },
                { label: "Brown", value: "Brown" },
                { label: "Blonde", value: "Blonde" },
                { label: "Red", value: "Red" },
                { label: "Brunette", value: "Brunette" },
                { label: "Gray", value: "Gray" },
                { label: "White", value: "White" },
                { label: "Bald", value: "Bald" },
                { label: "Other", value: "Other" },
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Eye Colour */}
            <SelectComponent
              label="Eye Colour"
              value={showEntry.eyeColour.value}
              onChange={handleChangeEntry}
              name="eyeColour"
              error={showEntry.eyeColour.error}
              options={[
                { label: "Blue", value: "Blue" },
                { label: "Brown", value: "Brown" },
                { label: "Green", value: "Green" },
                { label: "Hazel", value: "Hazel" },
                { label: "Gray", value: "Gray" },
                { label: "Amber", value: "Amber" },
                { label: "Black", value: "Black" },
                { label: "Other", value: "Other" },
              ]}
              placeholder="What colour are your eyes?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Weight in KG */}
            <NumberInputComponent
              label="Weight (KG)"
              value={showEntry.weightKG.value}
              onChange={handleChangeEntry}
              name="weightKG"
              min={10}
              max={500}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Height */}
            <NumberInputComponent
              label="Height (CM)"
              value={showEntry.height.value}
              onChange={handleChangeEntry}
              name="height"
              error={showEntry.height.error}
              min={60}
              max={300}
              placeholder="How tall are you?"
            />
          </Grid>
        </Grid>
      </div>

      {/* Lifestyle */}
      <div>
        <h2>Lifestyle</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {/* Smoker */}
            <SelectComponent
              label="Smoker"
              value={showEntry.smoker.value}
              onChange={handleChangeEntry}
              name="smoker"
              error={showEntry.smoker.error}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="Are you a smoker?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Feelings About Smokers */}
            <MultilineTextBox
              label="Feelings About Smokers"
              value={showEntry.feelingsAboutSmokers.value}
              onChange={handleChangeEntry}
              name={"feelingsAboutSmokers"}
              error={showEntry.feelingsAboutSmokers.error}
              rows={4}
              placeholder="How do you feel about smoking?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Date Smoker or Vaper */}
            <SelectComponent
              label="Date Smoker/Vaper"
              value={showEntry.dateSmokerOrVaper.value}
              onChange={handleChangeEntry}
              name="dateSmokerOrVaper"
              error={showEntry.dateSmokerOrVaper.error}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="Would you date a smoker/ vaper?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Accept Children/Pets/Dependents */}
            <SelectComponent
              label="Accept Children/Pets/Dependents"
              value={showEntry.acceptChildrenPetsDependents.value}
              onChange={handleChangeEntry}
              name="acceptChildrenPetsDependents"
              error={showEntry.acceptChildrenPetsDependents.error}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="Are you okay with Children/Pets/Dependents?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Have Children */}
            <SelectComponent
              label="Have Children"
              value={showEntry.haveChildren.value}
              onChange={handleChangeEntry}
              name="haveChildren"
              error={showEntry.haveChildren.error}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="Do you have kids?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Drinker */}
            <SelectComponent
              label="Drinker"
              value={showEntry.drinker.value}
              onChange={handleChangeEntry}
              name="drinker"
              error={showEntry.drinker.error}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="Do you drink?"
            />
          </Grid>
        </Grid>
      </div>

      {/* Interests */}
      <div>
        <h2>Interests</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {/* Indoors Type */}
            <SelectComponent
              label="Indoors Type"
              value={showEntry.outdoorsIndoorsType.value}
              onChange={handleChangeEntry}
              name="outdoorsIndoorsType"
              error={showEntry.outdoorsIndoorsType.error}
              options={[
                { label: "Indoors", value: "Indoors" },
                { label: "Outdoors", value: "Outdoors" },
              ]}
              placeholder="Are you an indoor or outdoor type of person?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Level of Fitness */}
            <SelectComponent
              label="Level of Fitness"
              value={showEntry.levelOfFitness.value}
              onChange={handleChangeEntry}
              name="levelOfFitness"
              error={showEntry.levelOfFitness.error}
              options={[
                { label: "Poor", value: "Poor" },
                { label: "Below Average", value: "Below Average" },
                { label: "Average", value: "Average" },
                { label: "Above Average", value: "Above Average" },
                { label: "Super Fit", value: "Super Fit" },
              ]}
              placeholder="What is your level of fitness?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Go to Gym */}
            <SelectComponent
              label="Go to Gym"
              value={showEntry.goToGym.value}
              onChange={handleChangeEntry}
              name="goToGym"
              error={showEntry.goToGym.error}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="Do you go to gym?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Member of Clubs */}
            <MultipleSelectComponent
              label="What are you into?"
              value={showEntry.memberOfClubs.value}
              onChange={handleChangeEntry}
              name="memberOfClubs"
              error={showEntry.memberOfClubs.error}
              placeholder="What are you into?"
              tip="Select as many as you want, then click on the form when done"
              options={[
                {
                  label: "Going to the movies",
                  value: "Going to the movies",
                },
                {
                  label: "Attending live concerts",
                  value: "Attending live concerts",
                },
                {
                  label: "Joining sports clubs",
                  value: "Joining sports clubs",
                },
                {
                  label: "Going to art exhibitions",
                  value: "Going to art exhibitions",
                },
                {
                  label: "Attending theater performances",
                  value: "Attending theater performances",
                },
                {
                  label: "Participating in outdoor activities",
                  value: "Participating in outdoor activities",
                },
                {
                  label: "Visiting museums",
                  value: "Visiting museums",
                },
                {
                  label: "Exploring new restaurants",
                  value: "Exploring new restaurants",
                },
                {
                  label: "Going to comedy shows",
                  value: "Going to comedy shows",
                },
                {
                  label: "Attending social networking events",
                  value: "Attending social networking events",
                },
                {
                  label: "Joining hobby or interest groups",
                  value: "Joining hobby or interest groups",
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Extrovert */}
            <SelectComponent
              label="Extrovert"
              value={showEntry.extrovertIntrovert.value}
              onChange={handleChangeEntry}
              name="extrovertIntrovert"
              error={showEntry.extrovertIntrovert.error}
              placeholder="Are you an introvert or an extrovert?"
              options={[
                { label: "Extrovert", value: "Extrovert" },
                { label: "Introvert", value: "Introvert" },
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Hobbies/Interests */}
            <MultilineTextBox
              label="Hobbies/Interests"
              value={showEntry.hobbiesInterests.value}
              onChange={handleChangeEntry}
              name={"hobbiesInterests"}
              error={showEntry.hobbiesInterests.error}
              rows={4}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Books Read */}
            <MultilineTextBox
              label="Books Read"
              value={showEntry.booksRead.value}
              onChange={handleChangeEntry}
              name={"booksRead"}
              error={showEntry.booksRead.error}
              rows={4}
              placeholder="What are you favourite books if any, else put?"
            />
          </Grid>
        </Grid>
      </div>

      {/* Entertainment */}
      <div>
        <h2>Entertainment</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {/* Last Good Book */}
            <InputComponent
              label="Last Good Book"
              value={showEntry.lastGoodBook.value}
              onChange={handleChangeEntry}
              error={showEntry.lastGoodBook.error}
              name="lastGoodBook"
              type="text"
              placeholder="What is the most recent book you read if any?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Last Movie Watched */}
            <InputComponent
              label="Last Movie Watched"
              value={showEntry.lastMovieWatched.value}
              onChange={handleChangeEntry}
              name="lastMovieWatched"
              type="text"
              error={showEntry.lastMovieWatched.error}
              placeholder="What was the last movie you watched?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Type of Movies Enjoy */}
            <MultilineTextBox
              label="Type of Movies Enjoy"
              value={showEntry.typeOfMoviesEnjoy.value}
              onChange={handleChangeEntry}
              name={"typeOfMoviesEnjoy"}
              rows={4}
              error={showEntry.typeOfMoviesEnjoy.error}
              placeholder="What type of movies do you like?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Interest in World Affairs */}
            <SelectComponent
              label="Interest in World Affairs"
              value={showEntry.interestInWorldAffairs.value}
              onChange={handleChangeEntry}
              name="interestInWorldAffairs"
              error={showEntry.interestInWorldAffairs.error}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="Do you have any interest in world affairs?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Love Adventure */}
            <SelectComponent
              label="Love Adventure"
              value={showEntry.loveAdventure.value}
              onChange={handleChangeEntry}
              name="loveAdventure"
              error={showEntry.loveAdventure.error}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="Do you love adventure?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Enjoy Making New Friends */}
            <SelectComponent
              label="Enjoy Making New Friends"
              value={showEntry.enjoyMakingNewFriends.value}
              onChange={handleChangeEntry}
              name="enjoyMakingNewFriends"
              error={showEntry.enjoyMakingNewFriends.error}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="Do you enjoy making new friends?"
            />
          </Grid>
        </Grid>
      </div>

      {/* Travel */}
      <div>
        <h2>Travel</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {/* Traveled to Great Degree */}
            <SelectComponent
              label="Traveled to Great Degree"
              value={showEntry.traveledToGreatDegree.value}
              onChange={handleChangeEntry}
              name="traveledToGreatDegree"
              error={showEntry.traveledToGreatDegree.error}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="Are you well travelled?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Intend to Travel */}
            <SelectComponent
              label="Intend to Travel"
              value={showEntry.intendToTravel.value}
              onChange={handleChangeEntry}
              name="intendToTravel"
              error={showEntry.intendToTravel.error}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="Do you intend to travel?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Preferred Age Group */}
            <SelectComponent
              label="Preferred Age Group"
              value={showEntry.preferredAgeGroup.value}
              onChange={handleChangeEntry}
              name="preferredAgeGroup"
              error={showEntry.preferredAgeGroup.error}
              options={[
                { label: "18-25", value: "18-25" },
                { label: "25-35", value: "25-35" },
                { label: "35-45", value: "35-45" },
                { label: "45-55", value: "45-55" },
                { label: "55+", value: "55+" },
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Open to All Cultural Groups */}
            <SelectComponent
              label="Open to All Cultural Groups"
              value={showEntry.openToAllCulturalGroups.value}
              onChange={handleChangeEntry}
              name="openToAllCulturalGroups"
              error={showEntry.openToAllCulturalGroups.error}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="Are you open to other cultures?"
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default CustomEntryForm;
