import { sendEntryEmail } from "./useFirestore";
import emailjs from "@emailjs/browser";
import { useAppContext } from "./useAppContext";
import { useNavigate } from "react-router-dom";
import { settings as appSettings } from "./../config";

export const useMailServer = () => {
  const { show, setObject, setActive } = useAppContext();
  const navigate = useNavigate();

  const sendEntryEmail = async (profile, entry, exists, settings) => {
    var rentry = exists === true ? "(Update)" : "";
    var couple = entry.coupleEntry === "true" ? "(couple)" : "";
    var showDetails = { ...entry };
    var status = "";

    if (entry.status !== undefined) {
      var status = entry.status;
    }

    if (profile.SexualOrientation === undefined) {
      profile.SexualOrientation = {
        value: "",
        error: false,
        errorMessage: "Required field",
      };
    }
    if (profile.SexualPreference === undefined) {
      profile.SexualPreference = {
        value: "",
        error: false,
        errorMessage: "Required field",
      };
    }
    if (profile.IDNo === undefined) {
      profile.IDNo = {
        value: "",
        error: false,
        errorMessage: "Required field",
      };
    }
    if (profile.DOB === undefined) {
      profile.DOB = { value: "", error: false, errorMessage: "Required field" };
    }

    var templateParams = {
      from_name:
        profile.name.value + " " + profile.surname.value + " " + couple,

      to_name: profile.name.value,
      reply_to: profile.email.value,
      message: "",
      cc: settings.showEmail,
      update: rentry,
      status: status,
      // Profile
      // name: profile.name.value,
      // surname: profile.surname.value,
      // cellphone: profile.cellphone,
      // email: profile.email.value,
      // gender: profile.gender.value,
      // age: profile.age.value,
      // fullAddress: profile.fullAddress.value,
      // SexualOrientation: profile.SexualOrientation.value,
      // SexualPreference: profile.SexualPreference.value,
      // IDNo: profile.IDNo.value,
      // DOB: profile.DOB.value,
    };

    templateParams = { ...templateParams, ...profile, ...showDetails };

    emailjs
      .send(
        settings.serviceID,
        settings.templateID,
        templateParams,
        settings.publicKey
      )
      .then(
        (result) => {
          console.log(result.text);
          return result;
        },
        (error) => {
          console.log(error.text);
          return error;
        }
      );
  };

  const processEmail = (profile, showEntry, exists) => {
    try {
      setActive(true);
      sendEntryEmail(profile, showEntry, exists, setObject).then((result) => {
        console.log(result);
        setActive(false);
        navigate("/thankyou/" + show);
        window.scrollTo(0, 0);
      });
    } catch (error) {
      console.log(error.text);
    }
  };

  const sendProfileEmail = async (profile, entry, exists, settings) => {
    var rentry = exists === true ? "(Update)" : "";
    var couple = entry.coupleEntry === "true" ? "(couple)" : "";
    var showDetails = { ...entry };
    var status = "";

    if (entry.status !== undefined) {
      var status = entry.status;
    }

    if (profile.SexualOrientation === undefined) {
      profile.SexualOrientation = {
        value: "",
        error: false,
        errorMessage: "Required field",
      };
    }
    if (profile.SexualPreference === undefined) {
      profile.SexualPreference = {
        value: "",
        error: false,
        errorMessage: "Required field",
      };
    }
    if (profile.IDNo === undefined) {
      profile.IDNo = {
        value: "",
        error: false,
        errorMessage: "Required field",
      };
    }
    if (profile.DOB === undefined) {
      profile.DOB = { value: "", error: false, errorMessage: "Required field" };
    }

    var templateParams = {
      from_name:
        profile.name.value + " " + profile.surname.value + " " + couple,

      to_name: profile.name.value,
      reply_to: profile.email.value,
      message: "",
      cc: settings.showEmail,
      update: rentry,
      status: status,
      // Profile
      // name: profile.name.value,
      // surname: profile.surname.value,
      // cellphone: profile.cellphone,
      // email: profile.email.value,
      // gender: profile.gender.value,
      // age: profile.age.value,
      // fullAddress: profile.fullAddress.value,
      // SexualOrientation: profile.SexualOrientation.value,
      // SexualPreference: profile.SexualPreference.value,
      // IDNo: profile.IDNo.value,
      // DOB: profile.DOB.value,
    };

    templateParams = { ...templateParams, ...profile, ...showDetails };

    emailjs
      .send(
        settings.serviceID,
        appSettings.opw,
        templateParams,
        settings.publicKey
      )
      .then(
        (result) => {
          console.log(result.text);
          return result;
        },
        (error) => {
          console.log(error.text);
          return error;
        }
      );
  };

  const processProfileEmail = (profile, showEntry, exists) => {
    try {
      setActive(true);
      sendProfileEmail(profile, showEntry, exists, setObject).then((result) => {
        console.log(result);
        setActive(false);
        // navigate("/thankyou/" + show);
      });
    } catch (error) {
      console.log(error.text);
    }
  };

  return { processEmail, processProfileEmail };
};
