export let entrantProfile = {
  uid: "",
  //Profile
  name: "",
  surname: { value: "", error: false, errorMessage: "Required field" },
  cellphone: "",
  email: { value: "", error: false, errorMessage: "Required field" },
  twitter: { value: "", error: false, errorMessage: "Required field" },
  facebook: { value: "", error: false, errorMessage: "Required field" },
  instagram: { value: "", error: false, errorMessage: "Required field" },
  gender: { value: "", error: false, errorMessage: "Required field" },
  age: { value: 18, error: false, errorMessage: "Required field" },
  city: "",
  province: "",
  fullAddress: "",
  street: "",
  streetNumber: "",
  DOB: {
    value: "",
    error: false,
    errorMessage: "You must enter a date of birth",
  },
};

export let dmfEntry = {
  uid: "",
  // ideal: "",
  // describe: "",
  // rate: "",
  // dealbreakers: "",
  signature: "",

  date: {
    label: "Your Date Preference?",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "date",
  },
  pic: {
    label: "Picture",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "file",
  },
  pic2: {
    label: "Picture 2",
    value: "No Picture",
    error: false,
    errorMessage: "Required field",
    fieldType: "file",
  },
  pic3: {
    label: "Picture 3",
    value: "No Picture",
    error: false,
    errorMessage: "Required field",
    fieldType: "file",
  },
  video: {
    label: "Video",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "file",
  },
  height: {
    label: "Height",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  criminalrecord: {
    label: "Criminal Record?",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  describeYourself: {
    label: "Describe Yourself",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "textarea",
  },
  schooling: {
    label: "Schooling/Education",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  dealbreakers: {
    label: "What is your deal breaker?",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "textarea",
  },
  idealphysic: {
    label: "Ideal partner's physic?",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  employed: {
    label: "Do they have to be employed?",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  religion: {
    label: "What religion must they follow?",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "text",
  },
  car: {
    label: "Should they have a car?",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  kids: {
    label: "Do you have kids?",
    value: "",
    error: false,
    errorMessage: "Required field",
    fieldType: "radio",
  },
  kidsnumber: {
    label: "How Many Kids?",
    value: 0,
    error: false,
    errorMessage: "Required field",
    fieldType: "number",
  },

  status: "New",
};

export let excludeValidation = ["pic", "video", "twitter", "height"];

export let listColumns = [
  "name",
  "surname",
  "gender",
  "date",
  "kids",
  "religion",
  "car",
  "employed",
];

// export let entrantProfile = {
//   uid: "",
//   //Profile
//   name: "",
//   surname: "",
//   cellphone: "",
//   email: "",
//   twitter: "",
//   facebook: "",
//   instagram: "",
//   gender: "",
//   age: 0,
//   city: "",
//   province: "",
//   fullAddress: "",
// };

// export let dmfEntry = {
//   uid: "",
//   // ideal: "",
//   // describe: "",
//   // rate: "",
//   // dealbreakers: "",
//   date: "",
//
//   pic: "",
//   video: "",
//   height: 0,
//   occupation: "",
//   company: "",
//   fulladdress: "",
//   shootaddress: "",
//   shootprovince: "",
//   shootcity: "",
//   criminalrecord: false,
//   crime: "",
//   lifestory: "",
//   singlewhy: "",
//   lastrelationshipdate: "",
//   wishtosharewhat: "",
//   partner: "",
//   schooling: "",
//   alive: "",
//   difficultexperiance: "",
//   peopleyouattract: "",
//   commonreasonsbreakup: "",
//   dealbreaker: "",
//   idealphysic: "",
//   employed: false,
//   religion: "",
//   car: false,
//   partnerkids: false,
//   partnerkidsnumber: 0,
//   kids: false,
//   kidsnumber: 0,
//   breakup: "",
//   relationshipstatusx: "",
// };

//old fields
// date: { value: "", error: false },
// pic: { value: "", error: false, errorMessage: "Required field" },
// pic2: { value: "No Picture", error: false, errorMessage: "Required field" },
// pic3: { value: "No Picture", error: false, errorMessage: "Required field" },
// video: { value: "", error: false, errorMessage: "Required field" },
// height: { value: "", error: false, errorMessage: "Required field" },
// criminalrecord: { value: "", error: false, errorMessage: "Required field" },
// describeYourself: { value: "", error: false, errorMessage: "Required field" },
// schooling: { value: "", error: false, errorMessage: "Required field" },
// dealbreakers: { value: "", error: false, errorMessage: "Required field" },
// idealphysic: { value: "", error: false, errorMessage: "Required field" },
// employed: { value: "", error: false, errorMessage: "Required field" },
// religion: { value: "", error: false, errorMessage: "Required field" },
// car: { value: "", error: false, errorMessage: "Required field" },
// kids: { value: "", error: false, errorMessage: "Required field" },
// kidsnumber: { value: 0, error: false, errorMessage: "Required field" },
