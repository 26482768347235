import React, { forwardRef, useEffect, useRef } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Container,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import DeleteDialog from "../components/alerts";
import LoadingOverlay from "react-loading-overlay-ts";
import { Alert } from "react-bootstrap";
import { usePlacesWidget } from "react-google-autocomplete";
import { DateField as DF } from "@mui/x-date-pickers/DateField/DateField";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Label } from "@mui/icons-material";
import dayjs from "dayjs";

const SelectField = ({ label, name, error, value, onChange, options }) => (
  <FormControl
    variant="outlined"
    fullWidth
    sx={{
      m: { xs: 1 },
      width: { xs: "92%" },
    }}
    error={error}
  >
    <InputLabel>{label}</InputLabel>
    <Select value={value} onChange={onChange} name={name} label={label}>
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

function ImagePreviewComponent({ id, src }) {
  return (
    <div className="image-preview-component">
      <img className="image-preview" id={id} src={src} alt="" />
    </div>
  );
}

function VideoPreviewComponent({ src }) {
  return (
    <div className="video-preview-component">
      <video className="video-preview" src={src} controls />
    </div>
  );
}

function MediaUploadComponent({ label, onChange, id, accept }) {
  return (
    <div className="media-upload-component">
      <label className="media-upload-label">{label}</label>
      <input
        className="media-upload-field"
        type="file"
        id={id}
        onChange={onChange}
        accept={accept}
      />
    </div>
  );
}

const NumberField = ({
  id,
  name,
  label,
  placeholder,
  value,
  onChange,
  error,
  InputProps,
}) => (
  <TextField
    id={id}
    type="number"
    name={name}
    label={label}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    error={error}
    variant="outlined"
    fullWidth
    sx={{
      m: 2,
      width: { xs: "92%", sm: "45%" },
    }}
    {...InputProps}
  />
);

function DatePicker({ label, value, onChange, error }) {
  return (
    <TextField
      label={label}
      type="date"
      value={value}
      onChange={onChange}
      variant="outlined"
      fullWidth
      error={error}
      InputLabelProps={{
        shrink: true,
      }}
      sx={{
        m: 2,
        width: { xs: "92%", sm: "45%" },
      }}
    />
  );
}

function TextAreaComponent({ label, value, onChange, name, error }) {
  return (
    <TextField
      multiline
      rows={4}
      label={label}
      value={value}
      onChange={onChange}
      name={name}
      variant="outlined"
      fullWidth
      error={error}
      sx={{
        m: 2,
        width: { xs: "92%", sm: "45%" },
      }}
    />
  );
}

function eForm({
  isActive,
  error,
  show,
  cellNumber,
  openClose,
  handleSubmit,
  formRef,
  children,
}) {
  return (
    <div className="entry-form">
      {error && <div className="error-alert">{error}</div>}
      <DeleteDialog show={show} cellNumber={cellNumber} openClose={openClose} />
      <fieldset disabled={isActive}>
        <form
          ref={formRef}
          id="enterDMF"
          name="theform"
          onSubmit={handleSubmit}
          className="entry-form-content"
        >
          <Box>{children}</Box>
        </form>
      </fieldset>
    </div>
  );
}

const MultilineTextBox = ({
  rows,
  name,
  label,
  value,
  onChange,
  error,
  InputProps,
  placeholder,
}) => (
  <TextField
    multiline
    rows={rows}
    name={name}
    label={label}
    value={value}
    onChange={onChange}
    error={error}
    variant="outlined"
    fullWidth
    sx={{
      m: { xs: 1 },
      width: { xs: "92%" },
    }}
    {...InputProps}
    placeholder={placeholder}
  />
);

const DateField = ({
  id,
  name,
  label,
  placeholder,
  value,
  onChange,
  onFocus,
  error,
  InputProps,
  tip,
}) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    {/*<TextField*/}
    {/*  label={label}*/}
    {/*  value={value}*/}
    {/*  onChange={onChange}*/}
    {/*  id={id}*/}
    {/*  name={name}*/}
    {/*  error={error}*/}
    {/*  size="small"*/}
    {/*  helperText={null}*/}
    {/*  variant="outlined"*/}
    {/*  fullWidth*/}
    {/*  sx={{*/}
    {/*    m: { xs: 1 },*/}
    {/*    width: { xs: "92%" },*/}
    {/*  }}*/}
    {/*/>*/}

    <MobileDatePicker
      label={label}
      value={value}
      onChange={onChange}
      renderInput={(props) => (
        <TextField
          {...props}
          id={id}
          name={name}
          error={error}
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            m: { xs: 1 },
            width: { xs: "92%" },
          }}
        />
      )}
    />
  </LocalizationProvider>
);

const AddressField = ({
  label,
  value,
  onChange,
  setAddressSearch,
  setAddress,
}) => {
  const changeAdd = (selected) => {
    setAddress({
      streetNumber: selected.address_components[0].long_name,
      street: selected.address_components[1].long_name,
      city: selected.address_components[3].long_name,
      province: selected.address_components[5].long_name,
      fullAddress: selected.formatted_address,
    });
  };

  const { ref } = usePlacesWidget({
    apiKey:
      "AIzaSyCLO072g3rnh3v1dSByrC6aYEPTtUPcyM4&callback=Function.prototype",
    onPlaceSelected: (selected) => {
      console.log(selected);
      setAddressSearch(selected.formatted_address);
      changeAdd(selected);
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "za" },
    },
  });

  return (
    <TextField
      label={label}
      name="fullAddress"
      inputRef={ref}
      value={value}
      onChange={onChange}
      variant="outlined"
      sx={{ m: 2, width: "92%" }}
      helperText={
        value === ""
          ? "Search for your address, select it from the drop down and click any of the address fields below to auto populate."
          : " "
      }
    />
  );
};

function MyForm({
  isActive,
  error,
  show,
  cellNumber,
  openClose,
  handleSubmit,
  children,
}) {
  const formRef = useRef();

  const containerStyle = {
    background: `rgba(255, 255, 255, 0.7)`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text="Busy Processing, Please Wait..."
    >
      <Container>
        {error && <Alert variant="danger">{error}</Alert>}
        <DeleteDialog show={show} cellNumber={cellNumber} open={openClose} />
        <fieldset disabled={false}>
          <br />
          <Box
            style={containerStyle}
            ref={formRef}
            id="enterDMF"
            name="theform"
            onSubmit={handleSubmit}
            disabled
            component="form"
            className="text-center"
          >
            {children}
          </Box>
        </fieldset>
      </Container>
    </LoadingOverlay>
  );
}

function SelectComponent({
  label,
  value,
  onChange,
  name,
  options,
  error,
  placeholder,
  tip,
}) {
  return (
    <Tooltip title={tip}>
      <FormControl
        variant="outlined"
        fullWidth
        sx={{
          m: { xs: 1 },
          width: { xs: "92%" },
        }}
        error={error}
      >
        <InputLabel>{label}</InputLabel>
        <Select value={value} onChange={onChange} name={name} label={label}>
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Tooltip>
  );
}

function MultipleSelectComponent({
  label,
  value,
  onChange,
  name,
  options,
  error,
  placeholder,
  tip,
}) {
  return (
    <Tooltip title={tip} enterTouchDelay={0} leaveTouchDelay={5000}>
      <FormControl
        variant="outlined"
        fullWidth
        sx={{
          m: { xs: 1 },
          width: { xs: "92%" },
        }}
        error={error}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          onChange={onChange}
          name={name}
          label={label}
          multiple
        >
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Tooltip>
  );
}

function InputComponent({
  label,
  value,
  onChange,
  error,
  name,
  type = "text",
  onFocus,
  placeholder,
}) {
  return (
    <TextField
      label={label}
      type={type}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      name={name}
      variant="outlined"
      fullWidth
      error={error}
      sx={{
        m: { xs: 1 },
        width: { xs: "92%" },
      }}
      placeholder={placeholder}
      // sx={{
      //   m: 2,
      //   width: { xs: "auto", sm: "auto" }, // let the container determine the width
      // }}
    />
  );
}

function GenderSelect({ value, onChange, error }) {
  return (
    <FormControl
      component="fieldset"
      // sx={{ m: 2, width: { xs: "92%", sm: "45%" } }}
      sx={{
        m: { xs: 1 },
        width: { xs: "92%" },
      }}
    >
      <InputLabel component="legend">Gender</InputLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={value === "Male"}
              onChange={onChange}
              name="gender"
              value="Male"
              error={error}
            />
          }
          label="Male"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={value === "Female"}
              onChange={onChange}
              name="gender"
              value="Female"
            />
          }
          label="Female"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={value === "Non-Binary"}
              onChange={onChange}
              name="gender"
              value="Non-Binary"
            />
          }
          label="Non-Binary"
        />
      </FormGroup>
    </FormControl>
  );
}

function NumberInputComponent({
  label,
  value,
  onChange,
  name,
  min,
  max,
  error,
  placeholder,
}) {
  return (
    <TextField
      label={label}
      type="number"
      value={value}
      onChange={onChange}
      name={name}
      variant="outlined"
      error={error}
      helperText={value === "" ? "Enter only numbers, not text." : " "}
      fullWidth
      InputProps={{
        inputProps: { min, max },
      }}
      placeholder={placeholder}
      sx={{
        m: { xs: 1 },
        width: { xs: "92%" },
      }}
    />
  );
}

function StepComponent({ activeStep, steps }) {
  return (
    <div hidden className="step-container">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`step ${index + 1 === activeStep ? "active" : "inactive"}`}
        >
          {step}
        </div>
      ))}
    </div>
  );
}

export {
  MultilineTextBox,
  NumberField,
  DateField,
  AddressField,
  SelectField,
  eForm,
  ImagePreviewComponent,
  VideoPreviewComponent,
  MediaUploadComponent,
  SelectComponent,
  TextAreaComponent,
  InputComponent,
  GenderSelect,
  DatePicker,
  NumberInputComponent,
  MyForm,
  StepComponent,
  MultipleSelectComponent,
};
