import React from "react";
import { CRow, CCol } from "@coreui/react"; // Replace 'your-ui-library' with the actual library you're using
import { Form, Col } from "react-bootstrap";
function MediaUploadForm({
  handleImage,
  handleVideo,
  showPic,
  showPic2,
  showPic3,
  showVid,
}) {
  return (
    <div>
      <h1>Media</h1>
      {/* Media Uploads */}
      <CRow>
        <CCol>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload a pic of yourself</Form.Label>
            <Form.Control
              type="file"
              id="pic"
              onChange={handleImage}
              accept="image/*"
            />
          </Form.Group>
        </CCol>
        <CCol>
          <img
            style={{ padding: "20px" }}
            id={"showPic"}
            src={showPic}
            className="img-fluid rounded mx-auto d-block"
            alt=""
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload a 2nd pic of yourself</Form.Label>
            <Form.Control
              type="file"
              id="pic2"
              onChange={handleImage}
              accept="image/*"
            />
          </Form.Group>
        </CCol>
        <CCol>
          <img
            style={{ padding: "20px" }}
            id={"showPic2"}
            src={showPic2}
            className="img-fluid rounded mx-auto d-block"
            alt=""
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload a 3rd pic of yourself</Form.Label>
            <Form.Control
              type="file"
              id="pic3"
              onChange={handleImage}
              accept="image/*"
            />
          </Form.Group>
        </CCol>
        <CCol>
          <img
            style={{ padding: "20px" }}
            id={"showPic3"}
            src={showPic3}
            className="img-fluid rounded mx-auto d-block"
            alt=""
          />
        </CCol>
      </CRow>

      <CRow>
        <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload a 30 sec video introducing yourself</Form.Label>
            <Form.Control type="file" onChange={handleVideo} accept="video/*" />
          </Form.Group>
        </Col>
        <Col>
          <video
            style={{ padding: "20px" }}
            src={showVid}
            id={"vidShow"}
            className="img-fluid rounded mx-auto d-block"
            controls
          />
        </Col>
      </CRow>
    </div>
  );
}

export default MediaUploadForm;
