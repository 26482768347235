import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Box, Container, Grid, TextField, Typography } from "@mui/material";
import {
  InputAdornment,
  Twitter,
  Instagram,
  Facebook,
} from "@mui/icons-material";

import {
  DateField,
  GenderSelect,
  AddressField,
  InputComponent,
  SelectComponent,
} from "../../hooks/useEWizFormComponents";

const ProfileForm = ({
  profile,
  handleChangeProfile,
  handleChangeProfileNonValidate,
  updateAddress,
  addressSearch,
  setAddressSearch,
  setAddress,
  cellphone,
  handleChangeProfileDate,
}) => {
  return (
    <Container>
      <Typography variant="h1">Personal Profile</Typography>
      <Typography variant="h6" style={{ color: "grey" }}>
        *Struggling to see? rotate phone
      </Typography>

      {/* Names */}
      <Typography variant="h3">Names</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InputComponent
            label="Name"
            value={profile.name.value}
            error={profile.name.error}
            onChange={handleChangeProfile}
            name="name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputComponent
            label="Surname"
            value={profile.surname.value}
            error={profile.surname.error}
            onChange={handleChangeProfile}
            name="surname"
          />
        </Grid>
      </Grid>

      {/* Contacts */}
      <Typography variant="h3">Contacts</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InputComponent
            label="Cellphone"
            value={cellphone}
            onChange={handleChangeProfile}
            readOnly
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputComponent
            label="E-Mail"
            value={profile.email.value}
            error={profile.email.error}
            onChange={handleChangeProfile}
            name="email"
          />
        </Grid>
      </Grid>

      {/* Age and gender */}
      <Typography variant="h3">Age and Gender</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DateField
            label="Date Of Birth"
            value={profile.DOB.value}
            onChange={(newValue) => handleChangeProfileDate("DOB", newValue)}
            //onChange={handleChangeProfile}
            error={profile.DOB.error}
            name="DOB"
            tip="Select the year, month, and day separately"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectComponent
            label="Gender"
            value={profile.gender.value}
            onChange={handleChangeProfile}
            name="gender"
            options={[
              { label: "Male", value: "Male" },
              { label: "Female", value: "Female" },
              { label: "Other", value: "Other" },
            ]}
          />
        </Grid>
      </Grid>

      {/* Social Media */}
      <Typography variant="h3">Social Media</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <InputComponent
            label="Twitter"
            value={profile.twitter.value}
            error={profile.twitter.error}
            onChange={handleChangeProfile}
            name="twitter"
            startAdornment={<Twitter />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputComponent
            label="Instagram"
            value={profile.instagram.value}
            error={profile.instagram.error}
            onChange={handleChangeProfile}
            name="instagram"
            startAdornment={<Instagram />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputComponent
            label="Facebook"
            value={profile.facebook.value}
            error={profile.facebook.error}
            onChange={handleChangeProfile}
            name="facebook"
            startAdornment={<Facebook />}
          />
        </Grid>
      </Grid>

      {/* Address */}
      <Typography variant="h3">Address</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AddressField
            label="Search For Address"
            value={addressSearch}
            onChange={(e) => {
              setAddressSearch(e.target.value);
            }}
            setAddressSearch={setAddressSearch}
            setAddress={setAddress}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputComponent
            label="Street Number"
            value={profile.streetNumber}
            onChange={handleChangeProfileNonValidate}
            onFocus={() => {
              updateAddress();
            }}
            name="streetNumber"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputComponent
            label="Street"
            value={profile.street}
            onChange={handleChangeProfileNonValidate}
            onFocus={() => {
              updateAddress();
            }}
            name="street"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputComponent
            label="City"
            value={profile.city}
            onChange={handleChangeProfileNonValidate}
            onFocus={() => {
              updateAddress();
            }}
            name="city"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputComponent
            label="Province"
            value={profile.province}
            onChange={handleChangeProfileNonValidate}
            onFocus={() => {
              updateAddress();
            }}
            name="province"
          />
        </Grid>
      </Grid>
    </Container>

    // <Box>
    //   <Typography variant="h1">Personal Profile</Typography>
    //   <Typography variant="h6" style={{ color: "grey" }}>
    //     *Struggling to see? rotate phone
    //   </Typography>
    //
    //   {/* Names */}
    //   <Typography variant="h3">Names</Typography>
    //   <div>
    //     <InputComponent
    //       label="Name"
    //       value={profile.name.value}
    //       error={profile.name.error}
    //       onChange={handleChangeProfile}
    //       name="name"
    //     />
    //
    //     <InputComponent
    //       label="Surname"
    //       value={profile.surname.value}
    //       error={profile.surname.error}
    //       onChange={handleChangeProfile}
    //       name="surname"
    //     />
    //   </div>
    //
    //   {/* Contacts */}
    //   <Typography variant="h3">Contacts</Typography>
    //   <div>
    //     <InputComponent
    //       label="Cellphone"
    //       value={cellphone}
    //       onChange={handleChangeProfile}
    //       readOnly
    //     />
    //
    //     <InputComponent
    //       label="E-Mail"
    //       value={profile.email.value}
    //       error={profile.email.error}
    //       onChange={handleChangeProfile}
    //       name="email"
    //     />
    //   </div>
    //
    //   {/* Age and gender */}
    //   <Typography variant="h3">Age and Gender</Typography>
    //   <div style={{ margin: "2%" }}>
    //     <DateField
    //       label="Date Of Birth"
    //       value={profile.DOB.value}
    //       onChange={handleChangeProfile}
    //       error={profile.DOB.error}
    //       name="DOB"
    //     />
    //
    //     <SelectComponent
    //       label="Gender"
    //       value={profile.gender.value}
    //       onChange={handleChangeProfile}
    //       name="gender"
    //       options={[
    //         { label: "Male", value: "Male" },
    //         { label: "Female", value: "Female" },
    //         { label: "Other", value: "Other" },
    //       ]}
    //     />
    //   </div>
    //
    //   {/* Social Media */}
    //   <Typography variant="h3">Social Media</Typography>
    //   <div>
    //     <InputComponent
    //       label="Twitter"
    //       value={profile.twitter.value}
    //       error={profile.twitter.error}
    //       onChange={handleChangeProfile}
    //       name="twitter"
    //       startAdornment={<Twitter />}
    //     />
    //
    //     <InputComponent
    //       label="Instagram"
    //       value={profile.instagram.value}
    //       error={profile.instagram.error}
    //       onChange={handleChangeProfile}
    //       name="instagram"
    //       startAdornment={<Instagram />}
    //     />
    //
    //     <InputComponent
    //       label="Facebook"
    //       value={profile.facebook.value}
    //       error={profile.facebook.error}
    //       onChange={handleChangeProfile}
    //       name="facebook"
    //       startAdornment={<Facebook />}
    //     />
    //   </div>
    //
    //   {/* Address */}
    //   <Typography variant="h3">Address</Typography>
    //   <div>
    //     <AddressField
    //       label="Search For Address"
    //       value={addressSearch}
    //       onChange={(e) => {
    //         setAddressSearch(e.target.value);
    //       }}
    //       setAddressSearch={setAddressSearch}
    //       setAddress={setAddress}
    //     />
    //
    //     <InputComponent
    //       label="Street Number"
    //       value={profile.streetNumber}
    //       onChange={handleChangeProfileNonValidate}
    //       onFocus={() => {
    //         updateAddress();
    //       }}
    //       name="streetNumber"
    //     />
    //
    //     <InputComponent
    //       label="Street"
    //       value={profile.street}
    //       onChange={handleChangeProfileNonValidate}
    //       onFocus={() => {
    //         updateAddress();
    //       }}
    //       name="street"
    //     />
    //
    //     <InputComponent
    //       label="City"
    //       value={profile.city}
    //       onChange={handleChangeProfileNonValidate}
    //       onFocus={() => {
    //         updateAddress();
    //       }}
    //       name="city"
    //     />
    //
    //     <InputComponent
    //       label="Province"
    //       value={profile.province}
    //       onChange={handleChangeProfileNonValidate}
    //       onFocus={() => {
    //         updateAddress();
    //       }}
    //       name="province"
    //     />
    //   </div>
    // </Box>
  );
};

export default ProfileForm;
