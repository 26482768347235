import { useCallback, useEffect, useState, useContext, React } from "react";
//import { getDashCounts, getSettings } from "../hooks/useFirestore";
//import { useParams } from "react-router-dom";
import { dashObjectModel } from "../models";
import "bootstrap/dist/css/bootstrap.css";
import "../stylesheets/ewizcomp.css";
import { useFirestore } from "../../hooks/useFirestore";
import { useAppContext } from "../../hooks/useAppContext";
import LoadingOverlay from "react-loading-overlay-ts";
import { tileDataList } from "../mfs/models";
import {
  CCol,
  CRow,
  CWidgetStatsC,
  CWidgetStatsD,
  CWidgetStatsF,
} from "@coreui/react";
import "@coreui/coreui/dist/css/coreui.min.css";

export function Dashboard() {
  const { getDashTotals } = useFirestore();
  const [dash, setDash] = useState({});
  const [isActive, setActive] = useState(true);
  const { show, setObject } = useAppContext();

  // Copy tileDataList to avoid direct mutation
  const tileDataListCopy = [...tileDataList];
  const tileRows = [];
  while (tileDataListCopy.length > 0) {
    tileRows.push(tileDataListCopy.splice(0, 3));
  }

  const getData = useCallback(async () => {
    const dashData = await getDashTotals(show, tileDataList);
    if (dashData) {
      setDash(dashData);
      setActive(false);
    }
  }, [getDashTotals, show]);

  useEffect(() => {
    document.body.style.background = setObject.color;
    getData();
  }, [getData, setObject]);

  return (
    <div>
      {isActive && (
        <LoadingOverlay active={isActive} spinner text="">
          <div>
            <h1 id={"loading"}>
              <span className="let1">l</span>
              <span className="let2">o</span>
              <span className="let3">a</span>
              <span className="let4">d</span>
              <span className="let5">i</span>
              <span className="let6">n</span>
              <span className="let7">g</span>
            </h1>
          </div>
        </LoadingOverlay>
      )}
      {!isActive && (
        <div className={"container"}>
          <img
            style={{ padding: "20px", width: "800px" }}
            src={setObject.showLogo}
            className="img-fluid rounded mx-auto d-block"
            alt=""
          />
          <br />

          {/* Map through each row of tiles */}
          {tileRows.map((row, rowIndex) => (
            <CRow key={rowIndex}>
              {row.map(
                (
                  tileData,
                  columnIndex // Use columnIndex as the key
                ) => (
                  <CCol key={columnIndex} xs={4}>
                    <CWidgetStatsF
                      className="mb-3"
                      color={tileData.color}
                      icon={tileData.icon}
                      title={tileData.dashboardLabel}
                      value={dash[tileData.fieldname.field]}
                    />
                  </CCol>
                )
              )}
            </CRow>
          ))}
        </div>
      )}
    </div>
  );
}
