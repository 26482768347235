import React from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardText,
  CCardTitle,
  CCol,
  CContainer,
  CFormCheck,
  CRow,
} from "@coreui/react";

const ShowForm = ({
  showLogo,
  showName,
  showDescription,
  closeDate,
  handleNextClick,
}) => {
  return (
    <CRow style={{ margin: "2%" }}>
      <CCol>
        <img
          style={{ padding: "50px", width: "500px" }}
          src={showLogo}
          className="img-fluid rounded mx-auto d-block"
          alt=""
        />
        <CContainer>
          <CCard className="text-center">
            <CCardHeader>About</CCardHeader>
            <CCardBody>
              <CCardTitle>{showName}</CCardTitle>
              <CCardText>{showDescription}</CCardText>
              <CButton onClick={handleNextClick}>Enter Show</CButton>
            </CCardBody>
            <CCardFooter className="text-medium-emphasis">
              Entries open until {closeDate}
            </CCardFooter>
          </CCard>
        </CContainer>
      </CCol>
    </CRow>
  );
};

export default ShowForm;
