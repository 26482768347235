import React, { useContext, useState, useEffect } from "react";
import {
  useNavigate,
  Navigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import logo from "../../images/dmf.png";
import PhoneInput from "react-phone-input-2";
import "bootstrap/dist/css/bootstrap.css";
import "react-phone-input-2/lib/bootstrap.css";
import { getSettings } from "../../hooks/useFirestore";

import {
  Form,
  Alert,
  FormGroup,
  ButtonGroup,
  Button,
  Container,
  FormLabel,
  FormControl,
  FormText,
} from "react-bootstrap";
import {
  projectAuth,
  projectFirestore,
  recaptchaVerifierCreate,
} from "../../firebase/config";
import { useAuthContext } from "../../hooks/useAuthContext";
import "../../stylesheets/style.css";
import { useAppContext } from "../../hooks/useAppContext";
import { showBackground, showColours } from "./settings/models";
import MetaTags from "react-meta-tags";

export function ShowLogIn() {
  // const color = "#05a1c9";

  //const { show } = useParams();
  const { show, cellNumber, setObject } = useAppContext();
  //let settings = getSettings(show);

  const [cellno, setCellno] = useState();
  const [error, setError] = useState("");
  const [OTP, setOTP] = useState("");
  const [isActive, setActive] = useState(false);
  const [flag, setflag] = useState(false);
  const [confirmObj, setConfirmObj] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    //Check Open Entry
    const todayDate = new Date();
    const settingsDate = new Date(setObject.closeDate);

    if (todayDate > settingsDate) {
      navigate("/entriesclosed/" + show);
      return;
    }
    //document.body.style.background = setObject.color;

    const color = showColours[show] || "transparent";
    const backgroundImage = showBackground[show]
      ? `url(${showBackground[show]})`
      : "none";

    const tintedImage = `linear-gradient(${color}, ${color}), ${backgroundImage}`;

    document.body.style.background = tintedImage;
    document.body.style.backgroundPosition = "center top";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundRepeat = "no-repeat";

    // setLogo(setObject.showLogo);
    window.scrollTo(0, 0);
  }, [show]);

  const { dispatch } = useAuthContext();

  projectAuth.useDeviceLanguage();

  function recaptchaRender(number) {
    // /** @type {firebase.auth.RecaptchaVerifier} */

    window.recaptchaVerifier = new recaptchaVerifierCreate(
      "recaptcha-container"
    );

    const recaptchaVerifier = window.recaptchaVerifier;

    recaptchaVerifier.render();

    return projectAuth.signInWithPhoneNumber(number, recaptchaVerifier);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (cellno === "" || cellno === undefined) {
      return setError("Please enter a valid cellphone number");
    }

    try {
      const response = await recaptchaRender("+" + cellno);
      console.log(response);
      setConfirmObj(response);
      setflag(true);
    } catch (err) {
      setError(err.message);
    }

    console.log(cellno);
  };

  let cn = "+" + cellno;

  const handleVerify = async (e) => {
    e.preventDefault();
    setError("");
    console.log(OTP);
    if (OTP === "" || OTP === undefined) {
      return;
    }
    confirmObj
      .confirm(OTP)
      .then((result) => {
        console.log(result.user);
        //dispatch login event
        dispatch({ type: "LOGIN", payload: result.user });

        if (setObject.useMain) {
          navigate("/main/" + show + "/" + cn);
        } else {
          navigate("/" + show + "/entry/" + show + "/" + cn);
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <Container>
      <div className="p-4 .container-fluid">
        <MetaTags>
          <title>Connect TV</title>

          <meta property="og:title" content="Connect TV" />
          <meta property="og:image" content={setObject.showsIcon} />
          <meta
            name="description"
            content="EntryWiz - Reality TV Show Entries"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Connect TV" />
        </MetaTags>

        <img
          style={{ padding: "80px", width: "500px" }}
          // src={"../images/" + show + ".jpeg"}
          src={setObject.showLogo}
          className="img-fluid rounded mx-auto d-block"
          alt=""
        />

        {/*<img*/}
        {/*  style={{*/}
        {/*    padding: "80px",*/}
        {/*    width: "800px",*/}
        {/*    height: "350px",*/}
        {/*    objectFit: "cover",*/}
        {/*  }}*/}
        {/*  src={setObject.showLogo}*/}
        {/*  className="img-fluid rounded mx-auto d-block"*/}
        {/*  alt=""*/}
        {/*/>*/}

        <br />
        {error && <Alert variant="danger">{error}</Alert>}
        <Form
          onSubmit={handleSubmit}
          style={{ display: !flag ? "block" : "none" }}
        >
          <FormGroup className="mb-3 col text-center">
            <FormLabel>Enter Your Cellphone Number</FormLabel>
            <PhoneInput
              inputStyle={{ width: "100%" }}
              country={"za"}
              onlyCountries={["za"]}
              placeholder={"Cellphone No"}
              value={cellno}
              onChange={setCellno}
              countryCodeEditable={false}
              masks={{ za: "(..) ...-...." }}
              enableAreaCodeStretch
              inputProps={{
                required: true,
                autoFocus: true,
              }}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <div id="recaptcha-container" />
          </FormGroup>
          <FormGroup className="mb-3">
            <div className="col text-center">
              <Button
                className="text-center"
                variant="primary"
                value="Submit"
                type="submit"
              >
                Get OTP
              </Button>
            </div>
          </FormGroup>
        </Form>

        <Form
          style={{ display: flag ? "block" : "none" }}
          onSubmit={handleVerify}
        >
          <FormGroup className="mb-3">
            <Form.Control
              type="text"
              placeholder="Enter OTP"
              onChange={(e) => setOTP(e.target.value)}
              value={OTP}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <div className="col text-center">
              <Button
                className="text-center"
                variant="primary"
                value="Submit"
                type="submit"
              >
                Confirm
              </Button>
            </div>
          </FormGroup>
        </Form>
        <img
          style={{ padding: "50px", width: "500px" }}
          // src={"../images/" + show + ".jpeg"}
          src={setObject.clientLogo}
          className="img-fluid rounded mx-auto d-block"
          alt=""
        />
      </div>
    </Container>
  );
}
