import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { useAuthContext } from "./hooks/useAuthContext";
import {
  BrowserRouter,
  Route,
  Navigate,
  Routes,
  createSearhParams,
} from "react-router-dom";

import { IndexMainPage } from "./components/indexMainPage";

import { EntryForm as EntryFormDMF } from "./components/dmf/entryForm";
import { EntryList as EntryListDMF } from "./components/dmf/entryList";
import { EntryForm as EntryListViewDMF } from "./components/dmf/entryFormView";

import { EntryForm as EntryFormMFS } from "./components/mfs/entryForm";
import { EntryList as EntryListMFS } from "./components/shared/EntryList";
import { EntryForm as EntryListViewMFS } from "./components/mfs/entryFormView";
import { listColumns as MFSColumns } from "./components/mfs/models";
import { Dashboard as SharedDash } from "./components/shared/ShowDashboard";

// import { EntryForm as EntryFormDemo } from "./components/demo/entryForm";
// import { EntryList as EntryListDemo } from "./components/demo/entryList";

import { EntryForm as EntryFormOPW } from "./components/opw/entryForm";
import { EntryList as EntryListOPW } from "./components/opw/entryList";
import { EntryForm as EntryListViewOPW } from "./components/opw/entryFormView";

import { MainPage as MainOE } from "./components/oe/mainPage";
import { EntryForm as EntryFormOE } from "./components/oe/entryForm";
import { EntryFormCouple as EntryFormCoupleOE } from "./components/oe/entryFormCouple";
import { EntryList as EntryListOE } from "./components/oe/entryList";
import { EntryForm as EntryListView } from "./components/oe/entryFormView";

// import { EntryForm as EntryFormPWAWP } from "./components/pwaw/entryFormPastor";
// import { EntryForm as EntryFormPWAW } from "./components/pwaw/entryForm";
// import { IndexPage as EntryFormIndex } from "./components/pwaw/indexPage";
// import { EntryList as EntryListPWAW } from "./components/pwaw/entryList";

import { Settings } from "./components/settings";
import { EntriesClosed } from "./components/entriesClosed";
import { LogIn } from "./components/logIn";
import { LogIn as LogInAdmin } from "./components/logInEmail";
import { ThankYou } from "./components/thankyou";
import { Dashboard } from "./components/Dashboard";
import { DeleteConfirmation } from "./components/deleteConfirmation";
import AppContextProvider from "./context/AppContext";
import { ShowLogIn } from "./components/shared/ShowLogIn";

function App() {
  const { authIsReady, user } = useAuthContext();

  return (
    <div>
      {authIsReady && (
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                user?.email ? (
                  <AppContextProvider>
                    <IndexMainPage />
                  </AppContextProvider>
                ) : (
                  <Navigate to="/login/admin" />
                )
              }
            />

            <Route path="/test/dash">
              <Route
                path=":show"
                element={
                  <AppContextProvider>
                    <SharedDash />
                  </AppContextProvider>
                }
              />
            </Route>

            {/*Admin and show agnostic*/}
            {/*<Route path="/settings">*/}
            {/*  <Route*/}
            {/*    path=":show"*/}
            {/*    element={*/}
            {/*      <AppContextProvider>*/}
            {/*        <Settings />*/}
            {/*      </AppContextProvider>*/}
            {/*    }*/}
            {/*  />*/}
            {/*</Route>*/}

            <Route path="/settings">
              <Route
                path=":show"
                element={
                  user?.email ? (
                    <AppContextProvider>
                      <Settings />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/admin" />
                  )
                }
              />
            </Route>

            <Route path="/dashboard">
              <Route
                path=":show"
                // element={
                //   <AppContextProvider>
                //     <Dashboard />
                //   </AppContextProvider>
                // }
                element={
                  user?.email ? (
                    <AppContextProvider>
                      <Dashboard />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/admin" />
                  )
                }
              />
            </Route>

            <Route path="/thankyou">
              <Route
                path=":show"
                element={
                  <AppContextProvider>
                    <ThankYou />
                  </AppContextProvider>
                }
              />
            </Route>

            <Route path="/deleteconfirm">
              <Route path=":show" element={<DeleteConfirmation />} />
            </Route>

            <Route path="/entriesclosed">
              <Route
                path=":show"
                element={
                  <AppContextProvider>
                    <EntriesClosed />
                  </AppContextProvider>
                }
              />
            </Route>

            <Route path="/login">
              <Route
                path=":show"
                element={
                  <AppContextProvider>
                    <ShowLogIn />
                  </AppContextProvider>
                }
              />
            </Route>

            {/*<Route path="/login/:show">*/}
            {/*  <AppContextProvider>*/}
            {/*    <Route*/}
            {/*      element={({ params }) => {*/}
            {/*        const { show } = params;*/}
            {/*        if (show === "mfs") {*/}
            {/*          return (*/}
            {/*            <LogIn*/}
            {/*              backgroundColor="red"*/}
            {/*              backgroundImage="url(image-url)"*/}
            {/*            />*/}
            {/*          );*/}
            {/*        } else {*/}
            {/*          return <LogIn />;*/}
            {/*        }*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </AppContextProvider>*/}
            {/*</Route>*/}

            {/*<Route path="/login/admin">*/}
            {/*  <Route*/}
            {/*    path=":show"*/}
            {/*    element={*/}
            {/*      <AppContextProvider>*/}
            {/*        <LogInAdmin />*/}
            {/*      </AppContextProvider>*/}
            {/*    }*/}
            {/*  />*/}
            {/*</Route>*/}

            <Route
              path="/login/admin"
              element={
                <AppContextProvider>
                  <LogInAdmin />
                </AppContextProvider>
              }
            />

            {/*OE*/}

            <Route path="/main">
              <Route
                path=":show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <MainOE />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/oe" />
                  )
                }
              />
            </Route>
            <Route path="/main">
              <Route
                path=":show"
                element={
                  <AppContextProvider>
                    <MainOE />
                  </AppContextProvider>
                }
              />
            </Route>
            <Route path="/oe/entry">
              <Route
                path=":show"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormOE />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/oe" />
                  )
                }
              />
              <Route
                path="couple/:show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormCoupleOE />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/oe" />
                  )
                }
              />
              <Route
                path="single/:show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormOE />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/oe" />
                  )
                }
              />
            </Route>
            <Route path="/oe/list">
              <Route
                path=":show"
                element={
                  user?.email ? (
                    <AppContextProvider>
                      <EntryListOE />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/admin" />
                  )
                }
              />
            </Route>
            <Route path="/oe/list/entry">
              <Route
                path=":show/:cellNumber"
                element={
                  <AppContextProvider>
                    <EntryListView />
                  </AppContextProvider>
                }
              />
              <Route
                path="couple/:show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormCoupleOE />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/oe" />
                  )
                }
              />
              <Route
                path="single/:show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormOE />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/oe" />
                  )
                }
              />
            </Route>

            {/*Tests and Prototypes*/}

            {/*Date My Family*/}
            <Route path="/dmf/entry">
              <Route
                path=":show"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormDMF />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/dmf" />
                  )
                }
              />
              <Route
                path=":show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormDMF />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/dmf" />
                  )
                }
              />
            </Route>
            <Route path="/dmf/list">
              <Route
                path=":show"
                element={
                  user?.email ? (
                    <AppContextProvider>
                      <EntryListDMF />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/admin" />
                  )
                }
              />
            </Route>
            <Route path="/dmf/list/entry">
              <Route
                path=":show/:cellNumber"
                element={
                  <AppContextProvider>
                    <EntryListViewDMF />
                  </AppContextProvider>
                }
              />
            </Route>

            {/*Our Perfect Wedding*/}
            <Route path="/opw/entry">
              <Route
                path=":show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormOPW />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/opw" />
                  )
                }
              />
            </Route>
            <Route path="/opw/list">
              <Route
                path=":show"
                element={
                  user?.email ? (
                    <AppContextProvider>
                      <EntryListOPW />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/admin" />
                  )
                }
              />
            </Route>
            <Route path="/opw/list/entry">
              <Route
                path=":show/:cellNumber"
                element={
                  <AppContextProvider>
                    <EntryListViewOPW />
                  </AppContextProvider>
                }
              />
            </Route>

            {/*Pastor Wants A Wife*/}
            {/*<Route path="/pwaw/entry">*/}
            {/*  /!*<Route*!/*/}
            {/*  /!*  path=":show"*!/*/}
            {/*  /!*  element={user ? <EntryFormOPW /> : <Navigate to="/login/opw" />}*!/*/}
            {/*/>*/}
            {/*  <Route*/}
            {/*    path=":show/:cellNumber"*/}
            {/*    element={*/}
            {/*      user ? (*/}
            {/*        <AppContextProvider>*/}
            {/*          <EntryFormIndex />*/}
            {/*        </AppContextProvider>*/}
            {/*      ) : (*/}
            {/*        <Navigate to="/login/pwaw" />*/}
            {/*      )*/}
            {/*    }*/}
            {/*  />*/}

            {/*  <Route*/}
            {/*    path=":show/:cellNumber/pwaw-w"*/}
            {/*    element={*/}
            {/*      user ? (*/}
            {/*        <AppContextProvider>*/}
            {/*          <EntryFormPWAW />*/}
            {/*        </AppContextProvider>*/}
            {/*      ) : (*/}
            {/*        <Navigate to="/login/pwaw" />*/}
            {/*      )*/}
            {/*    }*/}
            {/*  />*/}
            {/*  <Route*/}
            {/*    path=":show/:cellNumber/pwaw-p"*/}
            {/*    element={*/}
            {/*      user ? (*/}
            {/*        <AppContextProvider>*/}
            {/*          <EntryFormPWAWP />*/}
            {/*        </AppContextProvider>*/}
            {/*      ) : (*/}
            {/*        <Navigate to="/login/pwaw" />*/}
            {/*      )*/}
            {/*    }*/}
            {/*  />*/}
            {/*</Route>*/}

            {/*Date My Family*/}
            <Route path="/mfs/entry">
              <Route
                path=":show"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormMFS />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/mfs" />
                  )
                }
              />
              <Route
                path=":show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormMFS />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/mfs" />
                  )
                }
              />
            </Route>
            <Route path="/mfs/list">
              <Route
                path=":show"
                element={
                  user?.email ? (
                    <AppContextProvider>
                      <EntryListMFS show={"mfs"} columns={MFSColumns} />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/admin" />
                  )
                }
              />
            </Route>
            <Route path="/mfs/list/entry">
              <Route
                path=":show/:cellNumber"
                element={
                  <AppContextProvider>
                    <EntryListViewMFS />
                  </AppContextProvider>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
