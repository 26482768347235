export let entrantProfile = {
  // uid: "",
  // //Profile
  // name: "",
  // surname: "",
  // cellphone: "",
  // email: "",
  // twitter: "",
  // facebook: "",
  // instagram: "",
  // gender: "",
  // age: 0,
  // city: "",
  // province: "",
  // fullAddress: "",

  uid: "",
  name: { value: "", error: false, errorMessage: "Required field" },
  surname: { value: "", error: false, errorMessage: "Required field" },
  cellphone: "",
  email: { value: "", error: false, errorMessage: "Required field" },
  twitter: { value: "", error: false, errorMessage: "Required field" },
  facebook: { value: "", error: false, errorMessage: "Required field" },
  instagram: { value: "", error: false, errorMessage: "Required field" },
  gender: { value: "", error: false, errorMessage: "Required field" },
  age: { value: 18, error: false, errorMessage: "Required field" },
  city: "",
  province: "",
  fullAddress: "",
  street: "",
  streetNumber: "",
  // DOB: {
  //   value: "",
  //   error: false,
  //   errorMessage: "You must enter a date of birth",
  // },
};

export let opwEntry = {
  uid: "",

  signature: "",
  pic: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Picture",
    fieldType: "file",
  },
  video: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Video",
    fieldType: "file",
  },
  bridename: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Bride's Name",
    fieldType: "text",
  },
  brideage: {
    value: 18,
    error: false,
    errorMessage: "Required field",
    label: "Bride's Age",
    fieldType: "number",
  },
  bridecontact: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Bride's Contact",
    fieldType: "text",
  },
  bridehomelanguage: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Bride's Home Language",
    fieldType: "text",
  },
  describeBride: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Describe Bride",
    fieldType: "textarea",
  },
  groomname: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Groom's Name",
    fieldType: "text",
  },
  groomage: {
    value: 18,
    error: false,
    errorMessage: "Required field",
    label: "Groom's Age",
    fieldType: "number",
  },
  groomhomelanguage: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Groom's Home Language",
    fieldType: "text",
  },
  describeGroom: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Describe Groom",
    fieldType: "textarea",
  },
  groomcontact: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Groom's Contact",
    fieldType: "text",
  },
  weddingtype: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Wedding Type",
    fieldType: "text",
  },
  themecolours: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Theme Colours",
    fieldType: "text",
  },
  weddingdate: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Wedding Date",
    fieldType: "date",
  },
  weddingvanue: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Wedding Venue",
    fieldType: "text",
  },
  wishlist: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Wishlist",
    fieldType: "textarea",
  },
  meet: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Meet",
    fieldType: "textarea",
  },
  challenges: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Challenges",
    fieldType: "textarea",
  },
  proposaldate: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Proposal Date",
    fieldType: "date",
  },
  proposal: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Proposal",
    fieldType: "textarea",
  },
  day: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Day",
    fieldType: "text",
  },
  whyopw: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Why OPW",
    fieldType: "textarea",
  },
  bridesmaids: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Bridesmaids",
    fieldType: "textarea",
  },
  groomsmen: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Groomsmen",
    fieldType: "textarea",
  },
  choicesbridesmaids: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Choices for Bridesmaids",
    fieldType: "textarea",
  },
  choicesgroomsmen: {
    value: "",
    error: false,
    errorMessage: "Required field",
    label: "Choices for Groomsmen",
    fieldType: "textarea",
  },
  status: "New",
};

export let listColumns = [
  "name",
  "surname",
  "weddingtype",
  "weddingdate",
  "brideage",
  "groomage",
  "city",
  "province",
];

export let excludeValidation = [
  "pic",
  "video",
  "twitter",
  "facebook",
  "instagram",
  "proposaldate",
  "day",
  "status",
  "weddingvanue",
];

//Old Model
// pic: { value: "", error: false, errorMessage: "Required field" },
// video: { value: "", error: false, errorMessage: "Required field" },
//
// bridename: { value: "", error: false, errorMessage: "Required field" },
// // bridearea: { value: "", error: false, errorMessage: "Required field" },
// brideage: { value: 18, error: false, errorMessage: "Required field" },
// // brideaddress: { value: "", error: false, errorMessage: "Required field" },
// bridecontact: { value: "", error: false, errorMessage: "Required field" },
// bridehomelanguage: {
//   value: "",
//   error: false,
//   errorMessage: "Required field",
// },
// describeBride: { value: "", error: false, errorMessage: "Required field" },
// // bridechildren: { value: "", error: false, errorMessage: "Required field" },
// // bridechildrenage: { value: "", error: false, errorMessage: "Required field" },
//
// groomname: { value: "", error: false, errorMessage: "Required field" },
// // groomarea: { value: "", error: false, errorMessage: "Required field" },
// groomage: { value: 18, error: false, errorMessage: "Required field" },
// // groomaddress: { value: "", error: false, errorMessage: "Required field" },
// groomhomelanguage: {
//   value: "",
//   error: false,
//   errorMessage: "Required field",
// },
// describeGroom: { value: "", error: false, errorMessage: "Required field" },
//
// // groomchildren: { value: "", error: false, errorMessage: "Required field" },
// // groomchildrenage: { value: "", error: false, errorMessage: "Required field" },
// groomcontact: { value: "", error: false, errorMessage: "Required field" },
// weddingtype: { value: "", error: false, errorMessage: "Required field" },
// themecolours: { value: "", error: false, errorMessage: "Required field" },
// weddingdate: { value: "", error: false, errorMessage: "Required field" },
// weddingvanue: { value: "", error: false, errorMessage: "Required field" },
// // children: { value: "", error: false, errorMessage: "Required field" },
// // homelanuage: { value: "", error: false, errorMessage: "Required field" },
// wishlist: { value: "", error: false, errorMessage: "Required field" },
// meet: { value: "", error: false, errorMessage: "Required field" },
// challenges: { value: "", error: false, errorMessage: "Required field" },
// proposaldate: { value: "", error: false, errorMessage: "Required field" },
// proposal: { value: "", error: false, errorMessage: "Required field" },
// day: { value: "", error: false, errorMessage: "Required field" },
// // partner: { value: "", error: false, errorMessage: "Required field" },
// whyopw: { value: "", error: false, errorMessage: "Required field" },
// bridesmaids: { value: "", error: false, errorMessage: "Required field" },
// groomsmen: { value: "", error: false, errorMessage: "Required field" },
// choicesbridesmaids: {
//   value: "",
//   error: false,
//   errorMessage: "Required field",
// },
// choicesgroomsmen: { value: "", error: false, errorMessage: "Required field" },
