import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "../../stylesheets/style.css";
import "bootstrap/dist/css/bootstrap.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Alert, Col, Form, Row } from "react-bootstrap";
import "react-rating";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl as FormControlM,
  TextField,
  InputAdornment,
  FormGroup,
  FormControl,
} from "@mui/material";
import { Facebook, Instagram, Twitter } from "react-bootstrap-icons";
import { opwEntry, entrantProfile, excludeValidation } from "./models";

import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardText,
  CCardTitle,
  CCol,
  CContainer,
  CFormCheck,
  CRow,
} from "@coreui/react";
import renderTermsAndConditions from "../TermsAndConditions";
import SignatureCanvas from "react-signature-canvas";
import { useEntryWizFunctions } from "../../hooks/useEntryWizFunctions";
import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";
import DeleteDialog from "../alerts";

export function EntryForm() {
  const [openClose, setOpenClose] = useState(false);
  const [error, setError] = useState("");
  const { user } = useAuthContext();
  const form = useRef();

  const {
    fetchEntry,
    fetchProfile,
    handleNextClick,
    handlePrevClick,
    handleTermsChange,
    handleImage,
    handleVideo,
    handleSubmit,
    handleChangeEntry,
    handleChangeProfile,
    step,
    showEntry,
    profile,
    isActive,
    terms,
    show,
    cellNumber,
    setObject,
    showPic,
    showVid,
    ref,
    setActive,
    addressSearch,
    setAddressSearch,
    sigCanvasRef,
    handleChangeProfileNonValidate,
    updateAddress,
    address,
  } = useEntryWizFunctions(entrantProfile, opwEntry, excludeValidation);

  const handleDelete = () => {
    setOpenClose(true);
  };

  useLayoutEffect(() => {
    if (showEntry.signature) {
      sigCanvasRef.current.fromDataURL(showEntry.signature);
    }
  }, [showEntry.signature, sigCanvasRef]);

  useEffect(() => {
    setActive(true);
    fetchProfile(cellNumber);
    fetchEntry(show, cellNumber);
  }, []);

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text="Busy Processing, Please Wait..."
    >
      <div
        className="testbox"
        // style={{ "box-shadow": "0 20px 0 #a82877" }}
        style={{ margin: "2%" }}
      >
        {error && <Alert variant="danger">{error}</Alert>}
        <DeleteDialog show={show} cellNumber={cellNumber} open={openClose} />
        <fieldset disabled={false}>
          <Form
            ref={form}
            id="enterDMF"
            name="theform"
            onSubmit={handleSubmit}
            disabled
            component="form"
            className="testbox text-center"
            style={{
              padding: 20,
              background: `rgba(255, 255, 255, 0.7)`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div style={{ display: step.activeStep === 1 ? "block" : "none" }}>
              {/* Names */}
              <Row style={{ margin: "2%" }}>
                <img
                  style={{ padding: "50px", width: "500px" }}
                  // src={"../images/" + show + ".jpeg"}
                  src={setObject.showLogo}
                  className="img-fluid rounded mx-auto d-block"
                  alt=""
                />
                <CContainer>
                  <CCard className="text-center">
                    <CCardHeader>About</CCardHeader>{" "}
                    <CCardBody>
                      <CCardTitle>{setObject.showName}</CCardTitle>{" "}
                      <CCardText>{setObject.showDescription}</CCardText>
                      <CButton onClick={handleNextClick}>Enter Show</CButton>
                    </CCardBody>
                    <CCardFooter className="text-medium-emphasis">
                      Entries open until {setObject.closeDate}
                    </CCardFooter>
                  </CCard>
                </CContainer>
              </Row>
            </div>
            <div style={{ display: step.activeStep === 2 ? "block" : "none" }}>
              <h1>Personal Profile</h1>
              <h6 style={{ color: "grey" }}>
                *Struggling to see? rotate phone
              </h6>
              {/* Names */}
              <div>
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="ename"
                  label="Name"
                  name="name"
                  variant="outlined"
                  value={profile.name.value}
                  error={profile.name.error}
                  onChange={handleChangeProfile}
                />
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="esurname"
                  label="Surname"
                  name="surname"
                  variant="outlined"
                  value={profile.surname.value}
                  error={profile.surname.error}
                  onChange={handleChangeProfile}
                />
              </div>
              {/*Contacts*/}
              <div>
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="ecellphone"
                  name="phoneNumber"
                  label="Cellphone"
                  variant="outlined"
                  value={user.phoneNumber}
                  onChange={handleChangeProfile}
                  disabled
                />

                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="email"
                  label="E-Mail"
                  variant="outlined"
                  name="email"
                  value={profile.email.value}
                  error={profile.email.error}
                  onChange={handleChangeProfile}
                />
              </div>
              {/*Age and gender*/}
              <div>
                <TextField
                  id="age"
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  type={"number"}
                  min={18}
                  name="age"
                  label="Age"
                  placeholder="Enter your age"
                  variant="outlined"
                  value={profile.age.value}
                  error={profile.age.error}
                  onChange={handleChangeProfile}
                />

                <FormControl
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                >
                  <InputLabel id="genderSelect">Gender</InputLabel>
                  <Select
                    labelId="genderSelect"
                    label="Gender"
                    name="gender"
                    value={profile.gender.value}
                    error={profile.gender.error}
                    onChange={handleChangeProfile}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div>
                <TextField
                  sx={{ m: 2, width: "92%" }}
                  inputRef={ref}
                  label="Search For Address"
                  variant="outlined"
                  name="fullAddress"
                  className={"form-control"}
                  value={addressSearch}
                  onChange={(e) => {
                    setAddressSearch(e.target.value);
                  }}
                />
              </div>
              <div>
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="streetNumber"
                  name="streetNumber"
                  label="Street Number"
                  variant="outlined"
                  className={"form-control"}
                  value={profile.streetNumber}
                  onChange={handleChangeProfileNonValidate}
                  onFocus={() => {
                    updateAddress(address);
                  }}
                />
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="street"
                  name="street"
                  label="Street"
                  variant="outlined"
                  className={"form-control"}
                  value={profile.street}
                  onChange={handleChangeProfileNonValidate}
                  onFocus={() => {
                    updateAddress(address);
                  }}
                />
              </div>

              <div>
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="city"
                  name="city"
                  label="City"
                  variant="outlined"
                  className={"form-control"}
                  value={profile.city}
                  onChange={handleChangeProfileNonValidate}
                  onFocus={() => {
                    updateAddress(address);
                  }}
                />
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="province"
                  name="province"
                  label="Province"
                  variant="outlined"
                  className={"form-control"}
                  value={profile.province}
                  onChange={handleChangeProfileNonValidate}
                  onFocus={() => {
                    updateAddress(address);
                  }}
                />
              </div>
            </div>
            <div style={{ display: step.activeStep === 3 ? "block" : "none" }}>
              <h1>Entry</h1>
              <h6 style={{ color: "grey" }}>
                *Struggling to see? rotate phone
              </h6>
              <div style={{ margin: "2%" }}>
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="bname"
                  label="Bride's Name"
                  name="bridename"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.bridename.value}
                  error={showEntry.bridename.error}
                  onChange={handleChangeEntry}
                />

                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="egroomsname"
                  label="Groom's Name"
                  name="groomname"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomname.value}
                  error={showEntry.groomsmen.error}
                  onChange={handleChangeEntry}
                />
              </div>
              <div style={{ margin: "2%" }}>
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="bage"
                  // sx={{ m: 2, width: "45%" }}
                  type={"number"}
                  min={18}
                  name="brideage"
                  label="Bride's Age"
                  placeholder="Enter Bride age"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.brideage.value}
                  error={showEntry.brideage.error}
                  onChange={handleChangeEntry}
                />

                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="gage"
                  // sx={{ m: 2, width: "45%" }}
                  type={"number"}
                  min={18}
                  name="groomage"
                  label="Groom's Age"
                  placeholder="Enter Groom age"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomage.value}
                  error={showEntry.groomage.error}
                  onChange={handleChangeEntry}
                />
              </div>
              <div style={{ margin: "2%" }}>
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="bcontact"
                  label="Bride's Contact Number"
                  name="bridecontact"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.bridecontact.value}
                  error={showEntry.bridecontact.error}
                  onChange={handleChangeEntry}
                />

                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="gcontact"
                  label="Groom's Contact Number"
                  name="groomcontact"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomcontact.value}
                  error={showEntry.groomcontact.error}
                  onChange={handleChangeEntry}
                />
              </div>
              {/*  home language */}
              <div style={{ margin: "2%" }}>
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="Blang"
                  label="Bride's Home Language"
                  name="bridehomelanguage"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.bridehomelanguage.value}
                  error={showEntry.bridehomelanguage.error}
                  onChange={handleChangeEntry}
                />

                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="gland"
                  label="Groom's Home Language"
                  name="groomhomelanguage"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomhomelanguage.value}
                  error={showEntry.groomhomelanguage.error}
                  onChange={handleChangeEntry}
                />
              </div>
              <div style={{ margin: "2%" }}>
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="bDesc"
                  label="Description Of The Bride"
                  name="describeBride"
                  variant="outlined"
                  placeholder="Please provide a brief description of the bride"
                  value={showEntry.describeBride.value}
                  error={showEntry.describeBride.error}
                  onChange={handleChangeEntry}
                  multiline
                  rows={4}
                />
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  label="Description Of The Groom"
                  id="gDesc"
                  name="describeGroom"
                  variant="outlined"
                  placeholder="Please provide a brief description of the groom"
                  value={showEntry.describeGroom.value}
                  error={showEntry.describeGroom.error}
                  onChange={handleChangeEntry}
                  multiline
                  rows={4}
                />
              </div>
              {/*Theme Colours and Wedding Type*/}
              <div style={{ margin: "2%" }}>
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="tcolors"
                  label="Theme Colours"
                  name="themecolours"
                  variant="outlined"
                  value={showEntry.themecolours.value}
                  error={showEntry.themecolours.error}
                  onChange={handleChangeEntry}
                />

                <FormControlM
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                >
                  <InputLabel id="edateSelect">Wedding Type</InputLabel>
                  <Select
                    labelId="wtselect"
                    label="Wedding Type"
                    name="weddingtype"
                    value={showEntry.weddingtype.value}
                    error={showEntry.weddingtype.error}
                    defaultValue={"Other"}
                    onChange={handleChangeEntry}
                  >
                    <MenuItem value="Traditional">Traditional</MenuItem>
                    <MenuItem value="White">White</MenuItem>
                    <MenuItem value="Traditional & White">
                      Traditional & White
                    </MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControlM>
              </div>
              {/* Wedding date & vanue */}
              <div style={{ margin: "2%" }}>
                {/*<InputLabel id="Wedding Date">Wedding Date</InputLabel>*/}
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  label="Wedding Date"
                  name="weddingdate"
                  value={showEntry.weddingdate.value}
                  error={showEntry.weddingdate.error}
                  variant="outlined"
                  type="date"
                  onChange={handleChangeEntry}
                />

                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="venue"
                  label="Venue"
                  name="weddingvanue"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.weddingvanue.value}
                  error={showEntry.weddingvanue.error}
                  onChange={handleChangeEntry}
                />
              </div>

              <div style={{ margin: "2%" }}>
                <TextField
                  sx={{ m: 2, width: "92%" }}
                  multiline
                  rows={4}
                  label="Your wish list?"
                  placeholder="Please enter your three wishes..."
                  name="wishlist"
                  variant="outlined"
                  value={showEntry.wishlist.value}
                  error={showEntry.wishlist.error}
                  onChange={handleChangeEntry}
                ></TextField>
              </div>
              <div style={{ margin: "2%" }}>
                <TextField
                  sx={{ m: 2, width: "92%" }}
                  name="meet"
                  multiline
                  rows={5}
                  placeholder="How did you meet? We would like the whole story, how you met, when you met, where you met, what was going on in your minds when it all happened, the WHOLE story."
                  label="How did you meet?"
                  variant="outlined"
                  value={showEntry.meet.value}
                  error={showEntry.meet.error}
                  onChange={handleChangeEntry}
                ></TextField>
              </div>
              <div style={{ margin: "2%" }}>
                <TextField
                  sx={{ m: 2, width: "92%" }}
                  name="challenges"
                  multiline
                  rows={5}
                  label="Challenges"
                  placeholder="What challenges have you guys had to go through in your relationship?"
                  variant="outlined"
                  value={showEntry.challenges.value}
                  error={showEntry.challenges.error}
                  onChange={handleChangeEntry}
                ></TextField>
              </div>

              {/* Proposal & date */}
              <div style={{ margin: "2%" }}>
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  label="When was the proposal?"
                  name="proposaldate"
                  value={showEntry.proposaldate.value}
                  error={showEntry.proposaldate.error}
                  className="form-control"
                  type="date"
                  onChange={handleChangeEntry}
                />

                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  name="proposal"
                  multiline
                  rows={4}
                  label="How did they propose?"
                  placeholder="How did they propose? Tell us what happened at that moment? (the proposal)"
                  className={"form-control"}
                  variant="outlined"
                  value={showEntry.proposal.value}
                  error={showEntry.proposal.error}
                  onChange={handleChangeEntry}
                ></TextField>
              </div>
              {/*Why that day? */}
              {/*<Row style={{ margin: "2%" }}>*/}
              {/*  <Col>*/}
              {/*    <TextField*/}
              {/*      id="day"*/}
              {/*      label="What is behind that day?"*/}
              {/*      name="day"*/}
              {/*      variant="outlined"*/}
              {/*      placeholder="What is behind choosing the day you getting married on?"*/}
              {/*      className={"form-control"}*/}
              {/*      value={showEntry.day.value}*/}
              {/*      error={showEntry.day.error}*/}
              {/*      onChange={handleChangeEntry}*/}
              {/*    />*/}
              {/*  </Col>*/}
              {/*</Row>*/}

              <div style={{ margin: "2%" }}>
                <TextField
                  sx={{ m: 2, width: "92%" }}
                  name="whyopw"
                  id="whyopw"
                  label="Why do want Our Perfect Wedding to come?"
                  variant="outlined"
                  placeholder="Why do you want Our Perfect Wedding to come to your wedding?"
                  value={showEntry.whyopw.value}
                  error={showEntry.whyopw.error}
                  onChange={handleChangeEntry}
                  multiline
                  rows={4}
                />
              </div>
              {/*How many maids and men*/}
              <div style={{ margin: "2%" }}>
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="gmen"
                  // sx={{ m: 2, width: "45%" }}
                  type={"number"}
                  name="groomsmen"
                  label="Groomsmen"
                  placeholder="How many groomsmen do you have"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomsmen.value}
                  error={showEntry.groomsmen.error}
                  onChange={handleChangeEntry}
                />

                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="gchoices"
                  label="Groomsmen choices?"
                  name="choicesgroomsmen"
                  placeholder="How did you make your choices?"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.choicesgroomsmen.value}
                  error={showEntry.choicesgroomsmen.error}
                  onChange={handleChangeEntry}
                />
              </div>
              {/*How many maids and men*/}
              <div style={{ margin: "2%" }}>
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="maids"
                  // sx={{ m: 2, width: "45%" }}
                  type={"number"}
                  name="bridesmaids"
                  label="Bridesmaids"
                  placeholder="How many bridesmaids do you have"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.bridesmaids.value}
                  error={showEntry.bridesmaids.error}
                  onChange={handleChangeEntry}
                />{" "}
                <TextField
                  sx={{
                    m: 2,
                    width: { xs: "92%", sm: "45%" },
                  }}
                  id="maidschoices"
                  label="Bridesmaids choices?"
                  name="choicesbridesmaids"
                  placeholder="How did you make your choices?"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.choicesbridesmaids.value}
                  error={showEntry.choicesbridesmaids.error}
                  onChange={handleChangeEntry}
                />
              </div>
            </div>
            <div style={{ display: step.activeStep === 4 ? "block" : "none" }}>
              <h1>Media</h1>
              <Row style={{ margin: "2%" }}>
                <Col>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Upload a pic of you together</Form.Label>
                    <Form.Control
                      className={"form-control"}
                      type="file"
                      id="pic"
                      name="pic"
                      onChange={handleImage}
                      accept="image/*"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <img
                    style={{ padding: "20px" }}
                    id={"showPic"}
                    src={showPic}
                    className="img-fluid rounded mx-auto d-block"
                    alt=""
                  />
                </Col>
              </Row>
              <Row style={{ margin: "2%" }}>
                <Col>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>
                      Upload a 30 sec video introducing yourselves
                    </Form.Label>
                    <Form.Control
                      type="file"
                      id="video"
                      name="video"
                      onChange={handleVideo}
                      accept="video/*"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <video
                    style={{ padding: "20px" }}
                    src={showVid}
                    id={"vidShow"}
                    className="img-fluid rounded mx-auto d-block"
                    controls
                  />
                </Col>
              </Row>
            </div>
            <div style={{ display: step.activeStep === 5 ? "block" : "none" }}>
              <CContainer className="text-center">
                <FormGroup>
                  <Form.Label>Accept terms and conditions to submit</Form.Label>
                  {renderTermsAndConditions()}
                  <div
                    className="sigPadContainer"
                    style={{ border: "double", margin: 1 }}
                  >
                    <label>Please sign</label>
                    <CRow>
                      <CCol>
                        <SignatureCanvas
                          penColor="black"
                          canvasProps={{
                            className: "sigCanvas",
                          }}
                          ref={sigCanvasRef}
                        />
                      </CCol>
                    </CRow>
                    <hr />
                    <CButton onClick={() => sigCanvasRef.current.clear()}>
                      Clear
                    </CButton>
                  </div>
                  <CFormCheck
                    // style={{ width: "15%", margin: 3 }}
                    id="btn-check-outlined"
                    label="Accept Terms & Conditions"
                    autoComplete="off"
                    onChange={handleTermsChange}
                    value={terms.checked.toString()}
                    style={{
                      width: 40,
                      height: 40,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                </FormGroup>
              </CContainer>
            </div>

            {terms.checked === true ? (
              <div>
                <CButton
                  style={{ width: "15%", margin: 3 }}
                  color="danger"
                  variant="outline"
                  onClick={handleDelete}
                >
                  <CIcon icon={icon.cilTrash} size="xxl" /> Delete
                </CButton>
                <CButton
                  style={{ width: "15%", margin: 3 }}
                  color="success"
                  variant="outline"
                  type="submit"
                >
                  <CIcon icon={icon.cilSend} size="xxl" /> Submit
                </CButton>
              </div>
            ) : null}

            {terms.checked === false && step.activeStep !== 1 ? (
              <div>
                <CButton
                  variant="outline"
                  color="danger"
                  style={{ width: "15%", margin: 3 }}
                  onClick={handlePrevClick}
                  disabled={step.activeStep === 1}
                >
                  <CIcon icon={icon.cilArrowThickFromRight} size="xxl" /> Back
                </CButton>
                <CButton
                  color="primary"
                  variant="outline"
                  style={{ width: "15%", margin: 3 }}
                  onClick={handleNextClick}
                  disabled={step.activeStep === 5}
                >
                  Next <CIcon icon={icon.cilArrowThickFromLeft} size="xxl" />
                </CButton>
              </div>
            ) : null}
          </Form>
        </fieldset>
      </div>
    </LoadingOverlay>
  );
}
