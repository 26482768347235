import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import SignatureCanvas from "react-signature-canvas";
import "../../stylesheets/style.css";
import "bootstrap/dist/css/bootstrap.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { useFirestore, getEntry, getProfile } from "../../hooks/useFirestore";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import "react-rating";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
} from "react-component-export-image";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputAdornment,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { Facebook, Instagram, Twitter } from "react-bootstrap-icons";
import { dmfEntry, entrantProfile, excludeValidation } from "./models";
import DeleteDialog from "../alerts";
import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";

import { CButton, CCol, CContainer, CRow } from "@coreui/react";
import { useEntryWizFunctions } from "../../hooks/useEntryWizFunctions";

export function EntryForm() {
  const {
    fetchEntry,
    fetchProfile,
    handleSubmit,
    handleChangeEntry,
    handleChangeEntryNonValidate,
    handleChangeProfile,
    showEntry,
    profile,
    isActive,
    show,
    cellNumber,
    showVid,
    setActive,
    setShowEntry,
    handleSubmitNoEmail,
    showPic,
    showPic2,
    showPic3,
    updateStatus,
  } = useEntryWizFunctions(entrantProfile, dmfEntry, excludeValidation);

  const [openClose, setOpenClose] = useState(false);
  const [error, setError] = useState("");

  const formRef = useRef();
  const navigate = useNavigate();

  const handleToList = () => {
    navigate("/" + show + "/list/" + show);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    //console.log(user);
    setActive(true);
    fetchProfile(cellNumber);
    fetchEntry(show, cellNumber);

    //profile.cellphone.value = profile.cellphone.value;
  }, []);

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <div ref={ref}>
      <div>
        <h1>Personal Profile</h1>
        {/* Names */}
        <div>
          <TextField
            sx={{ m: 2, width: "45%" }}
            id="ename"
            label="Name"
            name="name"
            variant="outlined"
            value={profile.name.value}
            onChange={handleChangeProfile}
            className={"form-control"}
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            sx={{ m: 2, width: "45%" }}
            id="esurname"
            label="Surname"
            name="surname"
            variant="outlined"
            value={profile.surname.value}
            onChange={handleChangeProfile}
            className={"form-control"}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        {/*Contacts*/}
        <div>
          <TextField
            sx={{ m: 2, width: "45%" }}
            id="ecellphone"
            name="cellphone"
            label="Cellphone"
            variant="outlined"
            className={"form-control"}
            value={cellNumber}
            onChange={handleChangeProfile}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            sx={{ m: 2, width: "45%" }}
            id="email"
            name="email"
            label="E-Mail"
            variant="outlined"
            className={"form-control"}
            value={profile.email.value}
            onChange={handleChangeProfile}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        {/*Age and gender*/}
        <div>
          <TextField
            sx={{ m: 2, width: "45%" }}
            type={"date"}
            id="DOB"
            name="DOB"
            label="Date Of Birth"
            variant="outlined"
            value={profile.DOB.value}
            onChange={(event) => {
              handleChangeProfile(event);
              // calculateAge(event);
            }}
            className={"form-control"}
            error={profile.DOB.error}
            InputProps={{
              readOnly: true,
            }}

            // helperText={profile.DOB.errorMessage}
          />

          <FormControl sx={{ m: 2, width: "45%" }}>
            <InputLabel id="genderSelect">Gender</InputLabel>
            <Select
              variant="outlined"
              name="gender"
              label="genderSelect"
              value={profile.gender.value}
              onChange={handleChangeProfile}
              InputProps={{
                readOnly: true,
              }}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
        </div>
        {/*Social Media*/}
        <div>
          <TextField
            sx={{ m: 2, width: "29%" }}
            id="twitter"
            name="twitter"
            label="Twitter"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Twitter />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            value={profile.twitter.value}
            onChange={handleChangeProfile}
          />

          <TextField
            sx={{ m: 2, width: "29%" }}
            id="instagram"
            name="instagram"
            label="Instagram"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Instagram />
                </InputAdornment>
              ),
            }}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            value={profile.instagram.value}
            onChange={handleChangeProfile}
          />

          <TextField
            sx={{ m: 2, width: "29%" }}
            id="facebook"
            name="facebook"
            label="Facebook"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Facebook />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            value={profile.facebook.value}
            onChange={handleChangeProfile}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        {/*Address*/}
        <div>
          <TextField
            sx={{ m: 2, width: "45%" }}
            id="streetNumber"
            name="streetNumber"
            label="Street Number"
            variant="outlined"
            className={"form-control"}
            value={profile.streetNumber}
            // onChange={handleChangeProfileNonValidate}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            sx={{ m: 2, width: "45%" }}
            id="street"
            name="street"
            label="Street"
            variant="outlined"
            className={"form-control"}
            value={profile.street}
            // onChange={handleChangeProfileNonValidate}
          />
        </div>

        <div>
          <TextField
            sx={{ m: 2, width: "45%" }}
            id="city"
            name="city"
            label="City"
            variant="outlined"
            className={"form-control"}
            value={profile.city}
            //  onChange={handleChangeProfileNonValidate}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            sx={{ m: 2, width: "45%" }}
            id="province"
            name="province"
            label="Province"
            variant="outlined"
            className={"form-control"}
            value={profile.province}
            // onChange={handleChangeProfileNonValidate}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
      </div>
      <div>
        <h1>Entry</h1>
        {/*Height and Date*/}
        <div>
          <TextField
            sx={{ m: 2, width: "45%" }}
            type={"number"}
            name="height"
            id="height"
            min={80}
            label="Height"
            placeholder="Enter your height"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            variant="outlined"
            className={"form-control"}
            value={showEntry.height.value}
            onChange={handleChangeEntry}
            InputProps={{
              readOnly: true,
            }}
          />

          <FormControl sx={{ m: 2, width: "45%" }}>
            <InputLabel id="edateSelect">Date?</InputLabel>
            <Select
              variant="outlined"
              labelId="dateSelect"
              label="Date"
              name="date"
              value={showEntry.date.value}
              defaultValue={"Other"}
              onChange={handleChangeEntry}
              InputProps={{
                readOnly: true,
              }}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Other">Non-Binary</MenuItem>
            </Select>
          </FormControl>
        </div>

        {/*Highest schooling achievement? */}
        <div>
          <FormControl sx={{ m: 2, width: "45%" }}>
            <InputLabel id="shoolingSelect">Schooling</InputLabel>
            <Select
              className={"form-control"}
              labelId="shoolingSelect"
              label="Education"
              name="schooling"
              value={showEntry.schooling.value}
              onChange={handleChangeEntry}
            >
              <MenuItem value="None">None</MenuItem>
              <MenuItem value="High School">High school</MenuItem>
              <MenuItem value="Technical">Technical Certificate</MenuItem>
              <MenuItem value="Occupational">Occupational Certificate</MenuItem>
              <MenuItem value="Professional">Professional</MenuItem>
              <MenuItem value="Associate">Associate degree</MenuItem>
              <MenuItem value="Bachelor">Bachelor's degree</MenuItem>
              <MenuItem value="Master">Master's degree</MenuItem>
              <MenuItem value="Doctorate">Doctorate</MenuItem>
            </Select>
          </FormControl>

          {/* Date & Criminal Record  */}
          <FormControl sx={{ m: 2, width: "45%" }}>
            <InputLabel id="genderSelect">Criminal Record?</InputLabel>
            <Select
              className={"form-control"}
              labelId="genderSelect"
              name="criminalrecord"
              label="Do you have a Criminal Record?"
              value={showEntry.criminalrecord.value}
              onChange={handleChangeEntry}
              InputProps={{
                readOnly: true,
              }}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div>
          <TextField
            sx={{ m: 2, width: "45%" }}
            multiline
            rows={4}
            name="idealphysic"
            label="Ideal partner's physic?"
            placeholder="Describe your ideal partner's physic?"
            className={"form-control"}
            variant="outlined"
            value={showEntry.idealphysic.value}
            onChange={handleChangeEntry}
            InputProps={{
              readOnly: true,
            }}
          ></TextField>

          <FormControl sx={{ m: 2, width: "45%" }}>
            <InputLabel id="employed">Do they have to be employed?</InputLabel>
            <Select
              className={"form-control"}
              name="employed"
              labelId="employed"
              label="Do they have to be employed"
              value={showEntry.employed.value}
              onChange={handleChangeEntry}
              InputProps={{
                readOnly: true,
              }}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </div>
        {/* Religion  Car?"*/}
        <div>
          <FormControl sx={{ m: 2, width: "45%" }}>
            <InputLabel id="religionSelect">
              What religion must they follow?
            </InputLabel>
            <Select
              className={"form-control"}
              labelId="religionSelect"
              name="religion"
              label="Religion"
              value={showEntry.religion.value}
              onChange={handleChangeEntry}
              InputProps={{
                readOnly: true,
              }}
            >
              <MenuItem value="Any">Any</MenuItem>
              <MenuItem value="African Diaspora Religions">
                African Diaspora Religions
              </MenuItem>
              <MenuItem value="Atheism/Agnosticism">
                Atheism/Agnosticism
              </MenuItem>
              <MenuItem value="American Religions">American Religions</MenuItem>
              <MenuItem value="Bahá’í">Bahá’í</MenuItem>
              <MenuItem value="Buddhism">Buddhism</MenuItem>
              <MenuItem value="Christianity">Christianity</MenuItem>
              <MenuItem value="Confucianism">Confucianism</MenuItem>
              <MenuItem value="Druze">Druze</MenuItem>
              <MenuItem value="Gnosticism">Gnosticism</MenuItem>
              <MenuItem value="Hinduism">Hinduism</MenuItem>
              <MenuItem value="Indigenous">Indigenous</MenuItem>
              <MenuItem value="Islam">Islam</MenuItem>
              <MenuItem value="Jainism">Jainism</MenuItem>
              <MenuItem value="Judaism">Judaism</MenuItem>
              <MenuItem value="Rastafarianism">Rastafarianism</MenuItem>
              <MenuItem value="Shinto">Shinto</MenuItem>
              <MenuItem value="Sikhism">Sikhism</MenuItem>
              <MenuItem value="Traditional African Religions">
                Traditional African Religions
              </MenuItem>
              <MenuItem value="Zoroastrianism">Zoroastrianism</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 2, width: "45%" }}>
            <InputLabel id="car">Should they have a car??</InputLabel>
            <Select
              className={"form-control"}
              labelId="car"
              name="car"
              label="Should they have a car?"
              value={showEntry.car.value}
              onChange={handleChangeEntry}
              InputProps={{
                readOnly: true,
              }}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </div>

        {/* Do you have kids?  Max Kids?"*/}
        <div>
          <FormControl sx={{ m: 2, width: "45%" }}>
            <InputLabel id="pkids">Do you have kids??</InputLabel>
            <Select
              className={"form-control"}
              labelId="pkids"
              name="kids"
              label="Do you have kids??"
              value={showEntry.kids.value}
              onChange={handleChangeEntry}
              InputProps={{
                readOnly: true,
              }}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ m: 2, width: "45%" }}
            type={"number"}
            min={0}
            label="Kids number"
            name="kidsnumber"
            placeholder="Enter your height"
            variant="outlined"
            className={"form-control"}
            value={showEntry.kidsnumber.value}
            onChange={handleChangeEntry}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>

        {/* Describe Yourself*/}
        <div style={{ margin: "2%" }}>
          <TextField
            multiline
            rows={4}
            name="describeYourself"
            placeholder="Briefly Describe Yourself?"
            label="Describe Yourself"
            className={"form-control"}
            variant="outlined"
            value={showEntry.describeYourself.value}
            onChange={handleChangeEntry}
            error={showEntry.describeYourself.error}
            InputProps={{
              readOnly: true,
            }}
          ></TextField>
        </div>

        {/* What is your deal breaker?*/}
        <div style={{ margin: "2%" }}>
          <TextField
            multiline
            rows={4}
            name="dealbreakers"
            label="What is your deal breaker?"
            className={"form-control"}
            variant="outlined"
            value={showEntry.dealbreakers.value}
            onChange={handleChangeEntry}
            InputProps={{
              readOnly: true,
            }}
          ></TextField>
        </div>
      </div>

      <div>
        <h1>PIC/VIDEO</h1>
        {/*Media Uploads*/}
        <CRow>
          <CCol>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Picture</Form.Label>
              <img
                style={{ padding: "20px" }}
                id={"showPic"}
                src={showPic}
                className="img-fluid rounded mx-auto d-block"
                alt=""
              />
            </Form.Group>
          </CCol>

          <CCol>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Picture</Form.Label>
              <img
                style={{ padding: "20px" }}
                id={"showPic2"}
                src={showPic2}
                className="img-fluid rounded mx-auto d-block"
                alt=""
              />
            </Form.Group>
          </CCol>
          <CCol>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Picture</Form.Label>
              <img
                style={{ padding: "20px" }}
                id={"showPic3"}
                src={showPic3}
                className="img-fluid rounded mx-auto d-block"
                alt=""
              />
            </Form.Group>
          </CCol>
          <CCol>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Video</Form.Label>
              <video
                style={{ padding: "20px" }}
                src={showVid}
                className="img-fluid rounded mx-auto d-block"
                alt=""
                controls
              />
            </Form.Group>
          </CCol>
        </CRow>
      </div>

      <div>
        <CContainer className="text-center">
          <FormGroup></FormGroup>
        </CContainer>
      </div>
    </div>
  ));

  function handleStatusChange(e) {
    handleChangeEntryNonValidate(e);
  }

  function updateRecord() {
    updateStatus();
  }

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text="Busy Processing, Please Wait..."
    >
      <Container>
        {error && <Alert varimaant="danger">{error}</Alert>}
        <DeleteDialog show={show} cellNumber={cellNumber} open={openClose} />
        <fieldset disabled={false}>
          <br />
          <form
            id="enterDMF"
            name="theform"
            onSubmit={handleSubmitNoEmail}
            // disabled
            component="form"
            className="testbox text-center"
            style={{ padding: 20 }}
            autoComplete="off"
            noValidate
          >
            <ComponentToPrint ref={formRef}></ComponentToPrint>
            <FormControl sx={{ m: 2, width: "45%" }}>
              <InputLabel id="statusSelect">Status</InputLabel>
              <Select
                variant="outlined"
                labelId="statusSelect"
                label="Status"
                name="status"
                value={showEntry.status}
                onChange={handleStatusChange}
              >
                <MenuItem value="New">New</MenuItem>
                <MenuItem value="PendingReview">Pending Review</MenuItem>
                <MenuItem value="Accepted">Accepted</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
                <MenuItem value="ScheduledAudition">
                  Scheduled Audition
                </MenuItem>
                <MenuItem value="Passed">Passed</MenuItem>
              </Select>
            </FormControl>
            <div>
              <CButton
                style={{ width: "15%", margin: 3 }}
                color="danger"
                variant="outline"
                onClick={handleToList}
              >
                <CIcon icon={icon.cilList} size="xxl" /> Back To List
              </CButton>
              <CButton
                style={{ width: "15%", margin: 3 }}
                color="danger"
                variant="outline"
                onClick={() => {
                  setActive(true);
                  exportComponentAsJPEG(formRef, {
                    fileName: profile.name.value + " " + profile.surname.value,
                  }).then(() => {
                    setActive(false);
                  });
                }}
              >
                <CIcon icon={icon.cilSave} size="xxl" /> Export
              </CButton>

              <CButton
                style={{ width: "15%", margin: 3 }}
                color="success"
                variant="outline"
                onClick={updateRecord}
                type="submit"
              >
                <CIcon icon={icon.cilSend} size="xxl" /> Update
              </CButton>
            </div>
          </form>
        </fieldset>
      </Container>
    </LoadingOverlay>
  );
}
