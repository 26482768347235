import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  ButtonGroup,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";

import { projectFirestore, timestamp } from "../firebase/config";
import { ColorBox, DateBox, NumberBox } from "devextreme-react";
import { Box, Button, InputLabel } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { QRCode } from "react-qrcode-logo";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { useAppContext } from "../hooks/useAppContext";

export function Settings() {
  const form = useRef();
  const [exists, setExists] = useState("false");
  const [showName, setShowName] = useState("");
  const [showDescription, setShowDescription] = useState("");
  const [showCode, setShowCode] = useState("");
  const [showAppURL, setShowAppURL] = useState("");
  const [showEmail, setShowEmail] = useState("");
  const [instagramPage, setInstagramPage] = useState("");
  const [facebookPage, setFacebookPage] = useState("");
  const [twitterPage, setTwitterPage] = useState("");
  const [linkedInPage, setLinkedInPage] = useState("");
  const [serviceID, setServiceID] = useState("");
  const [templateID, setTemplateID] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [useMain, setUseMain] = useState("");

  const [season, setSeason] = useState("");
  const [openDate, setOpenDate] = useState("");
  const [closeDate, setCloseDate] = useState("");
  const [color, setColor] = useState("");
  const [showLogo, setShowLogo] = useState("");
  const [showsIcon, setShowIcon] = useState("");
  const [clientLogo, setClientLogo] = useState("");
  const navigate = useNavigate();
  const { show, setObject } = useAppContext();

  function handleSubmit(e) {
    console.log("Submitting Settings");
    console.log(exists);
    e.preventDefault();

    const showDetails = {
      showName,
      showDescription,
      showEmail,
      showAppURL,
      season,
      openDate,
      closeDate,
      color,
      showLogo,
      showsIcon,
      publicKey,
      templateID,
      serviceID,
      showCode,
      useMain,
      twitterPage,
      linkedInPage,
      facebookPage,
      instagramPage,
    };

    console.log(showDetails);
    const CreatedAt = timestamp.fromDate(new Date());
    //addDocument(entry);
    try {
      if (exists === "false") {
        console.log("Creating New");

        console.log(showDetails);

        projectFirestore
          .collection("Settings")
          .doc(show)
          .set({ ...showDetails, CreatedAt });
        navigate("/");
        // alert("Thank you for your entry");
      } else {
        console.log("Updating");
        projectFirestore.collection("Settings").doc(show).update(showDetails);
        navigate("/");
        // alert("Thank you for your entry");
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  useEffect(() => {
    document.body.style.background = color;

    //Profile
    projectFirestore
      .collection("Settings")
      .doc(show)
      .get()
      .then((results) => {
        if (results.exists) {
          setExists("true");
          console.log({ results });
          const settings = results.data();
          console.log({ settings });

          setTemplateID(settings.templateID);
          setServiceID(settings.serviceID);
          setPublicKey(settings.publicKey);
          setShowDescription(settings.showDescription);
          setShowName(settings.showName);
          setShowCode(settings.showCode);
          setSeason(settings.season);
          setOpenDate(settings.openDate);
          setCloseDate(settings.closeDate);
          setColor(settings.color);
          setShowLogo(settings.showLogo);
          setShowIcon(settings.showsIcon);
          setClientLogo(settings.clientLogo);
          setShowEmail(settings.showEmail);
          setShowAppURL(settings.showAppURL);
          setInstagramPage(settings.instagramPage);
          setFacebookPage(settings.facebookPage);
          setLinkedInPage(settings.linkedInPage);
          setTwitterPage(settings.twitterPage);
          setUseMain(settings.useMain);
        } else {
          console.log("No matches");
        }
      });
  }, []);

  return (
    <div className="container-fluid">
      <img
        //style={{ padding: "20px" }}
        style={{ padding: "50px", width: "500px" }}
        // src={"../images/" + show + ".png"}
        src={setObject.showLogo}
        className="img-fluid rounded mx-auto d-block"
        alt="EntryWiz"
      />
      <Container style={{ padding: "20px" }}>
        <Form
          ref={form}
          id="enterDMF"
          name="theSettings"
          onSubmit={handleSubmit}
        >
          <Form.Group>
            <Form.Label>Show Name</Form.Label>
            <Form.Control
              value={showName}
              onChange={(e) => setShowName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Show Code</Form.Label>
            <Form.Control
              value={showCode}
              onChange={(e) => setShowCode(e.target.value)}
              placeholder={"The code for the show and package used"}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Use Main</Form.Label>
            <Form.Check
              checked={useMain}
              onChange={(e) => setUseMain(e.target.checked)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Show Description</Form.Label>
            <Form.Control
              value={showDescription}
              as="textarea"
              rows={6}
              onChange={(e) => setShowDescription(e.target.value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Season</Form.Label>
            <NumberBox
              value={season}
              defaultValue={1}
              showSpinButtons={true}
              onValueChanged={(e) => setSeason(e.value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Entries Open From/To</Form.Label>
            <Row>
              <Col>
                <input
                  value={openDate}
                  variant="outlined"
                  className="form-control"
                  type="date"
                  onChange={(e) => setOpenDate(e.target.value)}
                />
              </Col>
              <Col>
                <input
                  value={closeDate}
                  variant="outlined"
                  className="form-control"
                  type="date"
                  onChange={(e) => setCloseDate(e.target.value)}
                />
              </Col>
            </Row>

            <Form.Group>
              <Form.Label>Show Colour</Form.Label>
              <ColorBox
                value={color}
                onValueChanged={(e) => {
                  setColor(e.value);
                  document.body.style.background = color;
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Client Logo</Form.Label>
              <Form.Control
                type="url"
                value={clientLogo}
                onChange={(e) => setClientLogo(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Show Logo</Form.Label>
              <Form.Control
                type="url"
                value={showLogo}
                onChange={(e) => setShowLogo(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Shows Icon</Form.Label>
              <Form.Control
                type="url"
                value={showsIcon}
                onChange={(e) => setShowIcon(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Row>
                <Col>
                  <Form.Label>App Url</Form.Label>
                  <Form.Control
                    type="url"
                    value={showAppURL}
                    onChange={(e) => setShowAppURL(e.target.value)}
                    readOnly={true}
                  />
                </Col>
                <Col>
                  <QRCode style={{ margin: "10px" }} value={showAppURL} />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Form.Label>Show Mailbox</Form.Label>
              <Form.Control
                value={showEmail}
                type="email"
                onChange={(e) => setShowEmail(e.target.value)}
              />
            </Form.Group>
          </Form.Group>

          <Form.Group>
            <Row>
              <Col>
                <Form.Label>Service ID</Form.Label>
                <Form.Control
                  value={serviceID}
                  onChange={(e) => setServiceID(e.target.value)}
                  readOnly={true}
                />
              </Col>
              <Col>
                <Form.Label>Template ID</Form.Label>
                <Form.Control
                  value={templateID}
                  onChange={(e) => setTemplateID(e.target.value)}
                  readOnly={true}
                />
              </Col>
              <Col>
                <Form.Label>Puplic Key</Form.Label>
                <Form.Control
                  value={publicKey}
                  onChange={(e) => setPublicKey(e.target.value)}
                  readOnly={true}
                />
              </Col>
            </Row>
          </Form.Group>

          <Form.Group>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Facebook</Form.Label>
                  <Form.Control
                    type="url"
                    value={facebookPage}
                    onChange={(e) => setFacebookPage(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Twitter</Form.Label>
                  <Form.Control
                    type="url"
                    value={twitterPage}
                    onChange={(e) => setTwitterPage(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Instagram</Form.Label>
                  <Form.Control
                    type="url"
                    value={instagramPage}
                    onChange={(e) => setInstagramPage(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>LinkedIn</Form.Label>
                  <Form.Control
                    type="url"
                    value={linkedInPage}
                    onChange={(e) => setLinkedInPage(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="text-center">
            <br />
            <Button
              style={{ margin: "15px", padding: "10px", width: "10%" }}
              variant="outlined"
              onClick={() => {
                navigate("/");
              }}
            >
              Cancel
            </Button>

            <Button
              style={{ margin: "15px", padding: "10px", width: "10%" }}
              variant="contained"
              value="Submit"
              id="submit"
              type="submit"
            >
              Save
            </Button>
          </Form.Group>
        </Form>
      </Container>
    </div>
  );
}
