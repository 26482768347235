import React, { useEffect, useRef, useState } from "react";
import "../../stylesheets/style.css";
import "bootstrap/dist/css/bootstrap.css";
import { useAuthContext } from "../../hooks/useAuthContext";

import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import "react-rating";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
} from "react-component-export-image";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputAdornment,
  FormGroup,
  FormControl as FormControlM,
} from "@mui/material";
import { Facebook, Instagram, Twitter } from "react-bootstrap-icons";
import { entrantProfile, opwEntry } from "./models";

import DeleteDialog from "../alerts";
import { useAddressAuto } from "../../hooks/useAddressAutoComplete";

import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";
import { CButton, CContainer } from "@coreui/react";

import { useEntryWizFunctions } from "../../hooks/useEntryWizFunctions";
import JsPDF from "jspdf";

export function EntryForm() {
  const [openClose, setOpenClose] = useState(false);
  const [error, setError] = useState("");
  const { user } = useAuthContext();

  const {
    fetchEntry,
    fetchProfile,
    handleSubmit,
    handleChangeEntry,
    handleChangeProfile,
    showEntry,
    profile,
    isActive,
    show,
    cellNumber,
    showPic,
    showVid,
    setActive,
    addressSearch,
    setAddressSearch,
    sigCanvasRef,
    handleStatusChange,
    handleToList,
    genPDF,
    PrintForm,
  } = useEntryWizFunctions(entrantProfile, opwEntry);

  useAddressAuto();

  const formRef = useRef();

  useEffect(() => {
    setActive(true);
    fetchProfile(cellNumber);
    fetchEntry(show, cellNumber);
  }, []);

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <div ref={ref}>
      <div>
        <h1>Personal Profile</h1>
        {/* Names */}
        <Row style={{ margin: "2%" }}>
          <Col>
            <TextField
              id="ename"
              label="Name"
              name="name"
              variant="outlined"
              value={profile.name.value}
              onChange={handleChangeProfile}
              className={"form-control"}
            />
          </Col>
          <Col>
            <TextField
              id="esurname"
              label="Surname"
              name="surname"
              variant="outlined"
              value={profile.surname.value}
              onChange={handleChangeProfile}
              className={"form-control"}
            />
          </Col>
        </Row>
        {/*Contacts*/}
        <Row style={{ margin: "2%" }}>
          <Col>
            <TextField
              id="ecellphone"
              label="Cellphone"
              variant="outlined"
              className={"form-control"}
              value={user.phoneNumber}
              onChange={handleChangeProfile}
              disabled
            />
          </Col>
          <Col>
            <TextField
              id="email"
              label="E-Mail"
              variant="outlined"
              name="email"
              className={"form-control"}
              value={profile.email.value}
              onChange={handleChangeProfile}
            />
          </Col>
        </Row>
        {/*Age and gender*/}
        <Row style={{ margin: "2%" }}>
          <Col>
            <TextField
              id="age"
              // sx={{ m: 2, width: "45%" }}
              type={"number"}
              min={18}
              name="age"
              label="Age"
              placeholder="Enter your age"
              variant="outlined"
              className={"form-control"}
              value={profile.age.value}
              onChange={handleChangeProfile}
            />
          </Col>
          <Col>
            <FormControlM className={"form-control"}>
              <InputLabel id="genderSelect">Gender</InputLabel>
              <Select
                className={"form-control"}
                labelId="genderSelect"
                label="Gender"
                name="gender"
                value={profile.gender.value}
                onChange={handleChangeProfile}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControlM>
          </Col>
        </Row>

        {/*Address*/}
        <Row style={{ margin: "2%" }}>
          <Col>
            <FormControlM variant="outlined" className={"form-control"}>
              <TextField
                label="Full Address"
                variant="outlined"
                className={"form-control"}
                value={profile.fullAddress}
                disabled
                // onChange={(e) => {
                //   setAddressSearch(e.target.value);
                // }}
              />
            </FormControlM>
          </Col>
        </Row>
      </div>

      <div>
        <h1>Entry</h1>
        <Row style={{ margin: "2%" }}>
          <Col>
            <TextField
              id="bname"
              label="Bride Name"
              name="bridename"
              variant="outlined"
              className={"form-control"}
              value={showEntry.bridename.value}
              onChange={handleChangeEntry}
            />
          </Col>
          <Col>
            <TextField
              id="eprovince"
              label="Groom Name"
              name="groomname"
              variant="outlined"
              className={"form-control"}
              value={showEntry.groomname.value}
              onChange={handleChangeEntry}
            />
          </Col>
        </Row>
        <Row style={{ margin: "2%" }}>
          <Col>
            <TextField
              id="bage"
              // sx={{ m: 2, width: "45%" }}
              type={"number"}
              min={18}
              name="brideage"
              label="Bride Age"
              placeholder="Enter Bride age"
              variant="outlined"
              className={"form-control"}
              value={showEntry.brideage.value}
              onChange={handleChangeEntry}
            />
          </Col>
          <Col>
            <TextField
              id="gage"
              // sx={{ m: 2, width: "45%" }}
              type={"number"}
              min={18}
              name="groomage"
              label="Groom Age"
              placeholder="Enter Groom age"
              variant="outlined"
              className={"form-control"}
              value={showEntry.groomage.value}
              onChange={handleChangeEntry}
            />
          </Col>
        </Row>
        <Row style={{ margin: "2%" }}>
          <Col>
            <TextField
              id="bcontact"
              label="Bride Contact Number"
              name="bridecontact"
              variant="outlined"
              className={"form-control"}
              value={showEntry.bridecontact.value}
              onChange={handleChangeEntry}
            />
          </Col>
          <Col>
            <TextField
              id="gcontact"
              label="Groom Contact Number"
              name="groomcontact"
              variant="outlined"
              className={"form-control"}
              value={showEntry.groomcontact.value}
              onChange={handleChangeEntry}
            />
          </Col>
        </Row>
        {/*  home language */}
        <Row style={{ margin: "2%" }}>
          <Col>
            <TextField
              id="Blang"
              label="Bride Home Language"
              name="bridehomelanguage"
              variant="outlined"
              className={"form-control"}
              value={showEntry.bridehomelanguage.value}
              onChange={handleChangeEntry}
            />
          </Col>
          <Col>
            <TextField
              id="gland"
              label="Groom Home Language"
              name="groomhomelanguage"
              variant="outlined"
              className={"form-control"}
              value={showEntry.groomhomelanguage.value}
              onChange={handleChangeEntry}
            />
          </Col>
        </Row>
        {/*Descriptions of bride and couple*/}
        <Row style={{ margin: "2%" }}>
          <Col>
            <TextField
              id="bDesc"
              label="Description Of The Bride"
              name="describeBride"
              variant="outlined"
              placeholder="Please provide a brief description of the bride"
              value={showEntry.describeBride.value}
              error={showEntry.describeBride.error}
              className={"form-control"}
              onChange={handleChangeEntry}
              multiline
              rows={4}
            />
          </Col>
          <Col>
            <TextField
              label="Description Of The Groom"
              id="gDesc"
              name="describeGroom"
              variant="outlined"
              placeholder="Please provide a brief description of the groom"
              value={showEntry.describeGroom.value}
              error={showEntry.describeGroom.error}
              className={"form-control"}
              onChange={handleChangeEntry}
              multiline
              rows={4}
            />
          </Col>
        </Row>
        {/*Theme Colours and Wedding Type*/}
        <Row style={{ margin: "2%" }}>
          <Col>
            <TextField
              id="tcolors"
              label="Theme Colours"
              name="themecolours"
              variant="outlined"
              className={"form-control"}
              value={showEntry.themecolours.value}
              onChange={handleChangeEntry}
            />
          </Col>
          <Col>
            <FormControlM className={"form-control"}>
              <InputLabel id="edateSelect">Wedding Type</InputLabel>
              <Select
                className={"form-control"}
                labelId="wtselect"
                label="Type of wedding "
                name="weddingtype"
                value={showEntry.weddingtype.value}
                defaultValue={"Other"}
                onChange={handleChangeEntry}
              >
                <MenuItem value="Traditional">Traditional</MenuItem>
                <MenuItem value="White">White</MenuItem>
                <MenuItem value="Traditional & White">
                  Traditional & White
                </MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControlM>
          </Col>
        </Row>
        {/* Wedding date & vanue */}
        <Row style={{ margin: "2%" }}>
          <Col>
            <InputLabel id="Wedding Date">Wedding Date</InputLabel>
            <input
              name="weddingdate"
              value={showEntry.weddingdate.value}
              variant="outlined"
              className="form-control"
              type="date"
              onChange={handleChangeEntry}
            />
          </Col>
          <Col>
            <TextField
              id="vanue"
              label="Vanue"
              name="weddingvanue"
              variant="outlined"
              className={"form-control"}
              value={showEntry.weddingvanue.value}
              onChange={handleChangeEntry}
            />
          </Col>
        </Row>

        <Row style={{ margin: "2%" }}>
          <TextField
            multiline
            rows={4}
            label="Your wish list?"
            name="wishlist"
            className={"form-control"}
            variant="outlined"
            value={showEntry.wishlist.value}
            onChange={handleChangeEntry}
          ></TextField>
        </Row>
        <Row style={{ margin: "2%" }}>
          <TextField
            name="meet"
            multiline
            rows={5}
            placeholder="How did you meet? We would like the whole story, how you met, when you met, where you met, what was going on in your minds when it all happened, the WHOLE story."
            label="How did you meet??"
            className={"form-control"}
            variant="outlined"
            value={showEntry.meet.value}
            onChange={handleChangeEntry}
          ></TextField>
        </Row>
        <Row style={{ margin: "2%" }}>
          <TextField
            name="challenges"
            multiline
            rows={5}
            label="Challenges"
            placeholder="What challenges have you guys had to go through in your relationship?"
            className={"form-control"}
            variant="outlined"
            value={showEntry.challenges.value}
            onChange={handleChangeEntry}
          ></TextField>
        </Row>
        {/* Proposal & date */}
        <Row style={{ margin: "2%" }}>
          <Col>
            <InputLabel id="shoolingSelect">
              When did he ask you to marry him?
            </InputLabel>
            <input
              name="proposaldate"
              value={showEntry.proposaldate.value}
              className="form-control"
              type="date"
              onChange={handleChangeEntry}
            />
          </Col>

          <Col>
            <TextField
              name="proposal"
              multiline
              rows={4}
              label="The Proposal"
              placeholder="Tell us what happened at that moment? (the proposal)"
              className={"form-control"}
              variant="outlined"
              value={showEntry.proposal.value}
              onChange={handleChangeEntry}
            ></TextField>
          </Col>
        </Row>
        {/*Why that day? */}
        <Row style={{ margin: "2%" }}>
          <Col>
            <TextField
              id="day"
              label="What is behind that day?"
              name="day"
              variant="outlined"
              placeholder="What is behind choosing the day you getting married on?"
              className={"form-control"}
              value={showEntry.day.value}
              onChange={handleChangeEntry}
            />
          </Col>
        </Row>
        {/*//Why OPW*/}
        <Row style={{ margin: "2%" }}>
          <Col>
            <TextField
              name="whyopw"
              id="whyopw"
              label="Why do want Our Perfect Wedding to come?"
              variant="outlined"
              placeholder="Why do you want Our Perfect Wedding to come to your wedding?"
              className={"form-control"}
              value={showEntry.whyopw.value}
              onChange={handleChangeEntry}
            />
          </Col>
        </Row>
        {/*How many maids and men*/}
        <Row style={{ margin: "2%" }}>
          <Col>
            <TextField
              id="gmen"
              // sx={{ m: 2, width: "45%" }}
              type={"number"}
              name="groomsmen"
              label="Groomsmen"
              placeholder="How many groomsmen do you have"
              variant="outlined"
              className={"form-control"}
              value={showEntry.groomsmen.value}
              onChange={handleChangeEntry}
            />
          </Col>
          <Col>
            <TextField
              id="gchoices"
              label="Groomsmen choices?"
              name="choicesgroomsmen"
              placeholder="How did you make your choices?"
              variant="outlined"
              className={"form-control"}
              value={showEntry.choicesgroomsmen.value}
              onChange={handleChangeEntry}
            />
          </Col>
        </Row>
        {/*How many maids and men*/}
        <Row style={{ margin: "2%" }}>
          <Col>
            <TextField
              id="maids"
              // sx={{ m: 2, width: "45%" }}
              type={"number"}
              name="bridesmaids"
              label="Bridesmaids"
              placeholder="How many bridesmaids do you have"
              variant="outlined"
              className={"form-control"}
              value={showEntry.bridesmaids.value}
              onChange={handleChangeEntry}
            />
          </Col>
          <Col>
            {" "}
            <TextField
              id="maidschoices"
              label="Bridesmaids choices?"
              name="choicesbridesmaids"
              placeholder="How did you make your choices?"
              variant="outlined"
              className={"form-control"}
              value={showEntry.choicesbridesmaids.value}
              onChange={handleChangeEntry}
            />
          </Col>
        </Row>
      </div>

      <div>
        <h1>PIC/VIDEO</h1>
        {/*Media Uploads*/}
        <Row style={{ margin: "2%" }}>
          <Col>
            <img
              style={{ padding: "20px" }}
              id={"showPic"}
              src={showPic}
              className="img-fluid rounded mx-auto d-block"
              alt=""
            />
          </Col>
        </Row>
        <Row style={{ margin: "2%" }}>
          <Col>
            <video
              style={{ padding: "20px" }}
              src={showVid}
              id={"vidShow"}
              className="img-fluid rounded mx-auto d-block"
              controls
            />
          </Col>
        </Row>
      </div>

      <div>
        <CContainer className="text-center">
          <FormGroup></FormGroup>
        </CContainer>
      </div>
    </div>
  ));

  class PrintView extends React.Component {
    render() {
      return (
        <div id="formPrint" ref={formRef}>
          <div>
            <h1>Personal Profile</h1>
            {/* Names */}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="ename"
                  label="Name"
                  name="name"
                  variant="outlined"
                  value={profile.name.value}
                  onChange={handleChangeProfile}
                  className={"form-control"}
                />
              </Col>
              <Col>
                <TextField
                  id="esurname"
                  label="Surname"
                  name="surname"
                  variant="outlined"
                  value={profile.surname.value}
                  onChange={handleChangeProfile}
                  className={"form-control"}
                />
              </Col>
            </Row>
            {/*Contacts*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="ecellphone"
                  label="Cellphone"
                  variant="outlined"
                  className={"form-control"}
                  value={user.phoneNumber}
                  onChange={handleChangeProfile}
                  disabled
                />
              </Col>
              <Col>
                <TextField
                  id="email"
                  label="E-Mail"
                  variant="outlined"
                  name="email"
                  className={"form-control"}
                  value={profile.email.value}
                  onChange={handleChangeProfile}
                />
              </Col>
            </Row>
            {/*Age and gender*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="age"
                  // sx={{ m: 2, width: "45%" }}
                  type={"number"}
                  min={18}
                  name="age"
                  label="Age"
                  placeholder="Enter your age"
                  variant="outlined"
                  className={"form-control"}
                  value={profile.age.value}
                  onChange={handleChangeProfile}
                />
              </Col>
              <Col>
                <FormControlM className={"form-control"}>
                  <InputLabel id="genderSelect">Gender</InputLabel>
                  <Select
                    className={"form-control"}
                    labelId="genderSelect"
                    label="Gender"
                    name="gender"
                    value={profile.gender.value}
                    onChange={handleChangeProfile}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControlM>
              </Col>
            </Row>

            {/*Address*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <FormControlM variant="outlined" className={"form-control"}>
                  <TextField
                    label="Full Address"
                    variant="outlined"
                    className={"form-control"}
                    value={profile.fullAddress}
                    disabled
                    // onChange={(e) => {
                    //   setAddressSearch(e.target.value);
                    // }}
                  />
                </FormControlM>
              </Col>
            </Row>
          </div>

          <div>
            <h1>Entry</h1>
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="bname"
                  label="Bride Name"
                  name="bridename"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.bridename.value}
                  onChange={handleChangeEntry}
                />
              </Col>
              <Col>
                <TextField
                  id="eprovince"
                  label="Groom Name"
                  name="groomname"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomname.value}
                  onChange={handleChangeEntry}
                />
              </Col>
            </Row>
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="bage"
                  // sx={{ m: 2, width: "45%" }}
                  type={"number"}
                  min={18}
                  name="brideage"
                  label="Bride Age"
                  placeholder="Enter Bride age"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.brideage.value}
                  onChange={handleChangeEntry}
                />
              </Col>
              <Col>
                <TextField
                  id="gage"
                  // sx={{ m: 2, width: "45%" }}
                  type={"number"}
                  min={18}
                  name="groomage"
                  label="Groom Age"
                  placeholder="Enter Groom age"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomage.value}
                  onChange={handleChangeEntry}
                />
              </Col>
            </Row>
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="bcontact"
                  label="Bride Contact Number"
                  name="bridecontact"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.bridecontact.value}
                  onChange={handleChangeEntry}
                />
              </Col>
              <Col>
                <TextField
                  id="gcontact"
                  label="Groom Contact Number"
                  name="groomcontact"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomcontact.value}
                  onChange={handleChangeEntry}
                />
              </Col>
            </Row>
            {/*  home language */}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="Blang"
                  label="Bride Home Language"
                  name="bridehomelanguage"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.bridehomelanguage.value}
                  onChange={handleChangeEntry}
                />
              </Col>
              <Col>
                <TextField
                  id="gland"
                  label="Groom Home Language"
                  name="groomhomelanguage"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomhomelanguage.value}
                  onChange={handleChangeEntry}
                />
              </Col>
            </Row>
            {/*Descriptions of bride and couple*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="bDesc"
                  label="Description Of The Bride"
                  name="describeBride"
                  variant="outlined"
                  placeholder="Please provide a brief description of the bride"
                  value={showEntry.describeBride.value}
                  error={showEntry.describeBride.error}
                  className={"form-control"}
                  onChange={handleChangeEntry}
                  multiline
                  rows={4}
                />
              </Col>
              <Col>
                <TextField
                  label="Description Of The Groom"
                  id="gDesc"
                  name="describeGroom"
                  variant="outlined"
                  placeholder="Please provide a brief description of the groom"
                  value={showEntry.describeGroom.value}
                  error={showEntry.describeGroom.error}
                  className={"form-control"}
                  onChange={handleChangeEntry}
                  multiline
                  rows={4}
                />
              </Col>
            </Row>
            {/*Theme Colours and Wedding Type*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="tcolors"
                  label="Theme Colours"
                  name="themecolours"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.themecolours.value}
                  onChange={handleChangeEntry}
                />
              </Col>
              <Col>
                <FormControlM className={"form-control"}>
                  <InputLabel id="edateSelect">Wedding Type</InputLabel>
                  <Select
                    className={"form-control"}
                    labelId="wtselect"
                    label="Type of wedding "
                    name="weddingtype"
                    value={showEntry.weddingtype.value}
                    defaultValue={"Other"}
                    onChange={handleChangeEntry}
                  >
                    <MenuItem value="Traditional">Traditional</MenuItem>
                    <MenuItem value="White">White</MenuItem>
                    <MenuItem value="Traditional & White">
                      Traditional & White
                    </MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControlM>
              </Col>
            </Row>
            {/* Wedding date & vanue */}
            <Row style={{ margin: "2%" }}>
              <Col>
                <InputLabel id="Wedding Date">Wedding Date</InputLabel>
                <input
                  name="weddingdate"
                  value={showEntry.weddingdate.value}
                  variant="outlined"
                  className="form-control"
                  type="date"
                  onChange={handleChangeEntry}
                />
              </Col>
              <Col>
                <TextField
                  id="vanue"
                  label="Vanue"
                  name="weddingvanue"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.weddingvanue.value}
                  onChange={handleChangeEntry}
                />
              </Col>
            </Row>

            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="Your wish list?"
                name="wishlist"
                className={"form-control"}
                variant="outlined"
                value={showEntry.wishlist.value}
                onChange={handleChangeEntry}
              ></TextField>
            </Row>
            <Row style={{ margin: "2%" }}>
              <TextField
                name="meet"
                multiline
                rows={5}
                placeholder="How did you meet? We would like the whole story, how you met, when you met, where you met, what was going on in your minds when it all happened, the WHOLE story."
                label="How did you meet??"
                className={"form-control"}
                variant="outlined"
                value={showEntry.meet.value}
                onChange={handleChangeEntry}
              ></TextField>
            </Row>
            <Row style={{ margin: "2%" }}>
              <TextField
                name="challenges"
                multiline
                rows={5}
                label="Challenges"
                placeholder="What challenges have you guys had to go through in your relationship?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.challenges.value}
                onChange={handleChangeEntry}
              ></TextField>
            </Row>
            {/* Proposal & date */}
            <Row style={{ margin: "2%" }}>
              <Col>
                <InputLabel id="shoolingSelect">
                  When did he ask you to marry him?
                </InputLabel>
                <input
                  name="proposaldate"
                  value={showEntry.proposaldate.value}
                  className="form-control"
                  type="date"
                  onChange={handleChangeEntry}
                />
              </Col>

              <Col>
                <TextField
                  name="proposal"
                  multiline
                  rows={4}
                  label="The Proposal"
                  placeholder="Tell us what happened at that moment? (the proposal)"
                  className={"form-control"}
                  variant="outlined"
                  value={showEntry.proposal.value}
                  onChange={handleChangeEntry}
                ></TextField>
              </Col>
            </Row>
            {/*Why that day? */}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="day"
                  label="What is behind that day?"
                  name="day"
                  variant="outlined"
                  placeholder="What is behind choosing the day you getting married on?"
                  className={"form-control"}
                  value={showEntry.day.value}
                  onChange={handleChangeEntry}
                />
              </Col>
            </Row>
            {/*//Why OPW*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  name="whyopw"
                  id="whyopw"
                  label="Why do want Our Perfect Wedding to come?"
                  variant="outlined"
                  placeholder="Why do you want Our Perfect Wedding to come to your wedding?"
                  className={"form-control"}
                  value={showEntry.whyopw.value}
                  onChange={handleChangeEntry}
                />
              </Col>
            </Row>
            {/*How many maids and men*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="gmen"
                  // sx={{ m: 2, width: "45%" }}
                  type={"number"}
                  name="groomsmen"
                  label="Groomsmen"
                  placeholder="How many groomsmen do you have"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomsmen.value}
                  onChange={handleChangeEntry}
                />
              </Col>
              <Col>
                <TextField
                  id="gchoices"
                  label="Groomsmen choices?"
                  name="choicesgroomsmen"
                  placeholder="How did you make your choices?"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.choicesgroomsmen.value}
                  onChange={handleChangeEntry}
                />
              </Col>
            </Row>
            {/*How many maids and men*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="maids"
                  // sx={{ m: 2, width: "45%" }}
                  type={"number"}
                  name="bridesmaids"
                  label="Bridesmaids"
                  placeholder="How many bridesmaids do you have"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.bridesmaids.value}
                  onChange={handleChangeEntry}
                />
              </Col>
              <Col>
                {" "}
                <TextField
                  id="maidschoices"
                  label="Bridesmaids choices?"
                  name="choicesbridesmaids"
                  placeholder="How did you make your choices?"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.choicesbridesmaids.value}
                  onChange={handleChangeEntry}
                />
              </Col>
            </Row>
          </div>

          <div>
            <h1>PIC/VIDEO</h1>
            {/*Media Uploads*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <img
                  style={{ padding: "20px" }}
                  id={"showPic"}
                  src={showPic}
                  className="img-fluid rounded mx-auto d-block"
                  alt=""
                />
              </Col>
            </Row>
            <Row style={{ margin: "2%" }}>
              <Col>
                <video
                  style={{ padding: "20px" }}
                  src={showVid}
                  id={"vidShow"}
                  className="img-fluid rounded mx-auto d-block"
                  controls
                />
              </Col>
            </Row>
          </div>

          <div>
            <CContainer className="text-center">
              <FormGroup></FormGroup>
            </CContainer>
          </div>
        </div>
      );
    }
  }

  const generatePDF = () => {
    const report = new JsPDF("portrait", "pt", "a4");
    report.html(document.querySelector("#formPrint")).then(() => {
      report.save("report.pdf");
    });
  };

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text="Busy Processing, Please Wait..."
    >
      <Container>
        {error && <Alert varimaant="danger">{error}</Alert>}
        <DeleteDialog show={show} cellNumber={cellNumber} open={openClose} />
        <fieldset disabled={false}>
          <br />
          <form
            id="enterDMF"
            name="theform"
            onSubmit={handleSubmit}
            // disabled
            component="form"
            className="testbox text-center"
            style={{ padding: 20 }}
            autoComplete="off"
            noValidate
          >
            <PrintForm profile={profile} entry={showEntry} formRef={formRef} />
            <FormControl sx={{ m: 2, width: "45%" }}>
              <InputLabel id="statusSelect">Status</InputLabel>
              <Select
                variant="outlined"
                labelId="statusSelect"
                label="Status"
                name="status"
                value={showEntry.status}
                onChange={handleStatusChange}
              >
                <MenuItem value="New">New</MenuItem>
                <MenuItem value="PendingReview">Pending Review</MenuItem>
                <MenuItem value="Accepted">Accepted</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
                <MenuItem value="ScheduledAudition">
                  Scheduled Audition
                </MenuItem>
                <MenuItem value="Passed">Passed</MenuItem>
              </Select>
            </FormControl>
            <div>
              <CButton
                style={{ width: "15%", margin: 3 }}
                color="danger"
                variant="outline"
                onClick={handleToList}
              >
                <CIcon icon={icon.cilList} size="xxl" /> List
              </CButton>
              <CButton
                style={{ width: "15%", margin: 3 }}
                color="danger"
                variant="outline"
                onClick={() => {
                  genPDF(formRef, profile, showEntry);
                }}
              >
                <CIcon icon={icon.cilSave} size="xxl" /> Export
              </CButton>

              <CButton
                style={{ width: "15%", margin: 3 }}
                color="success"
                variant="outline"
                type="submit"
              >
                <CIcon icon={icon.cilSend} size="xxl" /> Update
              </CButton>
            </div>
          </form>
        </fieldset>
      </Container>
    </LoadingOverlay>
  );
}
