import React, { useRef } from "react";
import { CContainer, CRow, CCol, CButton } from "@coreui/react"; // Replace 'your-ui-library' with the actual library you're using
import { Col, FormGroup, Form } from "react-bootstrap";
import { FormControlLabel, Checkbox } from "@mui/material";

import SignatureCanvas from "react-signature-canvas";
function TermsAndConditionsForm({
  terms,
  handleTermsChange,
  showTermsAndConditions,
}) {
  const sigCanvasRef = useRef(null);

  const clearSignature = () => {
    sigCanvasRef.current.clear();
  };

  return (
    <CContainer className="text-center">
      <FormGroup>
        <Form.Label>Accept terms and conditions to submit</Form.Label>
        {showTermsAndConditions()}
        <div
          className="sigPadContainer"
          style={{ border: "double", margin: 1 }}
        >
          <label>Please sign</label>
          <CRow>
            <CCol>
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  className: "sigCanvas",
                }}
                ref={sigCanvasRef}
              />
            </CCol>
          </CRow>
          <hr />
          <CButton onClick={clearSignature}>Clear</CButton>
        </div>

        <FormControlLabel
          value="start"
          control={
            <Checkbox
              checked={terms.checked}
              onChange={handleTermsChange}
              name="accept"
              size="large"
            />
          }
          label="I consent to Connect Channel (Pty) Ltd processing my personal information (and special personal information) in accordance with the above Privacy Policy"
          labelPlacement="end"
        />
      </FormGroup>
    </CContainer>
  );
}

export default TermsAndConditionsForm;
