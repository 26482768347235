import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { getEntry, getProfile, useFirestore } from "./useFirestore";

import { useAppContext } from "./useAppContext";
import { useAuthContext } from "./useAuthContext";
import { usePlacesWidget } from "react-google-autocomplete";
import { projectStorage } from "../firebase/config";
import { useNavigate } from "react-router-dom";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
} from "react-component-export-image";
import { Table } from "react-bootstrap";

export const useEntryWizFunctions = (
  modelProfile,
  modelEntry,
  excludeValidation
) => {
  let currentProfile = {};
  let currentEntry = {};

  //Contexts
  const { show, cellNumber, setObject } = useAppContext();
  const { user } = useAuthContext();

  const {
    createProfileFirestore,
    createDocumentFirestore,
    createDocumentFirestoreWithOutEmail,
    updateEntryStatus,
  } = useFirestore();
  const [profile, setProfile] = useState(modelProfile);
  const [showEntry, setShowEntry] = useState(modelEntry);
  const [address, setAddress] = useState();
  const [showPic, setEntrantPic] = useState("../images/" + show + ".jpeg");
  const [showPic2, setEntrantPic2] = useState("../images/" + show + ".jpeg");
  const [showPic3, setEntrantPic3] = useState("../images/" + show + ".jpeg");
  const [showVid, setEntrantVid] = useState("../images/" + show + ".jpeg");
  const [addressSearch, setAddressSearch] = useState("");
  const [step, setStep] = useState({
    activeStep: 1,
  });

  const [exists, setExists] = useState("false");
  const [isActive, setActive] = useState(false);
  const [terms, setTerms] = useState({
    checked: false,
  });

  const sigCanvasRef = useRef(null);
  const [signatureURL, setSignatureURL] = useState();

  const navigate = useNavigate();
  const changeAddress = (selected) => {
    setAddressSearch(selected.formatted_address);
    setAddress({
      streetNumber: selected.address_components[0].long_name,
      street: selected.address_components[1].long_name,
      city: selected.address_components[3].long_name,
      province: selected.address_components[5].long_name,
      fullAddress: selected.formatted_address,
    });
  };

  //Addresses
  const { ref } = usePlacesWidget({
    apiKey:
      "AIzaSyCLO072g3rnh3v1dSByrC6aYEPTtUPcyM4&callback=Function.prototype",
    onPlaceSelected: (selected) => {
      console.log(selected);
      setAddressSearch(selected.formatted_address);
      changeAddress(selected);
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "za" },
    },
  });

  const updateAddress = () => {
    setAddressSearch("");
    setProfile({
      ...profile,
      streetNumber: address.streetNumber,
      street: address.street,
      city: address.city,
      province: address.province,
      fullAddress: address.fullAddress,
    });
  };

  const handleNextClick = () => {
    let errorFound = 0;

    if (step.activeStep === 2) {
      const formFields = Object.keys(profile);
      let newFormValues = { ...profile };

      for (let index = 0; index < formFields.length; index++) {
        const currentField = formFields[index];
        const currentValue = profile[currentField].value;
        const skipValidation = excludeValidation.includes(currentField);
        if (currentValue === "" && skipValidation === false) {
          if (errorFound === 0) {
            errorFound = 1;
          }
          newFormValues = {
            ...newFormValues,
            [currentField]: {
              ...newFormValues[currentField],
              error: true,
            },
          };
        }
      }

      setProfile({ ...newFormValues });
      window.scrollTo(0, 0);
    }

    if (step.activeStep === 2 && errorFound === 0) {
      handleSubmitProfileOnly();
    }

    if (step.activeStep === 3) {
      const formFields = Object.keys(showEntry);
      let newEntryValues = { ...showEntry };

      for (let index = 0; index < formFields.length; index++) {
        const currentField = formFields[index];
        const currentValue = showEntry[currentField].value;
        const skipValidation = excludeValidation.includes(currentField);
        if (currentValue === "" && skipValidation === false) {
          if (errorFound === 0) {
            errorFound = 1;
            console.log(currentField);
          }
          newEntryValues = {
            ...newEntryValues,
            [currentField]: {
              ...newEntryValues[currentField],
              error: true,
            },
          };
        }
      }

      setShowEntry({ ...newEntryValues });
      window.scrollTo(0, 0);
    }

    // if (step.activeStep === 2) {
    //   // changeAddress(number, street, city, province);
    //   // updateAddress(address);
    // }

    if (errorFound === 0) {
      setStep((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
      alert(
        "Please check that all required fields (highlighted in red) including weight and height are populated. Put a N/A to any fields that don't apply."
      );
    }
  };

  const handlePrevClick = () => {
    setStep((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
    window.scrollTo(0, 0);
  };

  const uploadImage = async (e, profile) => {
    setActive(true);
    const uploadedFile = e.target.files[0];
    if (!uploadedFile) return;

    const storageRef = projectStorage.ref();

    try {
      await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name.value +
            profile.surname.value +
            cellNumber +
            e.target.id
        )
        .put(uploadedFile)
        .then((response) => {
          response.ref.getDownloadURL().then((link) => {
            // showEntry.pic = link;
            const id = e.target.id;
            if (id === "pic1") {
              showEntry.pic.value = link;
              setEntrantPic(link);
            } else if (id === "pic2") {
              showEntry.pic2.value = link;
              setEntrantPic2(link);
            } else if (id === "pic3") {
              showEntry.pic3.value = link;
              setEntrantPic3(link);
            } else {
              showEntry.pic.value = link;
              setEntrantPic(link);
            }

            console.log(link);
            setActive(false);
          });
        });
      // / alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }
  };

  const uploadVideo = async (event, profile) => {
    setActive(true);

    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    const storageRef = projectStorage.ref();

    try {
      // await storageRef.child(uploadedFile.name).put(uploadedFile);
      await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name.value +
            profile.surname.value +
            cellNumber +
            event.target.id
        )
        .put(uploadedFile)
        .then(async (response) => {
          let downloadURL = await response.ref.getDownloadURL();
          // showEntry.video = downloadURL;
          // await setVidLink(downloadURL);
          showEntry.video.value = downloadURL;
          setEntrantVid(downloadURL);
          console.log(downloadURL);
          setActive(false);
        });
      // alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }

    setActive(false);
  };

  const handleSignature = async () => {
    const URL = sigCanvasRef.current.getTrimmedCanvas().toDataURL("image/png");
    await uploadSignature(URL, profile);
    // sigCanvas.current.fromDataURL(URL);
  };

  const uploadSignature = async (URL, profile) => {
    setActive(true);

    const uploadedFile = URL;
    if (!uploadedFile) return;

    const storageRef = projectStorage.ref();

    try {
      // // await storageRef.child(uploadedFile.name).put(uploadedFile);
      // await storageRef
      //   .child(
      //     "EntriesMedia/" +
      //       show +
      //       "/" +
      //       profile.name +
      //       profile.surname +
      //       profile.cellphone
      //   )
      //   .put(uploadedFile)
      //   .then(async (response) => {
      //     let downloadURL = await response.ref.getDownloadURL();
      //     // showEntry.video = downloadURL;
      //     // setEntrantVid(downloadURL);
      //     console.log(downloadURL);
      //     console.log(URL);
      //     showEntry.signature = URL;
      //     setSignatureURL(URL);
      //     setActive(false);
      //   });
      // alert("Successfully uploaded video!");

      showEntry.signature = URL;
      setSignatureURL(URL);
      setActive(false);
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }

    setActive(false);
  };

  const handleTermsChange = (event) => {
    setTerms({ checked: event.target.checked });
    handleSignature();
  };

  const handleImage = (e) => {
    uploadImage(e, profile);
  };

  const handleVideo = (e) => {
    uploadVideo(e, profile);
  };

  const setCoupleEntry = () => {
    showEntry.coupleEntry = "true";
  };

  const updateStatus = () => {
    // e.preventDefault();
    try {
      updateEntryStatus(profile, showEntry, setObject, user, show);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      createDocumentFirestore(
        e,
        exists,
        profile,
        showEntry,
        setObject,
        user,
        show
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleSubmitProfileOnly = () => {
    //e.preventDefault();
    try {
      createProfileFirestore(
        // e,
        exists,
        profile,
        showEntry,
        setObject,
        user,
        show
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleSubmitNoEmail = (e) => {
    e.preventDefault();
    try {
      createDocumentFirestoreWithOutEmail(
        e,
        exists,
        profile,
        showEntry,
        setObject,
        user,
        show
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleChangeEntry = (e) => {
    const { name, value } = e.target;
    setShowEntry({
      ...showEntry,
      [name]: {
        ...showEntry[name],
        value,
        error: false,
      },
    });
  };

  const handleChangeEntryM = (event) => {
    const { name, value } = event.target;
    setShowEntry({
      ...showEntry,
      [name]: {
        ...showEntry[name],
        // if value is array just return it, otherwise convert to array
        value: Array.isArray(value) ? value : [value],
        error: false,
      },
    });
  };

  // const handleChangeProfileDate = (name, value) => {
  //   let d = value.toDate().toLocaleDateString();
  //   setProfile({
  //     ...profile,
  //     [name]: {
  //       ...profile[name],
  //       value: d,
  //       error: false,
  //     },
  //   });
  // };

  const handleChangeProfileDate = (name, value) => {
    if (value) {
      let d = value.toDate().toDateString();
      setProfile({
        ...profile,
        [name]: {
          ...profile[name],
          value: d,
          error: false,
        },
      });
    } else {
      console.warn(`The value is null for field ${name}`);
    }
  };

  const handleChangeProfile = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: {
        ...profile[name],
        value,
        error: false,
      },
    });
  };

  const handleChangeProfileNonValidate = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: value,
    });
  };

  const handleChangeEntryNonValidate = (e) => {
    const { name, value } = e.target;
    setShowEntry({
      ...showEntry,
      [name]: value,
    });
  };

  function handleStatusChange(e) {
    const { name, value } = e.target;
    setShowEntry({
      ...showEntry,
      status: value,
    });
  }

  const handleToList = () => {
    navigate("/" + show + "/list/" + show);
  };

  const fetchProfile = useCallback(async (userNumber) => {
    currentProfile = await getProfile(userNumber);
    if (currentProfile !== undefined) {
      Object.keys(currentProfile).forEach((key) => {
        profile[key] = currentProfile[key];
      });

      console.log(profile);
      setActive(false);
    } else {
      setActive(false);
    }
  }, []);

  const fetchEntry = async (show, userNumber) => {
    currentEntry = await getEntry(show, userNumber);
    if (currentEntry !== undefined) {
      Object.keys(currentEntry).forEach((key) => {
        showEntry[key] = currentEntry[key];
      });

      if (currentEntry.video.value) {
        showEntry.video.value = currentEntry.video.value;
        setEntrantVid(currentEntry.video.value);
      } else {
        setEntrantVid(setObject.showLogo);
      }

      if (currentEntry.pic.value) {
        showEntry.pic.value = currentEntry.pic.value;
        setEntrantPic(currentEntry.pic.value);
      } else {
        setEntrantPic(setObject.showLogo);
      }

      if (currentEntry.pic2) {
        showEntry.pic2.value = currentEntry.pic2.value;
        setEntrantPic2(currentEntry.pic2.value);
      } else {
        setEntrantPic2(setObject.showLogo);
      }

      if (currentEntry.pic3) {
        showEntry.pic3.value = currentEntry.pic3.value;
        setEntrantPic3(currentEntry.pic3.value);
      } else {
        setEntrantPic3(setObject.showLogo);
      }

      // showEntry.status = currentEntry.status;
      console.log(showEntry);

      setExists(true);
    } else {
      setEntrantPic(setObject.showLogo);
      setEntrantPic2(setObject.showLogo);
      setEntrantPic3(setObject.showLogo);
      setEntrantVid(setObject.showLogo);
    }
  };

  const PrintForm = ({ profile, entry, formRef }) => {
    return (
      <div ref={formRef}>
        <img
          style={{ padding: "50px", width: "500px" }}
          src={entry.pic.value}
          className="img-fluid rounded mx-auto d-block"
          alt=""
        />
        <Table responsive bordered hover>
          <thead>
            <tr>
              <th colSpan="2">Profile</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(profile).map(([key, value]) => {
              if (key !== "uid") {
                let displayKey = (entry[key] && entry[key].label) || key;
                if (typeof value === "object" && value !== null) {
                  return (
                    <tr key={key}>
                      <td>{displayKey}</td>
                      <td>{value.value}</td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={key}>
                      <td>{displayKey}</td>
                      <td>{value}</td>
                    </tr>
                  );
                }
              }
            })}
          </tbody>
        </Table>

        <Table responsive bordered hover size="sm">
          <thead>
            <tr>
              <th colSpan="2">Entry</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(entry).map(([key, value]) => {
              // Skip uid
              if (key === "uid") {
                return null;
              }
              let displayKey = (value && value.label) || key;
              if (typeof value === "object" && value !== null) {
                if (key === "video" || key === "pic") {
                  return (
                    <tr key={key}>
                      <td>{displayKey}</td>
                      <td>
                        <a href={value.value}>Link</a>
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={key}>
                      <td>{displayKey}</td>
                      <td>{value.value}</td>
                    </tr>
                  );
                }
              } else if (key === "signature") {
                return (
                  <tr key={key}>
                    <td>{displayKey}</td>
                    <td>
                      <img src={value} alt="signature" />
                    </td>
                  </tr>
                );
              } else {
                return (
                  <tr key={key}>
                    <td>{displayKey}</td>
                    <td>{value}</td>
                  </tr>
                );
              }
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  const genPDF = (formRef, profile, entry) => {
    setActive(true);
    //printForm(profile, entry, formRef);
    exportComponentAsJPEG(formRef, {
      fileName: profile.name.value + " " + profile.surname.value,
      width: 800,
      height: 2800,
    }).then(() => {
      setActive(false);
    });
  };

  return {
    fetchEntry,
    fetchProfile,
    handleNextClick,
    handlePrevClick,
    handleTermsChange,
    handleImage,
    handleVideo,
    handleSubmitProfileOnly,
    handleSubmit,
    handleChangeEntry,
    handleChangeProfile,
    handleChangeProfileNonValidate,
    handleChangeEntryNonValidate,
    step,
    showEntry,
    setShowEntry,
    profile,
    isActive,
    terms,
    show,
    cellNumber,
    setObject,
    showPic,
    showPic2,
    showPic3,
    showVid,
    setActive,
    ref,
    addressSearch,
    setAddressSearch,
    sigCanvasRef,
    handleStatusChange,
    handleToList,
    updateAddress,
    address,
    handleSubmitNoEmail,
    updateStatus,
    genPDF,
    PrintForm,
    setAddress,
    handleChangeEntryM,
    handleChangeProfileDate,
  };
};
