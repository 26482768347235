import React, { useEffect, useCallback, useState } from "react";
import { Badge } from "react-bootstrap";
import logo from "../images/dmf.png";
import "./dmf/showStyle.css";
import { projectFirestore } from "../firebase/config";

import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Paper,
} from "@mui/material";
import { getSettings } from "../hooks/useFirestore";
import { useAppContext } from "../hooks/useAppContext";
import { CCardFooter } from "@coreui/react";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";

export function ThankYou() {
  const color = "#49a7c2";
  const { show, settings, setObject } = useAppContext();
  const [logo, setLogo] = useState("");
  //const [setObject, setSetObject] = useState();
  // const fetchSettings = useCallback(
  //   async (show) => {
  //     if (setObject == undefined) {
  //       settings = await getSettings(show);
  //       document.body.style.background = settings.color;
  //       await setSetObject({ ...settings });
  //     }
  //     console.log(setObject);
  //   },
  //   [setObject]
  // );

  useEffect(() => {
    //Get Settings
    //fetchSettings();
  }, []);

  return (
    <div>
      <img
        // style={{ padding: "20px", width: "880px", height: "auto" }}
        style={{ padding: "50px", width: "500px", height: "auto" }}
        // src={"../images/" + show + ".png"}
        src={setObject.showLogo}
        className="img-fluid rounded mx-auto d-block"
        alt="EntryWiz"
      />
      <div className="row">
        <h1 className=" col text-center">
          <Paper elevation={3}>
            <h1 style={{ margin: "2%", fontWeight: "bold" }}>Thank You</h1>
          </Paper>
          <Card>
            <CardContent>
              <p>
                Your request will be processed. Any changes to your details and
                application will be communicated via email.
              </p>
            </CardContent>
            <CardActions
              disableSpacing
              className="d-flex justify-content-center"
            >
              <a href={setObject.twitterPage}>
                <IconButton aria-label="twitter">
                  <Twitter />
                </IconButton>
              </a>
              <a href={setObject.facebookPage}>
                <IconButton aria-label="facebook">
                  <Facebook />
                </IconButton>
              </a>
              <a href={setObject.instagramPage}>
                <IconButton aria-label="instagram">
                  <Instagram />
                </IconButton>
              </a>
              <a href={setObject.linkedInPage}>
                <IconButton aria-label="instagram">
                  <LinkedIn />
                </IconButton>
              </a>
            </CardActions>
          </Card>
        </h1>
      </div>
    </div>
  );
}
